import { DataGrid, GridRowsProp, GridValidRowModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { SupabaseTableEnum } from "../../../../lib/supabase/supabaseTypes";
import { useDatabaseFunctionsWithPromise } from "../../hooks/useDatabase";
import { useToast } from "../../context/ToastContext";
import { Logger } from "@/lib/logger/Logger";
import { Paper } from "@mui/material";
import {
	createMuiDataGridColumn,
	getMuiDataGridColumnsWithEditRowButton,
} from "../../lib/helpers";
import { countryLookup } from "../../lib/constants/countries";
import { CountryEnum } from "@/lib/supabase/supabaseEnums";
import { useCentralStore } from "../../store/Central";
import { deDE } from "@mui/x-data-grid/locales";

const columns = [
	createMuiDataGridColumn("code", "Code"),
	createMuiDataGridColumn("title", "Anrede"),
	createMuiDataGridColumn("first_name", "Vorname"),
	createMuiDataGridColumn("last_name", "Name"),
	createMuiDataGridColumn("street", "Strasse"),
	createMuiDataGridColumn("city", "Ort"),
	createMuiDataGridColumn("postal_code", "PLZ"),
	createMuiDataGridColumn("additional_information", "Zusatz"),
	{
		field: "country",
		headerName: "Land",
		width: 150,
		renderCell: (params: GridValidRowModel) =>
			countryLookup[params.row.country as CountryEnum],
	},
	createMuiDataGridColumn("phone_business", "Telefon (geschäftlich)"),
	createMuiDataGridColumn("phone_mobile", "Telefon (mobil)"),
	createMuiDataGridColumn("email", "EMail"),
	createMuiDataGridColumn("fax", "Fax"),
	createMuiDataGridColumn("is_own_tp", "Eigener TP-Wert", 150, "boolean"),
	createMuiDataGridColumn("own_tp", "Taxpunktwert"),
	createMuiDataGridColumn("tp_tier", "TP-Stufe"),
	createMuiDataGridColumn("tp_variation", "TP-Variation"),
	createMuiDataGridColumn(
		"is_print_skonto",
		"Skonto statt Rabatt drucken",
		150,
		"boolean"
	),
	// If the bank_account_id is not null, the boolean evaluates to true
	createMuiDataGridColumn("bank_account_id", "Bankkonto", 150, "boolean"),

	// TODO: add bank account lookup to Zustand and render custom cell:
	/**	{
		field: "bank_account_id",
		headerName: "Bankkonto",
		width: 150,
		renderCell: (params: GridValidRowModel) =>
			useCentralStore.getState().bankAccountsLookup[params.row.bank_account_id]
	} */
];

export const GuarantorsList: React.FC<{
	onEdit: (guarantorId: string) => void;
}> = ({ onEdit }) => {
	const [rows, setRows] = useState<GridRowsProp>([]);
	const organizationId = useCentralStore((state) => state.organization?.id);
	const { fetchDataWithPromise } = useDatabaseFunctionsWithPromise();
	const { showToast } = useToast();

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		const { data, error } = await fetchDataWithPromise(
			SupabaseTableEnum.GUARANTORS,
			{
				column: "organization_id",
				value: organizationId,
			}
		);
		if (error) {
			showToast("Error beim Abrufen der Garanten", "error");
			Logger.error(error, {}, "Error fetching guarantors");
		}
		setRows(data ?? []);
	};

	return (
		<Paper>
			<DataGrid
				localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
				rows={rows}
				columns={getMuiDataGridColumnsWithEditRowButton(
					onEdit,
					columns
				)}
				autoHeight
			/>
		</Paper>
	);
};
