// Ignore tabs and spaces in eslint
/* eslint-disable indent, no-tabs */
import React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { Logger } from "../logger/Logger";
import { useSessionContext } from "./SessionContext";
import { SupabaseTableEnum } from "../supabase/supabaseTypes";
import { OrganizationType } from "../types/types";
import { useDatabaseFunctionsWithPromise } from "../../dentlab/src/hooks/useDatabase";
import { useCentralStore } from "../../dentlab/src/store/Central";

type ProfileContextType = {
	firstName: string | null;
	lastName: string | null;
	avatarUrl: string | null;
};

const ProfileContext = createContext<{
	organizationType: OrganizationType | null;
	organizationId: string | null;
	profile: ProfileContextType;
	setProfile: (profile: ProfileContextType) => void;
	fetchProfile: (userId: string) => void;
	loading: boolean;
}>({
	organizationType: null,
	organizationId: null,
	profile: { firstName: null, lastName: null, avatarUrl: null },
	setProfile: () => {},
	fetchProfile: () => {},
	loading: true,
});

export const ProfileContextProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const { fetchDataWithPromise } = useDatabaseFunctionsWithPromise();
	const [profile, setProfile] = useState<ProfileContextType>({
		firstName: null,
		lastName: null,
		avatarUrl: null,
	});
	const [organizationId, setOrganizationId] = useState<string | null>(null);
	const { initialize } = useCentralStore((state) => ({
		initialize: state.initialize,
	}));
	const [organizationType, setOrganizationType] =
		useState<OrganizationType | null>(null);
	const [loading, setLoading] = useState<boolean>(true);

	const { session } = useSessionContext();

	useEffect(() => {
		initialize();
		if (session?.userId) {
			fetchProfile(session.userId);
		}
	}, [session?.userId]);

	const fetchProfile = async (userId_: string) => {
		setLoading(true);
		const { data, error } = await fetchDataWithPromise(
			SupabaseTableEnum.PROFILES,
			{
				column: "id",
				value: userId_,
			}
		);

		if (error) {
			Logger.error(error, {}, "Error fetching profile");
			return;
		}

		if (data) {
			const fetchedProfile = data[0];
			// TODO: I'd prefer setting the complete profile object here
			setProfile({
				firstName: fetchedProfile.first_name,
				lastName: fetchedProfile.last_name,
				avatarUrl: fetchedProfile.avatar_url,
			});
			setOrganizationId(fetchedProfile.organization_id);
			setOrganizationType(fetchedProfile.organization_type);
			Logger.log("ProfileContext, Profile retrieved:", fetchedProfile);
		}
		Logger.info("Your organization type is ", organizationType);
		setLoading(false);
	};

	return (
		<ProfileContext.Provider
			value={{
				organizationType,
				organizationId,
				profile,
				setProfile,
				fetchProfile,
				loading,
			}}
		>
			{children}
		</ProfileContext.Provider>
	);
};

export const useProfileContext = () => {
	const context = useContext(ProfileContext);
	if (!context) {
		throw new Error(
			"useProfileContext must be used within a ProfileContextProvider"
		);
	}
	return context;
};
/* eslint-enable indent, no-tabs */
