import { supabase } from "@/lib/supabase";
import {
	ProfileEntityType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { StateCreator } from "zustand";
import { useCentralStore } from "../Central";
import { Logger } from "@/lib/logger/Logger";
import { getRole } from "../../lib/util";

export type Member = Partial<ProfileEntityType> & {
	allowed_signups_lab_id?: string | null;
	allowed_signups_lab_email?: string | null;
	allowed_signups_lab_created_at?: string | null;
	allowed_signups_lab_organization_id?: string | null;
	allowed_signups_lab_role?: string | null;
};
export interface UserManagementSlice {
	membersLookup: Record<string, Member>;
	getMembers: () => Promise<void>;
}

export const createUserManagementStore: StateCreator<UserManagementSlice> = (
	set
) => ({
	membersLookup: {},
	getMembers: async () => {
		const { organization, role, profile } = useCentralStore.getState();

		if (!organization || !profile) {
			Logger.error("No Organization set");
			return;
		}
		const organizationId = organization.id;
		const { data, error } = await supabase
			.from(SupabaseTableEnum.PROFILES_TO_INVITES_VIEW)
			.select()
			.or(
				`organization_id.eq.${organizationId},allowed_signups_lab_organization_id.eq.${organizationId}`
			);

		if (error) {
			Logger.error(error);
			return;
		}

		const { data: emptyProfilesData, error: emptyProfilesError } =
			await supabase
				.from(SupabaseTableEnum.EMPTY_PROFILES)
				.select()
				.eq("organization_id", organizationId);

		if (emptyProfilesError) {
			Logger.error(emptyProfilesError);
			return;
		}

		const fullProfiles = data.map((d) => ({
			id: d.id ?? d.allowed_signups_lab_id,
			first_name: d.first_name ?? "-",
			last_name: d.last_name ?? "-",
			code: d.code ?? "-",
			email: d.email ?? d.allowed_signups_lab_email,
			invited: d.allowed_signups_lab_created_at
				? new Date(d.allowed_signups_lab_created_at).toLocaleDateString(
						"de-ch"
					)
				: "-",
			role:
				d.id == profile.id
					? getRole(role as number)
					: getRole(d.allowed_signups_lab_role as number),
			accepted:
				d.email && d.email == d.allowed_signups_lab_email
					? new Date(d.created_at ?? "").toLocaleDateString("de-ch")
					: "-",
		}));

		const emptyProfiles = emptyProfilesData.map((d) => ({
			id: d.id,
			first_name: d.first_name ?? "",
			last_name: d.last_name ?? "",
			email: "",
			invited: "-",
			role: "Mitarbeiter ohne Profil",
			accepted: "-",
			code: d.code ?? "",
		}));

		set({
			membersLookup: {
				...fullProfiles.reduce((acc, curr) => {
					if (!curr.id) {
						return acc;
					}
					acc[curr.id] = curr;
					return acc;
				}, {} as any),
				...emptyProfiles.reduce(
					(acc, curr) => {
						if (!curr.id) {
							return acc;
						}
						acc[curr.id] = curr;
						return acc;
					},
					{} as Record<string, Member>
				),
			},
		});
	},
});
