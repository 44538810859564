export const shortMonth = (date: string) => {
	return new Date(date)
		.toLocaleString("de-CH", { month: "short", year: "numeric" })
		.split(" ")[0];
};

/* short month and year (e.g. "Jan. 2020") */
export const shortMonthAndYear = (date: string) => {
	return new Date(date).toLocaleString("de-CH", {
		month: "short",
		year: "numeric",
	});
};

export const getSammelrechnungTitle = (
	earliest: string | null,
	latest: string | null
) => {
	if (earliest && latest) {
		// If there are two dates, find the date range
		const datesAreWithinSameYear =
			new Date(earliest).getFullYear() === new Date(latest).getFullYear();
		const datesAreWithinSameMonth =
			datesAreWithinSameYear &&
			new Date(earliest).getMonth() === new Date(latest).getMonth();

		// If the date range is one month, show in the format "Monatsrechnung Jan. 2020"
		if (datesAreWithinSameMonth) {
			return "Monatsrechnung" + " " + shortMonthAndYear(earliest);
		}
		// If the date range is more than one month but in the same year, show in the format "Sammelrechnung Jan. - Feb. 2020"
		else if (datesAreWithinSameYear) {
			return (
				"Sammelrechnung" +
				" " +
				shortMonth(earliest) +
				" - " +
				shortMonthAndYear(latest)
			);
		}
		// If the date range is more than one month and in different years, show in the format "Sammelrechnung Jan 2020 - Feb 2021"
		else {
			return (
				"Sammelrechnung" +
				" " +
				shortMonthAndYear(earliest) +
				" - " +
				shortMonthAndYear(latest)
			);
		}
	} else if (!earliest && latest) {
		// If there is only one date, show the month of the given date
		return "Monatsrechnung" + " " + shortMonthAndYear(latest);
	} else if (earliest && !latest) {
		// If there is only one date, show the month of the given date
		return "Monatsrechnung" + " " + shortMonthAndYear(earliest);
	} else {
		// If there are no dates, show default title
		return "Sammelrechnung";
	}
};
