import { StateCreator } from "zustand";
import { supabase } from "../../supabase/supabaseClient";
import {
	ReplyEntityType,
	SupabaseTableEnum,
} from "../../supabase/supabaseTypes";
import { RealtimePostgresChangesPayload } from "@supabase/supabase-js";
import { Logger } from "../../logger/Logger";

export interface ReplySlice {
	activityId: number | null;
	setActivityId: (activityId: number) => void;
	repliesLoading: boolean;
	replies: ReplyEntityType[];
	updateReplies: (
		payload: RealtimePostgresChangesPayload<{ [key: string]: any }>
	) => void;
	fetchReplies: (activityId: number) => void;
	addReply: (reply: ReplyEntityType) => void;
	emptyReplies: () => void;
}
export const createReplyStore: StateCreator<ReplySlice> = (set, get) => ({
	repliesLoading: false,
	replies: [],
	activityId: null,
	emptyReplies: () => {
		set({ replies: [] });
	},
	setActivityId: (activityId) => {
		set({ activityId });
		get().fetchReplies(activityId);
	},
	addReply: (reply: ReplyEntityType) => {
		set((state) => ({
			replies: [reply, ...state.replies],
		}));
	},
	updateReplies: (payload) => {
		if (payload.eventType !== "INSERT") {
			Logger.warn("Event type not Insert for updating reply");
			return;
		}

		set((state) => ({
			replies: [...state.replies, payload.new as ReplyEntityType],
		}));
	},

	fetchReplies: async (sharedId) => {
		set({ repliesLoading: true });
		if (!sharedId) return;

		const { data, error } = await supabase
			.from(SupabaseTableEnum.REPLIES)
			.select()
			.eq("shared_id", sharedId)
			.order("created_at", { ascending: true });

		if (error) {
			console.error(error);
			return;
		}

		if (data) {
			set({ replies: data });
		}

		set({
			repliesLoading: false,
		});
	},
});
