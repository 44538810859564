import { useState } from "react";
import { ClientSettingsDialog } from "./client-settings.dialog";
import { MenuItemButton } from "../../../../components/src/menu-item-button/menu-item-button.component";
import EditIcon from "@mui/icons-material/Edit";
import { useCentralStore } from "../../store/Central";
import { Star } from "lucide-react";

export const ClientPageTitleWithSettingsPopup = () => {
	const { client, favoriteClient } = useCentralStore((state) => ({
		client: state.client,
		favoriteClient: state.favoriteClient,
	}));

	const [open, setOpen] = useState<boolean>(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	if (!client) return;

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
				borderBottom: "var(--border-sm)",
				height: "var(--height-header-md)",
				justifyContent: "center",
			}}
			data-testid="client-page-title-with-settings-popup"
		>
			<div
				style={{
					display: "inline-flex",
					alignItems: "center",
				}}
			>
				<MenuItemButton
					title={`${client?.first_name} ${client?.last_name}`}
					iconRight={<EditIcon fontSize="small" />}
					onClick={handleOpen}
					textStyles="title-sm"
					dataTestId="client-page-title-with-settings-popup"
				/>
				<div
					className="cursor-pointer mr-4"
					onClick={() => favoriteClient(client.id, !client.favorite)}
				>
					{client?.favorite ? (
						<Star
							size={20}
							className="fill-yellow-400 stroke-yellow-300"
						/>
					) : (
						<Star size={20} />
					)}
				</div>
			</div>
			<ClientSettingsDialog open={open} onClose={handleClose} />
		</div>
	);
};
