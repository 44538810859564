import { useJobDocumentActions } from "@/dentlab/src/hooks/actions/useJobDocumentActions";
import { JobDocumentWithFiles } from "@/dentlab/src/store/Jobs/job-documents.store";
import { JobStatusEnum } from "@/lib/types/job";
import { JobDocumentTypeEnum } from "../job-document.types";
import { CustomButtonWithAnchoredMenu } from "@/dentlab/src/components/custom-button-with-anchored-menu/custom-button-with-anchored-menu.component";
import { CustomMenuItem } from "./job-document-customMenuItem.component";
import { PermissionStatus } from "@/dentlab/src/hooks/actions/actions-hooks-types";
import { JobItemEntityType } from "@/lib/supabase/supabaseTypes";
import {
	PdfTemplateType,
	XmlTemplateType,
} from "@/dentlab/src/pdf-templates/document-template.types";
import { useJobStore } from "@/dentlab/src/store/Jobs";
import { showNotification } from "@/dentlab/src/store/Central/selectors";
import { useNavigate } from "react-router-dom";
import { useTemplatesStore } from "@/dentlab/src/store/Templates";
import { useState } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText,
	Button,
	TextField,
} from "@mui/material";

interface DocumentOption {
	text: string;
	action: () => void | Promise<void>;
	permission: PermissionStatus;
}

export const JobDocumentOptionsMenu: React.FC<{
	jobDocument: JobDocumentWithFiles;
	jobItems: JobItemEntityType[];
	onExport: (templateType: PdfTemplateType | XmlTemplateType) => void;
}> = ({ jobDocument, jobItems, onExport }) => {
	const { createTemplate } = useTemplatesStore((state) => ({
		createTemplate: state.createTemplate,
	}));
	const navigate = useNavigate();
	const {
		isArchiveJobDocumentAllowed,
		isUnarchiveJobDocumentAllowed,
		archiveJobDocument,
		unarchiveJobDocument,
		isExportAuftragsblattAllowed,
		isExportRechnungAllowed,
		isExportBarzahlungAllowed,
		isExportKartenzahlungAllowed,
		isExportXmlAllowed,
	} = useJobDocumentActions();
	const [createTemplateDialogOpen, setCreateTemplateDialogOpen] =
		useState(false);

	const { duplicateJobDocument, isCreateJobDocumentAllowed } = useJobStore(
		(state) => ({
			duplicateJobDocument: state.duplicateJobDocument,
			isCreateJobDocumentAllowed: state.isCreateJobDocumentAllowed,
		})
	);
	const timers = useJobStore((state) => state.timers);

	const handleCreateTemplate = async (code: string, name: string) => {
		const result = await createTemplate(code, name, jobItems);
		if (result) {
			showNotification({
				message: "Jumbo/Vorlage erstellt",
				type: "success",
				action: {
					label: "Weiterleiten",
					onClick: () =>
						navigate(
							`/job-document-templates?templateId=${result.id}`
						),
				},
			});
		}
	};

	const documentOptions: DocumentOption[] = [
		{
			text: "Dokument als Vorlage speichern",
			action: async () => {
				if (Object.entries(timers).length > 0) {
					showNotification({
						message:
							"Bitte warten, bis die Elemente gespeichert wurden",
						type: "error",
					});
					return;
				}
				setCreateTemplateDialogOpen(true);
			},
			permission: {
				isAllowed: true,
				explanation: "",
			},
		},
	];

	if (jobDocument.status === JobStatusEnum.ARCHIVED) {
		documentOptions.push({
			text: "Archivierung aufheben",
			action: () => unarchiveJobDocument(jobDocument),
			permission: isUnarchiveJobDocumentAllowed(jobDocument),
		});
	} else {
		documentOptions.push({
			text: "Dokument archivieren",
			action: () => archiveJobDocument(jobDocument),
			permission: isArchiveJobDocumentAllowed(jobDocument),
		});
	}

	documentOptions.push({
		text: "Dokument duplizieren",
		action: async () =>
			await duplicateJobDocument(jobDocument, jobItems ?? []),
		permission: isCreateJobDocumentAllowed(
			jobDocument.type as JobDocumentTypeEnum
		),
	});

	if (jobDocument.type !== JobDocumentTypeEnum.DeliveryNote) {
		documentOptions.push({
			text: "Zu Lieferschein duplizieren",
			action: async () =>
				await duplicateJobDocument(
					jobDocument,
					jobItems ?? [],
					JobDocumentTypeEnum.DeliveryNote
				),
			permission: isCreateJobDocumentAllowed(
				JobDocumentTypeEnum.DeliveryNote
			),
		});
	}

	if (
		jobDocument.type === JobDocumentTypeEnum.Quotation ||
		jobDocument.type === JobDocumentTypeEnum.DeliveryNote
	) {
		documentOptions.push({
			text: "Auftragsblatt exportieren",
			action: () => onExport(PdfTemplateType.AUFTRAGSBLATT),
			permission: isExportAuftragsblattAllowed(jobDocument),
		});
	}

	if (jobDocument.type === JobDocumentTypeEnum.DeliveryNote) {
		documentOptions.push({
			text: "Rechnung erstellen",
			action: () => onExport(PdfTemplateType.RECHNUNG),
			permission: isExportRechnungAllowed(jobDocument),
		});
		documentOptions.push({
			text: "Barzahlungsquittung erstellen",
			action: () => onExport(PdfTemplateType.BARZAHLUNGSQUITTUNG),
			permission: isExportBarzahlungAllowed(jobDocument),
		});
		documentOptions.push({
			text: "Kartenzahlungsquittung erstellen",
			action: () => onExport(PdfTemplateType.KARTENZAHLUNGSQUITTUNG),
			permission: isExportKartenzahlungAllowed(jobDocument),
		});
		documentOptions.push({
			text: "SV LS ELNF erstellen",
			action: () => onExport(XmlTemplateType.LIEFERSCHEIN_XML),
			permission: isExportXmlAllowed(jobItems),
		});
	}

	if (jobDocument.type === JobDocumentTypeEnum.Quotation) {
		documentOptions.push({
			text: "SV KV ELNF erstellen",
			action: () => onExport(XmlTemplateType.KOSTENVORANSCHLAG_XML),
			permission: isExportXmlAllowed(jobItems),
		});
	}
	return (
		<CustomButtonWithAnchoredMenu>
			{documentOptions.map((d) => {
				const { isAllowed, explanation } = d.permission;
				return (
					<div key={d.text}>
						<CustomMenuItem
							title={isAllowed ? "" : explanation}
							menuFunction={d.action}
							disabled={isAllowed === false}
							text={d.text}
							placement="left"
						/>
					</div>
				);
			})}
			<CreateTemplateDialog
				onCreateTemplate={handleCreateTemplate}
				open={createTemplateDialogOpen}
				setOpen={setCreateTemplateDialogOpen}
			/>
		</CustomButtonWithAnchoredMenu>
	);
};

const CreateTemplateDialog: React.FC<{
	onCreateTemplate: (code: string, name: string) => void;
	open: boolean;
	setOpen: (open: boolean) => void;
}> = ({ onCreateTemplate, open, setOpen }) => {
	const [code, setCode] = useState("");
	const [name, setName] = useState("");

	const handleSubmit = () => {
		if (!code) {
			showNotification({
				message: "Code erforderlich",
				type: "warning",
			});
			return;
		}

		onCreateTemplate(code, name);
		setOpen(false);
	};

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{"Vorlage erstellen"}
			</DialogTitle>
			<DialogContent className="flex flex-col gap-3">
				<DialogContentText id="alert-dialog-description">
					Erstellen Sie eine neue Vorlage, um sie später wieder
					verwenden zu können.
				</DialogContentText>
				<div className="flex flex-col gap-0.5">
					<TextField
						id="code"
						label="Code"
						value={code}
						onChange={(e) => setCode(e.target.value)}
					/>
					<TextField
						id="name"
						label="Name"
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpen(false)}>Abbrechen</Button>
				<Button onClick={handleSubmit} autoFocus>
					Erstellen
				</Button>
			</DialogActions>
		</Dialog>
	);
};
