import { showNotification } from "@/dentlab/src/store/Central/selectors";
import { Logger } from "@/lib/logger/Logger";
import { supabase } from "@/lib/supabase";
import {
	ConnectedProfilesForMed,
	OrganizationEntityType,
	ProfileEntityType,
	SupabaseShareEntityViewEnum,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { StateCreator } from "zustand";

export interface CentralSlice {
	labsLookup: Record<
		string,
		{
			id: string;
			name: string;
			connectRelationshipId: string;
		}
	>;

	selectedLab: string | null;
	userId: string | null;
	userEmail: string | null;
	clientId: string | null;
	profile: ProfileEntityType | null;
	organization: OrganizationEntityType | null;
	initialized: boolean;
	connectedProfiles: Record<string, ConnectedProfilesForMed>;
	setClientId: (clientId: string) => void;
	initialize: () => Promise<void>;
	getSession: () => Promise<string | null>;
	getProfile: () => Promise<void>;
	getOrganization: (organizationId: string) => Promise<void>;
	handleSignOut: () => Promise<void>;
	getLabs: () => Promise<void>;
	setCurrentLab: (selectedLab: string) => void;
	getConnectedProfiles: () => Promise<void>;
}

export const createCentralStore: StateCreator<CentralSlice> = (set, get) => ({
	userId: null,
	userEmail: null,
	profile: null,
	organization: null,
	initialized: false,
	clientId: null,
	selectedLab: null,
	labsLookup: {},
	connectedProfiles: {},
	setClientId: (clientId: string) =>
		set({
			clientId,
		}),
	initialize: async () => {
		const { getSession, getOrganization, getLabs, getConnectedProfiles } =
			get();

		if (get().initialized) {
			Logger.warn("Central store already initialized");
			return;
		}

		const organizationId = await getSession();
		if (!organizationId) return;

		await getOrganization(organizationId);
		await getLabs();
		await getConnectedProfiles();

		set({
			initialized: true,
		});
	},
	getSession: async () => {
		const { data } = await supabase.auth.getSession();

		set({
			userId: data.session?.user.id,
			userEmail: data.session?.user.email ?? "",
		});

		return data.session?.user.app_metadata.organization_id;
	},
	getProfile: async () => {
		const { userId } = get();
		if (!userId) {
			Logger.error("User ID is not set");
			return;
		}
		const { data, error } = await supabase
			.from(SupabaseTableEnum.PROFILES)
			.select()
			.eq("id", userId)
			.single();

		if (error) {
			Logger.error(error);
			return;
		}

		const profile = data;
		set({ profile });
	},
	getOrganization: async (organizationId: string) => {
		const { data, error } = await supabase
			.from(SupabaseTableEnum.ORGANIZATIONS)
			.select()
			.eq("id", organizationId)
			.single();

		if (error) {
			Logger.error(error);
			return;
		}
		if (data) {
			set({
				organization: data,
			});
		}
	},
	handleSignOut: async () => {
		try {
			const { error } = await supabase.auth.signOut();
			if (error) {
				showNotification({
					message:
						"Beim Abmelden ist ein Fehler aufgetreten, bitte versuche es erneut",
					type: "error",
				});
				Logger.error(error);
				return;
			}
			set({
				userId: null,
				userEmail: null,
				organization: null,
				profile: null,
			});

			window.location.href = "/";
		} catch (error) {
			Logger.error(error, {}, "Error signing out");
			showNotification({
				message:
					"Beim Abmelden ist ein Fehler aufgetreten, bitte versuche es erneut.",
				type: "error",
			});
		}
	},

	getLabs: async () => {
		const organizationId = get().organization?.id;
		if (!organizationId) return;
		const { data, error } = await supabase
			.from(SupabaseTableEnum.CONNECT_RELATIONSHIPS)
			.select()
			.eq("med_id", organizationId);

		if (error) {
			Logger.error(error);
			return;
		}

		if (data.length === 0) {
			Logger.warn("No labs found for organization", organizationId);
			return;
		}
		const labsLookup = data.reduce(
			(
				acc: Record<
					string,
					{
						name: string;
						id: string;
						connectRelationshipId: string;
					}
				>,
				row
			) => {
				acc[row.lab_id] = {
					name: row.lab_name ?? "",
					id: row.lab_id,
					connectRelationshipId: row.id,
				};

				return acc;
			},
			{}
		);

		set({
			labsLookup,
			selectedLab: data[0].lab_id,
		});
	},
	setCurrentLab: (selectedLab: string) => {
		const labs = get().labsLookup;

		if (selectedLab in labs) set({ selectedLab });
	},
	getConnectedProfiles: async () => {
		const { data, error } = await supabase
			.from(SupabaseShareEntityViewEnum.CONNECTED_PROFILES_FOR_MED)
			.select();
		if (error) {
			Logger.error(
				error,
				{},
				"Error fetching connected profiles for lab"
			);
			return;
		}

		const connectedProfiles = data.reduce((acc, row) => {
			if (row.profile_id) {
				acc[row.profile_id] = row;
			}
			return acc;
		}, get().connectedProfiles);

		set({
			connectedProfiles,
		});
	},
});
