import { Logger } from "@/lib/logger/Logger";
import { supabase } from "@/lib/supabase";
import {
	FileWithShare,
	JobDocumentEntityType,
	JobItemEntityType,
	SupabaseShareEntityViewEnum,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { StateCreator } from "zustand";
import { handleDatabaseOperation } from "../../lib/utils/utils-functions";
import { useCentralStore } from "../Central";
import { JobDocumentTypeEnum } from "../../pages/job-page/job-document/job-document.types";
import {
	GsStatusEnum,
	KvStatusEnum,
	LsStatusEnum,
	PermissionStatus,
} from "../../hooks/actions/actions-hooks-types";
import { JobStatusEnum } from "@/lib/types/job";
import { JobStoreUnion } from ".";
import { v4 as uuidv4 } from "uuid";
import { showNotification } from "../Central/selectors";
import { produce } from "immer";
import {
	formatArticle,
	formatTariff,
	jobDocumentTypeNamePresets,
} from "./utils";
import { useTemplatesStore } from "../Templates/templates.store";

export type JobDocumentWithFiles = JobDocumentEntityType & {
	files_with_shares: FileWithShare[];
};

interface JobDocumentsState {
	// TODO(?): Could force this to be a string by just treaing it this way everywhere with .toString()
	jobDocuments: Record<number, JobDocumentWithFiles>;
	openedSidebar: null | {
		documentId: number;
		type: "tariff" | "article" | "group";
	};
}

export const initialJobDocumentsState: JobDocumentsState = {
	jobDocuments: {},
	openedSidebar: null,
};

export interface JobDocumentSlice extends JobDocumentsState {
	createJobDocument: (
		type: JobDocumentTypeEnum
	) => Promise<number | undefined>;
	createJobDocumentFromTemplate: (
		jobDocumentTemplateId: number,
		templateType: JobDocumentTypeEnum
	) => Promise<void>;
	isCreateJobDocumentAllowed: (
		jobDocumentType: JobDocumentTypeEnum
	) => PermissionStatus;
	deleteJobDocument: (id: number) => Promise<void>;
	fetchJobDocument: (id: number) => Promise<void>;
	duplicateJobDocument: (
		jobDocument: JobDocumentEntityType,
		existingJobItems: JobItemEntityType[],
		newDocumentType?: JobDocumentTypeEnum
	) => Promise<void>;
	updateJobDocumentTitle: (
		id: number,
		title: string,
		status: JobStatusEnum
	) => Promise<void>;
	updateJobDocumentStatus: (
		documentId: number,
		status: JobStatusEnum | KvStatusEnum | LsStatusEnum | GsStatusEnum,
		amount?: number | null,
		accountingDocumentId?: number | null
	) => Promise<boolean>;
	openSideBar: (
		documentId: number,
		type: "tariff" | "article" | "group"
	) => void;
	closeSidebar: () => void;
}

export const createJobDocumentStore: StateCreator<
	JobStoreUnion,
	[],
	[],
	JobDocumentSlice
> = (set, get) => ({
	...initialJobDocumentsState,
	openedSidebar: null,
	createJobDocument: async (type: JobDocumentTypeEnum) => {
		const jobDocuments = get().jobDocuments;
		const permission = get().isCreateJobDocumentAllowed(type);

		if (!permission.isAllowed) {
			showNotification({
				message: permission.explanation,
				type: "error",
			});

			Logger.error(permission.explanation);
			return;
		}

		const count =
			Object.values(jobDocuments).filter(
				(doc: JobDocumentWithFiles) => doc.type === type
			).length || 0;
		const countForName = count > 0 ? ` ${count + 1}` : "";

		const { data, error } = await supabase
			.from(SupabaseTableEnum.JOB_DOCUMENTS)
			.insert({
				title: `${jobDocumentTypeNamePresets(type)} ${countForName}`,
				// These variables are only being used here, no need to assign
				// explicit names
				job_id: get().job?.id,
				type,
				discount_material:
					useCentralStore.getState().client?.discount_material ?? 0,
				discount_work:
					useCentralStore.getState().client?.discount_work ?? 0,
			} as JobDocumentEntityType)
			.select()
			.single();

		if (error) {
			Logger.error(error);
			return;
		}

		if (data) {
			set(
				produce((state) => {
					state.jobDocuments[data.id] = {
						...data,
						files_with_shares: [],
					};
				})
			);
			return data.id;
		}
	},
	createJobDocumentFromTemplate: async (
		templateId: number,
		templateType: JobDocumentTypeEnum
	) => {
		const clientId = useCentralStore.getState().client?.id;
		if (!clientId) {
			Logger.error("Client id is not set");
			return;
		}

		const job = get().job;
		if (!job) {
			Logger.error("Job id is not set");
			return;
		}

		const { name: templateName, template_items: templateItems } =
			useTemplatesStore.getState().templatesLookup[templateId];
		const newJobDocumentId = await get().createJobDocument(templateType);

		if (!newJobDocumentId) {
			showNotification({
				message: "Fehler beim Erstellen des Dokuments",
				type: "error",
			});
			return;
		}

		// shitty because it doesn't really check whether it's an article or a tariff
		const aShitWayToGetTheFormattedItem = (code_e: string) => {
			// Check if code_e is a tariff or article
			const tariff = useCentralStore.getState().tariffs[code_e];
			const article = useCentralStore.getState().articles[code_e];

			const jobTpTier = job.tp_tier;
			const jobTpValue = job.tp_value;
			const jobTpVariation = job.tp_variation;
			if (jobTpTier === null) {
				showNotification({
					message: "Dem Auftrag fehlt die Taxpunktstufe.",
					type: "error",
				});
				return;
			}
			if (jobTpValue === null) {
				showNotification({
					message: "Dem Auftrag fehlt der Taxpunktwert.",
					type: "error",
				});
				return;
			}

			if (tariff) {
				return formatTariff(
					tariff,
					newJobDocumentId,
					clientId,
					job.guarantor_id?.toString() ?? null,
					jobTpTier,
					jobTpValue,
					jobTpVariation ?? 0
				);
			} else if (article) {
				return formatArticle(article, newJobDocumentId);
			} else {
				return null;
			}
		};

		const itemsToInsert = templateItems.map((item) => {
			const formattedItem = aShitWayToGetTheFormattedItem(item.code_e);

			if (!formattedItem) {
				showNotification({
					message: `Position ${item.code_e} aus Jumbo existiert nicht und wurde übersprungen.`,
					type: "warning",
				});
				return null;
			}

			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { created_at, modified_at, ...newItem } =
				formattedItem as any;
			return {
				...newItem,
				quantity: item.quantity ?? 1,
			};
		});
		const { error: jobItemsError } = await supabase
			.from(SupabaseTableEnum.JOB_ITEMS)
			.insert(itemsToInsert.filter((item) => item !== null));

		if (jobItemsError) {
			Logger.error(jobItemsError);
			return;
		}

		Logger.log("[createJobDocumentFromTemplate 2]", newJobDocumentId);

		if (newJobDocumentId) {
			get().fetchJobDocument(newJobDocumentId);
		}

		get().pendingChangesNotification();

		// update job title if the template has a name
		if (templateName) {
			get().updateJob("title", templateName);
		}
	},

	duplicateJobDocument: async (
		jobDocument: JobDocumentEntityType,
		existingJobItems: JobItemEntityType[],
		newDocumentType?: JobDocumentTypeEnum
	) => {
		if (!jobDocument || !jobDocument.type) {
			showNotification({
				message: "Dokument konnte nicht kopiert werden.",
				type: "error",
			});
			return;
		}

		const jobDocumentType =
			newDocumentType || (jobDocument.type as JobDocumentTypeEnum);

		const permission = get().isCreateJobDocumentAllowed(jobDocumentType);

		if (permission.isAllowed === false) {
			showNotification({
				message: permission.explanation,
				type: "error",
			});
			return;
		}

		const { error: jobDocumentError, data: jobDocumentData } =
			await supabase
				.from(SupabaseTableEnum.JOB_DOCUMENTS)
				.insert({
					title: jobDocumentTypeNamePresets(jobDocumentType),
					job_id: jobDocument.job_id,
					type: jobDocumentType,
				})
				.select()
				.single();

		if (jobDocumentError) {
			showNotification({
				message: "Datenbankfehler beim Duplizieren des Dokuments.",
				type: "error",
			});
			Logger.error(jobDocumentError);
			return;
		}

		if (jobDocumentData && existingJobItems.length > 0) {
			const { error: jobItemsError } = await supabase
				.from(SupabaseTableEnum.JOB_ITEMS)
				.insert(
					existingJobItems.map((item) => {
						// old id already exists, so it needs to be undefined and created by supabase

						// eslint-disable-next-line @typescript-eslint/no-unused-vars
						const {
							id,
							job_document_id,
							modified_at,
							created_at,
							...newItem
						} = item;
						return {
							...newItem,
							id: uuidv4(),
							job_document_id: jobDocumentData.id,
						};
					})
				);

			if (jobItemsError) {
				showNotification({
					message: "Fehler beim Kopieren der Positionen.",
					type: "error",
				});
				Logger.error(jobItemsError);
				return;
			}
		}

		const newJobDocument: JobDocumentWithFiles = {
			...jobDocumentData,
			files_with_shares: [],
		};

		// Note that we only need to set the new job document here,
		// the job items are fetched on render of the job document component
		set(
			produce((state) => {
				state.jobDocuments[jobDocumentData.id] = newJobDocument;
			})
		);

		get().pendingChangesNotification();
	},

	/**
	 * isCreateJobDocumentAllowed - Function to check if a job document can be created
	 * @param {JobDocumentTypeEnum} jobDocumentType
	 * @returns {PermissionStatus}
	 *
	 * ALLOWED:
	 * - Quotation: Always
	 * - DeliveryNote: If no delivery note (that's not archived) exists
	 * - CreditNote: Always
	 */
	isCreateJobDocumentAllowed: (
		jobDocumentType: JobDocumentTypeEnum
	): PermissionStatus => {
		const jobDocuments = get().jobDocuments;

		if (
			jobDocumentType === JobDocumentTypeEnum.Quotation ||
			jobDocumentType === JobDocumentTypeEnum.CreditNote ||
			jobDocumentType === JobDocumentTypeEnum.MATERIALS
		) {
			return {
				isAllowed: true,
				explanation: "",
			};
		} else if (jobDocumentType === JobDocumentTypeEnum.DeliveryNote) {
			const deliveryNoteExists = Object.values(jobDocuments).some(
				(jobDocument) =>
					jobDocument.type === JobDocumentTypeEnum.DeliveryNote &&
					jobDocument.status !== JobStatusEnum.ARCHIVED
			);

			if (!deliveryNoteExists) {
				return {
					isAllowed: true,
					explanation: "",
				};
			}

			return {
				isAllowed: false,
				explanation:
					"Es existiert bereits ein Lieferschein. Archivieren Sie diesen, um einen neuen zu erstellen.",
			};
		}

		return {
			isAllowed: false,
			explanation: "Die Art des Auftrags wurde nicht erkannt.",
		};
	},

	deleteJobDocument: async (id: number) => {
		set(
			produce((state) => {
				delete state.jobDocuments[id];
			})
		);

		await handleDatabaseOperation(
			supabase
				.from(SupabaseTableEnum.JOB_DOCUMENTS)
				.delete()
				.eq("id", id)
				.select()
		);
	},

	updateJobDocumentTitle: async (
		id: number,
		title: string,
		status: JobStatusEnum
	) => {
		if (status > JobStatusEnum.IN_PROGRESS) {
			showNotification({
				message:
					"Der Titel des Dokuments kann nicht geändert werden, da der Auftrag bereits abgeschlossen ist.",
				type: "error",
			});
			return;
		}

		const previousTitle = get().jobDocuments[id].title;
		if (previousTitle === title) return;

		set(
			produce((state) => {
				state.jobDocuments[id].title = title;
			})
		);

		const { error } = await supabase
			.from(SupabaseTableEnum.JOB_DOCUMENTS)
			.update({ title })
			.eq("id", id)
			.select();

		if (error) {
			set(
				produce((state) => {
					state.jobDocuments[id].title = previousTitle;
				})
			);
			showNotification({
				message: "Fehler beim Aktualisieren des Titels des Dokuments",
				type: "error",
			});
			Logger.warn("Error updating job document title", { error });
		} else {
			showNotification({
				message: "Titel des Dokuments aktualisiert",
				type: "success",
			});
		}
	},

	updateJobDocumentStatus: async (
		documentId: number,
		status: JobStatusEnum | KvStatusEnum | LsStatusEnum | GsStatusEnum,
		amount: number | null = null,
		accountingDocumentId: number | null = null
	) => {
		if (
			status === JobStatusEnum.BOOKED_SINGLE ||
			status === JobStatusEnum.BOOKED_MONTHLY
		) {
			if (!accountingDocumentId) {
				Logger.error("No accounting document id provided");
				showNotification({
					message:
						"Es fehlt ein Buchhaltungseintrag, um den Status des Dokuments zu aktualisieren.",
					type: "error",
				});
			}
		}

		if (status == JobStatusEnum.COMPLETED) {
			// If the amount is null, show an error but continue with the update
			if (amount === null) {
				Logger.error("No amount provided");
				showNotification({
					message:
						"Um den Auftrag abzuschliessen, muss ein Gesamtbetrag vorhanden sein.",
					type: "warning",
				});
			}
			// If the amount is 0, show a warning and continue with the update
			if (amount === 0) {
				showNotification({
					message: "Gesamtbetrag ist 0.",
					type: "warning",
				});
			}
		}

		let previousJobDocument: JobDocumentWithFiles | null = null;
		set(
			produce((state) => {
				previousJobDocument = state.jobDocuments[documentId];
				state.jobDocuments[documentId].status = status;
				if (amount) {
					state.jobDocuments[documentId].amount = amount;
				}
				if (accountingDocumentId) {
					state.jobDocuments[documentId].accounting_document_id =
						accountingDocumentId;
				}
			})
		);

		const { error } = await supabase
			.from(SupabaseTableEnum.JOB_DOCUMENTS)
			.update({
				status,
				...(amount ? { amount } : {}),
				...(accountingDocumentId
					? { accounting_document_id: accountingDocumentId }
					: {}),
			})
			.eq("id", documentId);

		if (error) {
			set(
				produce((state) => {
					if (previousJobDocument) {
						state.jobDocuments[documentId] = previousJobDocument;
					}
				})
			);
			showNotification({
				message: "Fehler beim Aktualisieren des Status des Dokuments",
				type: "error",
			});
			Logger.error(error, {}, "Error updating job document status");
			return false;
		}
		return true;
	},
	fetchJobDocument: async (id: number) => {
		const { data, error } = await supabase
			.from(SupabaseTableEnum.JOB_DOCUMENTS)
			.select(`*, ${SupabaseShareEntityViewEnum.FILES_WITH_SHARES}(*)`)
			.eq("id", id)
			.single();

		if (error) {
			Logger.error(`[job-documents.store] fetchJobDocument: ${error}`);
			return;
		}

		if (!data) {
			Logger.error(
				`[job-documents.store] fetchJobDocument: No data found for id ${id}`
			);
			return;
		}

		set(
			produce((state) => {
				state.jobDocuments[id] = data;
			})
		);
	},
	closeSidebar: () => set({ openedSidebar: null }),
	openSideBar: (documentId: number, type: "tariff" | "article" | "group") => {
		if (get().openedSidebar !== null) {
			set({
				openedSidebar: null,
			});
			setTimeout(() => {
				set({
					openedSidebar: {
						documentId,
						type,
					},
				});
			}, 100);
		} else {
			set({
				openedSidebar: {
					documentId,
					type,
				},
			});
		}
	},
});
