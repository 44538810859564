import { SearchBar } from "./header-bar-components/search-bar/search-bar.component";
import "../../styles.css";
import { HeaderBarSettingsGroup } from "./header-bar-components/header-bar-settings-group/header-bar-settings-group.component";
import logo from "../../../assets/dentLAB_logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useCentralStore } from "@/dentlab/src/store/Central";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import { IconButton, Tooltip } from "@mui/material";
import { MFADialog } from "@/dentlab/src/components/mfa/mfa-dialog.component";

export const HeaderBar: React.FC<{
	mfaAlert: boolean;
	onMfaAlertClick: () => void;
}> = ({ mfaAlert, onMfaAlertClick }) => {
	const navigate = useNavigate();
	const { setMFADialog, mfaEnrolled, mfaDialogOpen } = useCentralStore(
		(state) => ({
			setMFADialog: state.setMFADialog,
			mfaEnrolled: state.mfaEnrolled,
			mfaDialogOpen: state.mfaDialogOpen,
		})
	);
	return (
		<div className="header-bar">
			<div
				style={{
					display: "flex",
					alignItems: "center",
					marginLeft: "1rem",
					paddingTop: "3px",
				}}
			>
				<Link to="/">
					<img
						src={logo}
						style={{
							height: "14px",
						}}
					/>
				</Link>
			</div>
			<SearchBar />
			<div className="flex flex-row items-center gap-2">
				{mfaAlert && (
					<Tooltip
						title={
							"Die Mehrfaktor-Authentifizierung ist für Ihr Konto nicht eingerichtet. Bitte richten Sie sie jetzt ein indem Sie hier klicken!"
						}
					>
						<IconButton onClick={onMfaAlertClick} color="warning">
							<PrivacyTipIcon />
						</IconButton>
					</Tooltip>
				)}
				<div className="flex w-[550px] flex-row">
					<div className="flex flex-row rounded-full bg-white bg-opacity-10 items-center justify-center text-sm my-0.5">
						<p className="ml-4 whitespace-nowrap w-full max-w-[150px] xl:max-w-[300px] overflow-hidden text-ellipsis mr-2 text-white opacity-80">
							Sie nutzen die kostenlose Version von dentLAB.
						</p>
						<div
							className="flex flex-row rounded-full bg-white bg-opacity-20 items-center justify-center text-sm h-full hover:cursor-pointer"
							onClick={() => navigate("/abonnement")}
						>
							<p className="px-4 text-white opacity-80 whitespace-nowrap overflow-hidden text-ellipsis">
								Jetzt upgraden
							</p>
						</div>
					</div>
					<HeaderBarSettingsGroup />
				</div>
			</div>
			<MFADialog open={mfaDialogOpen} onOpen={setMFADialog} />
		</div>
	);
};
