import { useState } from "react";
import { useCentralStore } from "../../../store/Central";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import { Button } from "@mui/material";
import { ConnectRelationshipSelect } from "./cr-select/connect-relationship-select.component";
import { Typography } from "@/components/src/typography/typography.component";

export const ShareConnectRelationshipDialog: React.FC<{
	open: boolean;
	onClose: () => void;
}> = ({ open, onClose }) => {
	const { connectClientToExistingConnectRelationship } = useCentralStore(
		(state) => ({
			connectClientToExistingConnectRelationship:
				state.connectClientToExistingConnectRelationship,
		})
	);
	const [selectedRelationshipId, setSelectedRelationshipId] = useState<
		string | null
	>(null);

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Wählen Sie einen Auftraggeber</DialogTitle>
			<DialogContent>
				<div className="flex flex-col gap-4">
					<Typography variant="body-lg">
						Die Connect-Beziehung des gewählten Auftraggebers wird
						mit dem aktuellen Auftraggeber geteilt.
					</Typography>
					<ConnectRelationshipSelect
						onSelect={(connectRelationshipId) => {
							setSelectedRelationshipId(connectRelationshipId);
						}}
					/>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Abbrechen</Button>
				<Button
					onClick={() => {
						if (!selectedRelationshipId) return;
						connectClientToExistingConnectRelationship(
							selectedRelationshipId
						);
						onClose();
					}}
					disabled={!selectedRelationshipId}
				>
					Connect-Beziehung teilen
				</Button>
			</DialogActions>
		</Dialog>
	);
};
