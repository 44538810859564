import { useEffect, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { useCentralStore } from "../../store/Central";
import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";
import { useJobItemsContext } from "../../context/JobItemsContext";
import { ItemPatientDetail } from "../articles/item-patient-detail.component";
import { PageTitle } from "@/components/src/page-title/page-title.component";
import clsx from "clsx";
import { ArticleForm } from "./article-form.component";
import { CustomButtonWithAnchoredMenu } from "../../components/custom-button-with-anchored-menu/custom-button-with-anchored-menu.component";
import { CustomMenuItem } from "../job-page/job-document/job-document-components/job-document-customMenuItem.component";

export const ArticlePage = () => {
	const { code } = useParams();
	const navigate = useNavigate();
	const { article, getArticles, deleteArticle } = useCentralStore(
		(state) => ({
			article: state.articles[code ?? ""],
			getArticles: state.getArticles,
			deleteArticle: state.deleteArticle,
			organization: state.organization,
		})
	);

	const { handleUpdateArticle } = useJobItemsContext();

	const defaultValues = useMemo(() => ({ ...article }), [article]);

	const methods = useForm({
		defaultValues,
	});

	useEffect(() => {
		methods.reset(defaultValues);
	}, [methods.reset, defaultValues]);

	const handleDelete = async () => {
		await deleteArticle(article.id);
		navigate("/article");
	};

	if (!article) {
		return (
			<div className="w-full h-full flex flex-col gap-4 justify-center items-center text-gray-900">
				<div>
					Dieser Artikel existiert nicht
					<Button
						onClick={() => {
							navigate("/article");
						}}
					>
						Zurück zur Übersicht
					</Button>
				</div>
			</div>
		);
	}

	const onSubmit = async (data: any) => {
		await handleUpdateArticle({
			...data,
		});
		await getArticles();
	};

	return (
		<div className="p-6">
			<div className="flex justify-between items-center">
				<PageTitle
					title={[
						{
							name: "Artikel",
							link: "/article",
						},
						{
							name: article.code_e.toString(),
						},
					]}
				/>
				<CustomButtonWithAnchoredMenu>
					<CustomMenuItem
						title=""
						menuFunction={handleDelete}
						disabled={false}
						text="Artikel löschen"
						placement="left"
					/>
				</CustomButtonWithAnchoredMenu>
			</div>
			<div className="flex w-full justify-between pr-10 ">
				<div className="flex flex-col">
					{JobItemTypeEnum.ARTICLE_MATERIAL && (
						<div className={clsx("block 2xl:hidden pt-6")}>
							<ItemPatientDetail itemId={article.id.toString()} />
						</div>
					)}
					<FormProvider {...methods}>
						<ArticleForm showSaveButton onSubmit={onSubmit} />
					</FormProvider>
				</div>
				{JobItemTypeEnum.ARTICLE_MATERIAL && (
					<div className={clsx("hidden 2xl:block")}>
						<ItemPatientDetail itemId={article.id.toString()} />
					</div>
				)}
			</div>
		</div>
	);
};
