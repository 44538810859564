import { IconButton } from "@mui/material";
import { Pin } from "lucide-react";
import { useJobStore } from "../../store/Jobs";
import { JobWithShare } from "@/lib/supabase/supabaseTypes";

export const PinJob: React.FC<{ job: JobWithShare }> = ({ job }) => {
	const { pinJob, unpinJob, pinnedJobs } = useJobStore((state) => ({
		pinJob: state.pinJob,
		unpinJob: state.unpinJob,
		pinnedJobs: state.pinnedJobs,
	}));
	if (!job.id) return null;
	const isPinned = pinnedJobs[job.id];
	return (
		<IconButton
			onClick={() => {
				if (isPinned) {
					unpinJob(job.id as number);
				} else {
					pinJob(job);
				}
			}}
			color={isPinned ? "primary" : "default"}
		>
			<Pin />
		</IconButton>
	);
};
