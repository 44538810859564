import { Checkbox, FormControlLabel } from "@mui/material";
import { FormGroupProps, FormGroupPropsValueChange } from "../../../form-types";
import { AddressGroup } from "../../../form-input-components/groups/address.group";
import { TitleAndNameGroup } from "../../../form-input-components/groups/title-and-name.group";
import { FormSectionWithTitle } from "../../../../components/form-section-with-title/form-section-with-title.component";
import { ChangeEvent } from "react";
import { CustomTextField } from "../../../form-input-components/custom-input-components/custom-text-field.component";

interface NameAndAddressGroupProps extends FormGroupProps {
	hideTitleAndName?: boolean;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void; // add onChange here it make it required
	onValueChange: (valueChange: FormGroupPropsValueChange) => void; // add onValueChange here it make it required
}

export const ClientNameAndAddressSection: React.FC<
	NameAndAddressGroupProps
> = ({
	formData,
	formErrors,
	onChange,
	onValueChange,
	onCheckboxChange,
	hideTitleAndName = false,
}) => {
	return (
		<FormSectionWithTitle title="Name und Adresse">
			{!hideTitleAndName && (
				<TitleAndNameGroup
					formData={formData}
					formErrors={formErrors}
					onChange={onChange}
					onValueChange={onValueChange}
					requiredFields={["title", "first_name", "last_name"]}
				/>
			)}
			<CustomTextField
				label="Firmenname"
				name="company_name"
				value={formData.company_name || ""}
				onChange={onChange}
				size="small"
				validationError={formErrors?.company_name}
			/>
			<AddressGroup
				formData={formData}
				formErrors={formErrors}
				onChange={onChange}
				onValueChange={onValueChange}
				requiredFields={["street", "city", "postal_code", "country"]}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={
							formData.is_correspondence_address ? true : false
						}
						onChange={onCheckboxChange}
						name="is_correspondence_address"
						color="primary"
					/>
				}
				label="Korrespondenzadresse"
			/>
			{formData.is_correspondence_address && (
				<>
					<TitleAndNameGroup
						formData={formData}
						formErrors={formErrors}
						onChange={onChange}
						onValueChange={onValueChange}
						customNaming={{
							title: "c_title",
							first_name: "c_first_name",
							last_name: "c_last_name",
						}}
					/>
					<AddressGroup
						formData={formData}
						formErrors={formErrors}
						onChange={onChange}
						onValueChange={onValueChange}
						customNaming={{
							street: "c_street",
							country: "c_country",
							city: "c_city",
							additional_information: "c_additional_information",
							phone_personal: "c_phone_personal",
							phone_business: "c_phone_business",
							phone_mobile: "c_phone_mobile",
							fax: "c_fax",
							email: "c_email",
						}}
						requiredFields={[
							"street",
							"city",
							"postal_code",
							"country",
						]}
					/>
				</>
			)}
		</FormSectionWithTitle>
	);
};
