import { Typography } from "@/components/src/typography/typography.component";
import { useCentralStore } from "../../../store/Central";
import { useState } from "react";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@mui/material";
import { Button } from "@mui/material";
import { showNotification } from "../../../store/Central/selectors";
import { validateEmail } from "../../../hooks/useForm/form-field-validation-functions";

export const NewConnectRelationshipDialog: React.FC<{
	open: boolean;
	onClose: () => void;
}> = ({ open, onClose }) => {
	const { connectClientFromScratch } = useCentralStore((state) => ({
		connectClientFromScratch: state.connectClientFromScratch,
	}));
	const [adminEmail, setAdminEmail] = useState<string>("");
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Neue Connect-Verbindung anlegen</DialogTitle>
			<DialogContent>
				<div className="flex flex-col gap-4">
					<Typography variant="body-lg">
						Um eine neue Connect-Verbindung anzulegen, geben Sie die
						E-Mail-Adresse des Administrators der Praxis ein.
					</Typography>
					<TextField
						label="E-Mail-Adresse des Administrators"
						value={adminEmail}
						type="email"
						onChange={(e) => setAdminEmail(e.target.value)}
					/>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Abbrechen</Button>
				<Button
					onClick={() => {
						if (!validateEmail(adminEmail)) {
							showNotification({
								message: "Ungültige E-Mail-Adresse",
								type: "error",
							});
							return;
						}
						connectClientFromScratch(adminEmail);
						onClose();
					}}
				>
					Connect-Verbindung anlegen
				</Button>
			</DialogActions>
		</Dialog>
	);
};
