import { useEffect, useState } from "react";
import { SelectionRange } from "@progress/kendo-react-dateinputs";
import { MonthlyInvoicesCard } from "./monthly-invoices-card.component";
import { CustomDateRangePicker } from "../../components/custom-date-range-picker/custom-date-range-picker.component";
import { supabase } from "@/lib/supabase";
import { useCentralStore } from "../../store/Central";
import { showNotification } from "../../store/Central/selectors";
import { RecipientTypeEnum } from "./types";
import { PageTitle } from "@/components/src/page-title/page-title.component";
import { SupabaseViewEnum } from "@/lib/supabase/supabaseTypes";

export type CompletedJobDocument = {
	amount: number | null;
	client_id: string | null;
	date: string | null;
	guarantor_id: string | null;
	id: number | null;
	job_code: string | null;
	job_id: number | null;
	job_patient_id: string | null;
	job_title: string | null;
	organization_id: string | null;
	type: string | null;
	is_ignore_monthly_discount: boolean | null;
};

interface RecipientGroup {
	recipientType: RecipientTypeEnum;
	recipientId: string;
	jobDocuments: CompletedJobDocument[];
}

export const MonthlyInvoicesPage = () => {
	const { organizationId } = useCentralStore((state) => ({
		organizationId: state.organization?.id,
	}));
	const [loading, setLoading] = useState<boolean>(false);
	const [recipientGroups, setRecipientGroups] = useState<RecipientGroup[]>(
		[]
	);

	const [dateValue, setDateValue] = useState<SelectionRange>({
		// first day of current month
		start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
		// today
		end: new Date(),
	});

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		if (!organizationId) {
			showNotification({
				message: "Fehler beim Laden der Daten: Keine Organisation",
				type: "error",
			});
			return;
		}

		setLoading(true);

		// We deliberately don't use .eq("organization_id", organizationId);
		// because it lead to performance issues. RLS already filters the data for the current organization.
		const { data, error } = await supabase
			.from(SupabaseViewEnum.COMPLETED_JOB_DOCUMENTS_VIEW)
			.select();

		if (error) {
			showNotification({
				message: "Fehler beim Laden der Daten",
				type: "error",
			});
			return;
		}
		const jobDocumentsPerRecipient = data.reduce(
			(acc, curr) => {
				let recipientId = null;
				let recipientType = null;
				if (curr.guarantor_id) {
					recipientId = curr.guarantor_id;
					recipientType = RecipientTypeEnum.GUARANTOR;
				} else if (curr.client_id) {
					recipientId = curr.client_id;
					recipientType = RecipientTypeEnum.CLIENT;
				} else {
					return acc;
				}
				if (!recipientId) {
					return acc;
				}
				if (!acc[recipientId]) {
					acc[recipientId] = {
						recipientType,
						recipientId,
						jobDocuments: [],
					};
				}
				acc[recipientId].jobDocuments.push(curr);
				return acc;
			},
			{} as Record<string, RecipientGroup>
		);

		setRecipientGroups(Object.values(jobDocumentsPerRecipient));
		setLoading(false);
	};

	return (
		<div className="flex flex-col px-12 py-6">
			<PageTitle title={[{ name: "Sammelrechnungen" }]} />
			<div className="flex flex-col gap-6 py-4">
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "10px",
						paddingBottom: "20px",
					}}
				>
					<span
						style={{
							fontSize: "14px",
							fontWeight: "bold",
						}}
					>
						Schnellauswahl
					</span>
					<CustomDateRangePicker
						dateVal={dateValue}
						handleDateChange={setDateValue}
					/>
				</div>
				{loading && <div>Lädt...</div>}
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "20px",
					}}
				>
					{recipientGroups.map((recipientGroup) => {
						return (
							<MonthlyInvoicesCard
								key={recipientGroup.recipientId}
								recipient={
									recipientGroup.recipientType ===
									RecipientTypeEnum.CLIENT
										? useCentralStore.getState()
												.clientsLookup[
												recipientGroup.recipientId
											]
										: useCentralStore.getState()
												.guarantorLookup[
												recipientGroup.recipientId
											]
								}
								dateRange={dateValue}
								onManualSelect={() => {}}
								rows={recipientGroup.jobDocuments}
								onFetchData={() => fetchData()}
								recipientType={recipientGroup.recipientType}
							/>
						);
					})}
				</div>
				{!loading && recipientGroups.length === 0 && (
					<div
						style={{
							padding: "30px",
						}}
					>
						Keine abgeschlossenen Lieferscheine oder Gutschriften,
						die noch keiner Sammelrechnung angerechnet sind.
					</div>
				)}
			</div>
		</div>
	);
};
