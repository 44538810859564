import { EditableTitle } from "../../../../../../components/src/editable-title/editable-title.component";
import { useState, useEffect } from "react";
import { isUpdateJobCodeAllowed } from "@/dentlab/src/store/Jobs/utils";
import { useJobStore } from "@/dentlab/src/store/Jobs";
import { JobStatusEnum } from "@/lib/types/job";
import { showNotification } from "@/dentlab/src/store/Central/selectors";
import { Logger } from "@/lib/logger/Logger";

export const JobCodeForm: React.FC<{
	jobId: number;
	jobCode: string;
	jobStatus: JobStatusEnum;
}> = ({ jobId, jobCode, jobStatus }) => {
	const { updateJob } = useJobStore();

	const [code, setCode] = useState<string>("");

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCode(e.target.value);
	};

	useEffect(() => {
		setCode(jobCode);
	}, [jobCode]);

	const handleSubmit = async () => {
		if (!jobId) {
			showNotification({
				message: "Auftrag ist nicht gesetzt",
				type: "error",
			});
			Logger.error("JobCodeForm", {});
			return;
		}
		await updateJob("code", code, jobId);
	};

	return (
		<EditableTitle
			name="code"
			value={code}
			onChange={handleInputChange}
			onSubmit={handleSubmit}
			onReset={() => {}}
			type="code"
			loading={!jobId}
			disabled={jobStatus ? !isUpdateJobCodeAllowed(jobStatus) : true}
		/>
	);
};
