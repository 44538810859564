import { createClient } from "@supabase/supabase-js";
import { Database } from "./supabaseTypesImport";
import { Logger } from "../logger/Logger";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function loggingFetch(url: any, options: any) {
	return fetch(url, options)
		.then((response) => {
			Logger.log("[Supabase Logs]", url, options, response);
			if (response.ok) {
				return response;
			} else {
				Logger.warn(
					"[Supabase Logs] Response error:",
					response.statusText
				);
				throw response;
			}
		})
		.catch((error) => {
			console.error("[Supabase Logs] Fetch error:", error);
			throw error;
		});
}

let options = undefined;
if (
	process.env.NODE_ENV === "test" ||
	process.env.NODE_ENV === "development" ||
	process.env.NODE_ENV === "development_testing"
) {
	options = { global: { fetch: loggingFetch.bind(globalThis) } };
}

const supabaseUrl: string =
	import.meta.env.VITE_SUPABASE_PROJECT_URL.substring(0, 4) === "http"
		? import.meta.env.VITE_SUPABASE_PROJECT_URL
		: `https://${import.meta.env.VITE_SUPABASE_PROJECT_URL}.supabase.co`;
const supabaseAnonKey: string = import.meta.env
	.VITE_SUPABASE_PUBLIC_API_ANON_KEY;

const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, options);

export { supabase };
