import { PatientNameFormat } from "../types/enums";

export function getRtfWidth(width?: number) {
	return width ? `${width}mm` : "100%";
}

export function getPatientName(
	patient: {
		firstName: string | null;
		lastName: string | null;
		title: string | null;
	},
	patientNameFormat: PatientNameFormat
) {
	if (!patient.firstName && !patient.lastName) {
		return "";
	}
	if (patientNameFormat === PatientNameFormat.LAST_NAME_COMMA_FIRST_NAME) {
		return `${patient.lastName ?? ""}, ${patient.firstName ?? ""}`;
	}
	return `${patient.lastName ?? ""}, ${patient.firstName ?? ""}, ${patient.title ?? ""}`;
}
