import { useEffect, useRef, useState } from "react";
import "../styles.css";
import { useNavigate } from "react-router-dom";
import { Offset } from "@progress/kendo-react-popup";
import {
	ContextMenu,
	MenuItem,
	MenuSelectEvent,
} from "@progress/kendo-react-layout";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { MenuItemButton } from "@/components/src/menu-item-button/menu-item-button.component";
import { useJobStore } from "../../../store/Jobs";
import { usePatientStore } from "../../../store/Patient";
import { JobStatusEnum } from "@/lib/types/job";
import { useDialog } from "@/dentlab/src/context/DialogContext";

export const JobListItem: React.FC<{
	jobId: number;
	clientId: string;
	isJobRequest: boolean;
	jobStatus: JobStatusEnum;
	onRefresh?: () => void;
	children: React.ReactNode;
}> = ({ jobId, clientId, isJobRequest, jobStatus, onRefresh, children }) => {
	const idOfSelectedJob = useJobStore((state) => state.job?.id);

	const navigate = useNavigate();
	const { showDialog } = useDialog();

	const { deleteJob, changeJobStatus } = useJobStore((state) => ({
		deleteJob: state.deleteJob,
		changeJobStatus: state.changeJobStatus,
	}));

	const handleClick = () => {
		if (!show) {
			const url = isJobRequest
				? `/client/${clientId}/request/${jobId}`
				: `/client/${clientId}/job/${jobId}`;
			navigate(url);
		}
	};

	const offSet = useRef<Offset>({ left: 0, top: 0 });
	const [show, setShow] = useState(false);

	const handleContextMenu = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		e.preventDefault();
		offSet.current = { left: e.clientX, top: e.clientY };
		setShow(true);
	};

	useEffect(() => {
		document.addEventListener("click", () => {
			show ? setShow(false) : null;
		});
	}, [show]);

	const handleOnClose = () => {
		setShow(false);
	};

	const handleOnSelect = async (e: MenuSelectEvent) => {
		switch (e.item.data.action) {
			case "new-window":
				handleNewWindow();
				break;
			case "delete":
				showDialog({
					title: "Möchtest du diesen Auftrag wirklich löschen?",
					content: "",
					actions: [
						{
							actionLabel: "Abbrechen",
							actionHandler: () => {},
						},
						{
							actionLabel: "Löschen",
							actionHandler: async () => {
								await deleteJob(jobId, jobStatus);
								onRefresh ? onRefresh() : null;
							},
						},
					],
				});
				break;
			case "archive":
				await changeJobStatus(jobId, jobStatus, JobStatusEnum.ARCHIVED);
				break;
			default:
		}
		setShow(false);
	};

	const handleNewWindow = () => {
		window.open(
			`${window.location.origin}/client/${clientId}/job/${jobId}`,
			"_blank"
		);
	};

	return (
		<>
			<MenuItemButton
				onClick={handleClick}
				onContextMenu={handleContextMenu}
				selected={idOfSelectedJob === jobId}
			>
				{children}
			</MenuItemButton>
			<ContextMenu
				vertical={true}
				onSelect={handleOnSelect}
				onClose={handleOnClose}
				show={show}
				offset={offSet.current}
				style={{ display: "inline-block" }}
			>
				<MenuItem
					data={{ action: "new-window" }}
					render={() => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									gap: "6px",
									paddingTop: "6px",
									paddingBottom: "6px",
								}}
							>
								<p>In neuem Fenster öffnen</p>
								<OpenInNewIcon fontSize="small" />
							</div>
						);
					}}
				/>
				<MenuItem
					data={{ action: "archive" }}
					render={() => {
						return (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									gap: "6px",
									paddingTop: "6px",
									paddingBottom: "6px",
								}}
							>
								<div>Archivieren</div>
							</div>
						);
					}}
				/>
				{jobStatus === JobStatusEnum.ARCHIVED && (
					<MenuItem
						data={{ action: "delete" }}
						render={() => {
							return (
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										gap: "6px",
										paddingTop: "6px",
										paddingBottom: "6px",
									}}
								>
									<div>Löschen</div>
								</div>
							);
						}}
					/>
				)}
			</ContextMenu>
		</>
	);
};

export const JobListItemContent: React.FC<{
	jobTitle: string;
	jobCode: string;
	patientId: string;
}> = ({ jobTitle, jobCode, patientId }) => {
	const { patientName, patientsLookup } = usePatientStore((state) => ({
		patientName: state.patientName,
		patientsLookup: state.patientsLookup,
	}));
	return (
		<div>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "2px",
				}}
			>
				<p>
					<b>
						{jobCode}
						{patientId && " "}
					</b>{" "}
					{patientId && patientName(patientsLookup[patientId])}
				</p>
				<p
					style={{
						fontStyle: "italic",
					}}
				>
					{jobTitle}
				</p>
			</div>
		</div>
	);
};
