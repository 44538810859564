import { Checkbox, FormControlLabel } from "@mui/material";
import { useForm } from "../../../../hooks/useForm/useForm";
import { FormProps } from "../../../form-types";
import { Database } from "../../../../../../lib/supabase";
import { TitleAndNameGroup } from "../../../form-input-components/groups/title-and-name.group";
import { AddressGroup } from "../../../form-input-components/groups/address.group";
import {
	GuarantorEntityType,
	SupabaseTableEnum,
} from "../../../../../../lib/supabase/supabaseTypes";
import React from "react";
import { CustomTextField } from "../../../form-input-components/custom-input-components/custom-text-field.component";
import { FormSectionWithSaveButton } from "@/dentlab/src/components/dialog-components/form-section-with-save-button/form-section-with-save-button.component";
import { FormSectionWithTitle } from "@/dentlab/src/components/form-section-with-title/form-section-with-title.component";
import { showNotification } from "@/dentlab/src/store/Central/selectors";
import { BankAccountSelect } from "../../bank-account/input-components/bank-account.select";
import { useCentralStore } from "@/dentlab/src/store/Central";

export const GuarantorForm: React.FC<
	FormProps & {
		guarantorId?: string;
	}
> = ({
	onSave = (result: GuarantorEntityType) => {},
	submitRef = null,
	guarantorId = undefined,
}) => {
	const supabaseTable = SupabaseTableEnum.GUARANTORS;

	const organizationId = useCentralStore((state) => state.organization?.id);
	const { getGuarantorLookup } = useCentralStore.getState();

	const isUpdate = guarantorId ? true : false;
	const useFormCustomFields = {
		organization_id: organizationId,
	};
	const useFormFetchData = isUpdate ? true : false;
	const useFormFetchId = isUpdate ? guarantorId : undefined;

	const {
		handleInputChange,
		handleCheckboxChange,
		handleCreate,
		formData,
		formErrors,
		handleValueChange,
		handleUpdate,
		resetFormData,
	} = useForm<Database["public"]["Tables"]["guarantors"]["Row"]>(
		useFormCustomFields,
		supabaseTable,
		useFormFetchData,
		useFormFetchId,
		"Garant"
	);

	const handleUpdateSubmit = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		return await handleUpdate(event, useFormFetchId);
	};

	const handleInsertSubmit = async (
		event: React.FormEvent<HTMLFormElement>
	) => {
		return await handleCreate(event);
	};

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		const { success, data } = isUpdate
			? await handleUpdateSubmit(event)
			: await handleInsertSubmit(event);
		if (success && data) {
			await getGuarantorLookup();
			onSave(data[0]);
		} else {
			showNotification({
				message: "Es ist ein Fehler aufgetreten",
				type: "error",
			});
		}
	};

	return (
		<FormSectionWithSaveButton
			onSubmit={onSubmit}
			onResetForm={resetFormData}
		>
			<button ref={submitRef} type="submit" style={{ display: "none" }} />
			<FormSectionWithTitle title="">
				<CustomTextField
					label="Garanten-Code"
					value={formData.code || ""}
					name="code"
					onChange={handleInputChange}
					required
				/>
				<TitleAndNameGroup
					formData={formData}
					formErrors={formErrors}
					onChange={handleInputChange}
					onValueChange={(args) =>
						handleValueChange(args.name, args.value)
					}
					requiredFields={["title", "first_name", "last_name"]}
				/>
				<AddressGroup
					formData={formData}
					formErrors={formErrors}
					onChange={handleInputChange}
					onCheckboxChange={handleCheckboxChange}
					exclude={["phone_personal"]}
					onValueChange={(args) =>
						handleValueChange(args.name, args.value)
					}
					requiredFields={[
						"street",
						"city",
						"postal_code",
						"country",
					]}
				/>
				<FormControlLabel
					control={
						<Checkbox
							name="is_print_skonto"
							checked={formData.is_print_skonto ? true : false}
							onChange={handleCheckboxChange}
						/>
					}
					label={"Skonto statt Rabatt drucken"}
				/>
				<BankAccountSelect
					onChange={(value) =>
						handleValueChange("bank_account_id", value)
					}
					value={
						formData.bank_account_id
							? formData.bank_account_id.toString()
							: ""
					}
				/>
			</FormSectionWithTitle>
		</FormSectionWithSaveButton>
	);
};
