import React from "react";
import { useEffect, useState } from "react";
import { SidebarType } from "../../types/types";
import { JobPageHeader } from "./job-page-components/job-page-header/job-page-header.component";
import { RightSidebar } from "./job-page-components/right-sidebar.component";
import { FilesContextProvider } from "../../context/FilesContext";
import { JobInfo } from "./job-page-components/job-info.component";
import { RefContextProvider, useRefContext } from "../../context/RefContext";
import { JobDocument } from "./job-document/job-document.component";
import { isShared } from "../../../../lib/supabase/supabaseTypes";
import { Loading } from "@/components/src/animations/loading";
import { JobStatusEnum } from "@/lib/types/job";
import { JobNotFoundComponent } from "./job-page-components/job-not-found.component";
import { useJobStore } from "../../store/Jobs";
import { useCentralStore } from "../../store/Central";
import { Button } from "@mui/material";
import { NewJobDocumentArea } from "./job-page-components/new-job-document-area/new-job-document-area.component";
import { JobDocumentDialog } from "./job-page-components/job-document-dialog.component";
import { JobDocumentTypeEnum } from "./job-document/job-document.types";

/**
 * JobPage - Page displaying information for a single job, accessed via /job/:jobId
 * The job page uses useJobStore to handle all state related to the job, and the corresponding client and patient enttities.
 */
const JobPageComponent: React.FC = () => {
	const { client } = useCentralStore((state) => ({
		organization: state.organization,
		client: state.client,
	}));
	const { debugMode } = useCentralStore((state) => ({
		debugMode: state.debugMode,
	}));
	const { jobDocuments, job, changeJobStatus, jobLoading } = useJobStore(
		(state) => ({
			jobDocuments: Object.values(state.jobDocuments).sort((a, b) => {
				if (
					!a.created_at ||
					!b.created_at ||
					a.created_at == b.created_at
				)
					return 0;
				return a.created_at > b.created_at ? 1 : -1;
			}),
			job: state.job,
			deleteJobDocument: state.deleteJobDocument,
			changeJobStatus: state.changeJobStatus,
			jobLoading: state.jobLoading,
		})
	);

	const clientId = client?.id;
	const { container } = useRefContext();

	useEffect(() => {
		// This will close the sidebar at the start and open it after 200 ms to give the illusion of it opening when a new job is created
		setSelectedSidebar(null);
		// This is a hack to open the jobInfo sidebar when there are no job documents
		setTimeout(() => {
			if (jobDocuments.length === 0) {
				setSelectedSidebar("jobInfo");
			}
		}, 200);
	}, [jobDocuments.length]);

	const [selectedSidebar, setSelectedSidebar] = useState<SidebarType | null>(
		null
	);

	const [openJobDocumentDialog, setOpenJobDocumentDialog] =
		useState<boolean>(false);

	useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === "d" && (e.metaKey || e.ctrlKey)) {
				e.preventDefault();
				setOpenJobDocumentDialog((open) => !open);
			}
		};

		document.addEventListener("keydown", down);
		return () => document.removeEventListener("keydown", down);
	}, []);

	const handleSidebarClose = () => {
		setSelectedSidebar(null);
	};

	if (jobLoading) {
		return <Loading />;
	}
	if (job && job.client_id !== clientId) {
		return (
			<div>
				Die Referenz des Auftrags zum Auftraggeber stimmt nicht mit dem
				aktuellen Auftraggeber überein. {job.client_id} {clientId}
			</div>
		);
	}

	// TODO: Distinguish between a job created by a lab that's shared and a job
	// created by a med
	if (job?.status == JobStatusEnum.NOT_STARTED && isShared(job)) {
		return (
			<div className="w-full flex-col gap-8 h-full flex items-center justify-center">
				<span className="text-2xl font-bold">
					Eingehender Auftrag aus dentCONNECT
				</span>
				<div className="flex gap-2">
					<Button
						onClick={() => {
							if (job.id) {
								changeJobStatus(
									job.id,
									job.status as JobStatusEnum,
									JobStatusEnum.IN_PROGRESS
								);
							}
						}}
					>
						Akzeptieren
					</Button>
					<Button
						onClick={() => {
							if (job.id) {
								changeJobStatus(
									job.id,
									job.status as JobStatusEnum,
									JobStatusEnum.ARCHIVED
								);
							}
						}}
					>
						Ablehnen
					</Button>
				</div>
			</div>
		);
	}
	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				display: "flex",
				flexDirection: "row",
			}}
			ref={container}
		>
			{debugMode && (
				<div className="absolute bottom-0 left-0 bg-white">
					Job ID: {job?.id}
				</div>
			)}
			<div
				style={{
					flex: 1,
					overflow: "auto",
				}}
			>
				<div
					style={{
						position: "sticky",
						top: 0,
						zIndex: 10,
					}}
				>
					<JobPageHeader
						isSidebarOpen={selectedSidebar !== null}
						onSidebarOpen={(type: SidebarType) =>
							setSelectedSidebar(type)
						}
					/>
				</div>
				{jobDocuments &&
					jobDocuments.map((doc) => (
						<JobDocument key={doc.id} jobDocument={doc} />
					))}
				<NewJobDocumentArea />
			</div>
			<JobDocumentDialog
				open={openJobDocumentDialog}
				setOpen={setOpenJobDocumentDialog}
				templateType={JobDocumentTypeEnum.DeliveryNote}
			/>
			{selectedSidebar === "jobInfo" && job && (
				<RightSidebar
					title={"Auftragsinformationen"}
					onClose={handleSidebarClose}
				>
					<JobInfo />
				</RightSidebar>
			)}
		</div>
	);
};

export const JobPage: React.FC = () => {
	// retrieve jobId from url /job/:jobId
	const organizationId = useCentralStore((state) => state.organization?.id);
	const job = useJobStore((state) => state.job);
	if (!organizationId) {
		return <Loading />;
	}

	if (!job?.id) {
		return <JobNotFoundComponent />;
	}

	return (
		<FilesContextProvider
			fileIdReferences={{
				organization_id: organizationId,
				job_id: job.id,
			}}
		>
			<RefContextProvider>
				<JobPageComponent />
			</RefContextProvider>
		</FilesContextProvider>
	);
};
