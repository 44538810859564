import { Button, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useFormDialog } from "../../../hooks/useFormDialog";
import {
	PractitionerForm,
	PractitionerFormProps,
} from "../../../forms/form-components/practitioner/insert-update/practitioner.form";
import {
	PractsWithCtViewType,
	SupabaseTableEnum,
} from "../../../../../lib/supabase/supabaseTypes";
import { usePractitionersContext } from "../../../context/single-entity-contexts/PractitionersContext";
import { showNotification } from "@/dentlab/src/store/Central/selectors";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { supabase } from "@/lib/supabase";
import { Logger } from "@/lib/logger/Logger";
import DeleteIcon from "@mui/icons-material/Delete";

export const PractitionerList: React.FC = () => {
	const {
		practitioners: { practitionersByClientIdLookup },
		updatePractitioners,
	} = usePractitionersContext();
	const { clientId } = useCentralStore((state) => ({
		clientId: state.clientId,
	}));
	const practitioners = practitionersByClientIdLookup[clientId ?? ""] ?? [];

	const { openDialog, dialog } = useFormDialog<PractitionerFormProps>({
		onSubmit: (practitioner: PractsWithCtViewType) => {
			if (!clientId) {
				showNotification({
					message: "Auftraggeber nicht gefunden.",
					type: "error",
				});
				return;
			}

			if (!updatePractitioners) {
				showNotification({
					message: "Fehler beim Hinzufügen des Behandlers.",
					type: "error",
				});
				return;
			}

			// case if there are no practitioners yet
			if (practitioners.length === 0) {
				updatePractitioners([practitioner]);
				return;
			}

			const existingPractitioner = practitioners.find(
				(pract) => pract.id === practitioner.id
			);

			const newPractitioners = existingPractitioner
				? practitioners.map((pract) =>
						pract.id === practitioner.id ? practitioner : pract
					)
				: [...practitioners, practitioner];

			updatePractitioners(newPractitioners);
		},
		FormComponent: PractitionerForm,
		dialogTitle: "Behandler hinzufügen",
	});

	// If the edit button is clicked, the practitioner id is stored in state and the dialog is opened
	const handleOpenExistingPractitionerForm = (practitionerId: number) => {
		openDialog({ practitionerId: practitionerId });
	};

	// If the add button is clicked, the dialog is opened with clientId as props instead of practitionerId
	const handleOpenNewPractitionerForm = () => {
		if (!clientId) {
			showNotification({
				message: "Auftraggeber nicht gefunden.",
				type: "error",
			});
			return;
		}
		openDialog({ clientId: clientId });
	};

	const deletePractitioner = async (id: number) => {
		if (!updatePractitioners) return;

		const index = practitioners.findIndex((pract) => pract.id === id);
		if (index === -1) return;

		const [practitionerToRemove] = practitioners.splice(index, 1);

		updatePractitioners([...practitioners]);

		const { error } = await supabase
			.from(SupabaseTableEnum.PRACTITIONERS)
			.delete()
			.eq("id", id);

		if (error) {
			updatePractitioners([...practitioners, practitionerToRemove]);
			showNotification({
				message: "Fehler beim Löschen des Behandlers",
				type: "error",
			});
			Logger.error(error, {}, "Error deleting practitioner");
		} else {
			showNotification({
				message: "Behandler erfolgreich gelöscht",
				type: "success",
			});
		}
	};

	return (
		<div>
			<div className="flex flex-col gap-2 mt-4">
				{practitioners.map((practitioner: PractsWithCtViewType) => (
					<div key={practitioner.id} className="flex items-center">
						{practitioner.first_name} {practitioner.last_name}{" "}
						{practitioner.email}
						{practitioner.connect_relationship_id &&
							" (besitzt CONNECT-Account)"}
						<IconButton
							aria-label="delete"
							size="medium"
							onClick={() => {
								handleOpenExistingPractitionerForm(
									practitioner.id ?? 0
								);
							}}
						>
							<EditIcon />
						</IconButton>
						<Tooltip title="Behandler löschen">
							<div>
								<IconButton
									size="medium"
									onClick={() =>
										deletePractitioner(
											practitioner.id as number
										)
									}
									aria-label="delete"
								>
									<DeleteIcon />
								</IconButton>
							</div>
						</Tooltip>
					</div>
				))}
			</div>
			<Button onClick={handleOpenNewPractitionerForm}>
				Behandler hinzufügen
			</Button>
			{dialog}
		</div>
	);
};
