import * as Sentry from "@sentry/react";

const isLoggerEnabled: boolean =
	process.env.NODE_ENV === "test" ||
	process.env.NODE_ENV === "development" ||
	import.meta.env.VITE_APP_TITLE === "dentLAB-dev_testing" ||
	import.meta.env.VITE_APP_TITLE === "dentCONNECT-dev_testing";

const isProduction: boolean =
	import.meta.env.VITE_APP_TITLE === "dentLAB" ||
	import.meta.env.VITE_APP_TITLE === "dentCONNECT";

export enum SentryCategoryTag {
	XML = "xml",
	PDF = "pdf",
	DATABASE = "database",
}

type SentryTags = {
	category?: SentryCategoryTag;
};

/**
 * Usage of Logger:
 * - .log -> standard logging
 * - .warn -> warning, don't send to Sentry
 * - .error -> log as error and send to Sentry
 *
 * Logging to Sentry
 * error: Send the error or a string that describes the error
 * tags: Send the tags as second argument (used for alerts)
 * ...params: Send the rest of the arguments as additional arguments for console logging
 */
export class Logger {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static log = (...params: any[]): void => {
		if (isLoggerEnabled) {
			// eslint-disable-next-line no-console
			console.log(...params);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static warn = (...params: any[]): void => {
		if (isLoggerEnabled) {
			console.warn(...params);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static error = (
		error: Error | string | any,
		tags?: SentryTags,
		...params: any[]
	): void => {
		const sentryError: Error =
			error instanceof Error ? error : new Error(String(error));

		// in all environments, send errors to Sentry
		const sentryId = Sentry.captureException(sentryError, {
			tags: tags,
		});

		// show errors in all environments except production
		if (!isProduction) {
			// eslint-disable-next-line no-console
			console.error(error, tags, ...params, sentryId);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static info = (...params: any[]): void => {
		if (isLoggerEnabled) {
			// eslint-disable-next-line no-console
			console.info(
				"%cINFO\n\n",
				"font-weight: bold; color: blue;",
				...params
			);
		}
	};
}
