import { createTheme } from "@mui/material";
import { deDE } from "@mui/material/locale";

export const theme = createTheme(
	{
		palette: {
			primary: {
				main: "#003261",
				light: "#83C3FF",
				dark: "#00284E",
			},
			secondary: {
				main: "#06A77C",
				light: "#08E8AD",
				dark: "#03543E",
			},
			common: {
				black: "#000",
				white: "#fff",
			},
		},
		components: {
			MuiButton: {
				defaultProps: {
					size: "small",
				},
			},
			MuiFilledInput: {
				defaultProps: {
					margin: "dense",
				},
			},
			MuiFormControl: {
				defaultProps: {
					margin: "dense",
				},
			},
			MuiFormHelperText: {
				defaultProps: {
					margin: "dense",
				},
			},
			MuiIconButton: {
				defaultProps: {
					size: "small",
				},
			},
			MuiInputBase: {
				defaultProps: {
					margin: "dense",
				},
			},
			MuiInputLabel: {
				defaultProps: {
					margin: "dense",
				},
			},
			MuiListItem: {
				defaultProps: {
					dense: true,
				},
			},
			MuiOutlinedInput: {
				defaultProps: {
					margin: "dense",
				},
			},
			MuiFab: {
				defaultProps: {
					size: "small",
				},
			},
			MuiTable: {
				defaultProps: {
					size: "small",
				},
			},
			MuiTextField: {
				defaultProps: {
					margin: "dense",
					size: "small",
				},
			},
			MuiToolbar: {
				defaultProps: {
					variant: "dense",
				},
			},
		},
	},
	deDE
);
