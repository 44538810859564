import { DataGrid } from "@mui/x-data-grid";
import { deDE } from "@mui/x-data-grid/locales";

export const TariffsOrArticlesTable: React.FC<{
	rows: any[];
	columns: any[];
	onRowClick: (params: any) => void;
}> = ({ rows, columns, onRowClick }) => {
	return (
		// Add padding at the bottom to avoid colliding with the Intercom chat
		<div className="pb-24">
			<DataGrid
				localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
				rows={rows}
				columns={columns}
				autoHeight
				density="compact"
				onRowClick={onRowClick}
				initialState={{
					sorting: {
						sortModel: [{ field: "code_e", sort: "asc" }],
					},
				}}
				sx={{
					"& .MuiDataGrid-row:hover": {
						backgroundColor: "rgba(0, 0, 0, 0.04)",
						cursor: "pointer",
					},
				}}
			/>
		</div>
	);
};
