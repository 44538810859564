import { Check } from "lucide-react";
import { Close } from "@mui/icons-material";
import { useCentralStore } from "../../store/Central";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../lib/utils/utils-functions";
import { TariffDialog } from "./tariff-dialog.component";
import { TariffsOrArticlesTable } from "./tariffs-and-articles-table.component";
const tariffsColumns = [
	// Zur Ansicht
	// Tarif-Position (code) is what is printed on the invoice
	{
		field: "code",
		headerName: "Anzeige",
		explanation: "Tarif-Position (für Anzeige in Dokumenten)",
		width: 80,
	},
	// Zur Eingabe
	// Tarifcode (code_e) is what is used for entering the tariff in the invoice
	{
		field: "code_e",
		headerName: "Eingabe",
		explanation: "Tarif-Code (für Eingabe in Rechnungen)",
		width: 80,
	},
	{
		field: "description_de",
		headerName: "Bezeichnung (D)",
		width: 200,
	},
	{
		field: "description_fr",
		headerName: "Bezeichnung (F)",
		width: 200,
	},
	{
		field: "description_it",
		headerName: "Bezeichnung (I)",
		width: 200,
	},
	{
		field: "additional_text",
		headerName: "Zusatztext",
		width: 200,
	},
	{
		field: "is_req_dr_tax",
		type: "boolean",
		headerName: "Arztstr.",
		explanation: "Ärztesteuerpflichtig",
		renderCell: (params: any) => {
			return params.value ? <Check /> : <Close />;
		},
	},
	{
		field: "composition",
		headerName: "Zusammensetzung",
		width: 140,
	},
	{ field: "tp_sv", headerName: "SV", width: 100 },
	{ field: "tp_pp1", headerName: "PP1", width: 100 },
	{ field: "tp_pp2", headerName: "PP2", width: 100 },
	{ field: "tp_pp3", headerName: "PP3", width: 100 },
	{
		field: "tp_value",
		headerName: "Eigener TPW",
	},
	{
		field: "standard_quantity",
		headerName: "Mg.",
		explanation: "Vorgabemenge",
	},
	{
		field: "modified_at",
		headerName: "Änderung",
		width: 120,
		renderCell: (params: any) => {
			return params.row.custom
				? formatDate(params.row.modified_at)
				: "Keine";
		},
	},
];

export const TariffsOverviewPage = () => {
	const { tariffs } = useCentralStore((state) => ({
		tariffs: Object.values(state.tariffs),
	}));
	const navigate = useNavigate();

	return (
		<div>
			<div className="w-full flex justify-between items-center p-6">
				<div className="text-2xl">Tarife</div>
				<TariffDialog />
			</div>
			<div className="p-2">
				<TariffsOrArticlesTable
					rows={tariffs}
					columns={tariffsColumns}
					onRowClick={(params) => {
						navigate(`/tariff/${params.row.code_e}`);
					}}
				/>
			</div>
		</div>
	);
};
