import { Paper } from "@mui/material";
import { DataGrid, GridRowsProp } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { SupabaseTableEnum } from "../../../../lib/supabase/supabaseTypes";
import { useToast } from "../../context/ToastContext";
import { Logger } from "@/lib/logger/Logger";
import {
	createMuiDataGridColumn,
	getMuiDataGridColumnsWithEditRowButton,
} from "../../lib/helpers";
import { useCentralStore } from "../../store/Central";
import { supabase } from "@/lib/supabase";
import { deDE } from "@mui/x-data-grid/locales";

const columns = [
	createMuiDataGridColumn("bank_name", "Bankname"),
	createMuiDataGridColumn("bank_city", "Bankort"),
	createMuiDataGridColumn("cdtr_name", "Name"),
	createMuiDataGridColumn("cdtr_street_line_1", "Strasse"),
	createMuiDataGridColumn("cdtr_house_number_line_2", "Hausnummer"),
	createMuiDataGridColumn("cdtr_area_code", "PLZ"),
	createMuiDataGridColumn("cdtr_city", "Ort"),
	createMuiDataGridColumn("cdtr_country", "Land"),
];

export const BankAccountList: React.FC<{
	onEdit: (bankAccountId: string) => void;
}> = ({ onEdit }) => {
	const [rows, setRows] = useState<GridRowsProp>([]);
	const organizationId = useCentralStore((state) => state.organization?.id);
	const { showToast } = useToast();

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		if (!organizationId) {
			Logger.error("Organization ID not found");
			return;
		}

		const { data, error } = await supabase
			.from(SupabaseTableEnum.BANK_ACCOUNTS)
			.select()
			.eq("organization_id", organizationId);
		if (error) {
			showToast("Error beim Abrufen der Konten", "error");
			Logger.error(error, {}, "Error fetching bank accounts");
		}
		setRows(data ?? []);
	};

	return (
		<Paper>
			<DataGrid
				localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
				rows={rows}
				columns={getMuiDataGridColumnsWithEditRowButton(
					onEdit,
					columns
				)}
				sx={{
					height: "100%",
					width: "100%",
					border: 0,
					"& .MuiDataGrid-footerContainer": {
						display: "none",
					},
				}}
			/>
		</Paper>
	);
};
