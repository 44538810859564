import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useCentralStore } from "../../store/Central";
import { useJobItemsContext } from "../../context/JobItemsContext";
import { TariffForm } from "./tariff-form.component";
import { Button } from "@mui/material";
import { PageTitle } from "@/components/src/page-title/page-title.component";
import { CustomButtonWithAnchoredMenu } from "../../components/custom-button-with-anchored-menu/custom-button-with-anchored-menu.component";
import { CustomMenuItem } from "../job-page/job-document/job-document-components/job-document-customMenuItem.component";
import { showNotification } from "../../store/Central/selectors";

export const TariffPage = () => {
	const { code } = useParams();
	const { tariff, getTariffs, deleteTariff } = useCentralStore((state) => ({
		tariff: state.tariffs[code ?? ""],
		getTariffs: state.getTariffs,
		deleteTariff: state.deleteTariff,
	}));
	const { handleUpdateTariff } = useJobItemsContext();
	const navigate = useNavigate();

	const methods = useForm({
		defaultValues: { ...tariff },
	});

	useEffect(() => {
		methods.reset({ ...tariff });
	}, [methods.reset, tariff]);

	const onSubmit = async (data: any) => {
		await handleUpdateTariff(data);
		await getTariffs();
	};

	const handleDelete = async () => {
		if (!tariff?.id) return;
		if (!tariff.custom) {
			showNotification({
				message:
					"Tarif kann nicht gelöscht werden, da er ein Standardtarif ist. Nur benutzerdefinierte Tarife können gelöscht werden.",
				type: "warning",
			});
		} else {
			await deleteTariff(tariff.id);
			navigate("/tariff");
		}
	};

	if (!tariff) {
		return (
			<div className="w-full h-full flex flex-col gap-4 justify-center items-center text-gray-900">
				<div>Dieser Tarif existiert nicht</div>
				<Button
					onClick={() => {
						navigate("/tariff");
					}}
				>
					Zurück zur Übersicht
				</Button>
			</div>
		);
	}

	return (
		<div className="p-6">
			<div className="flex justify-between items-center">
				<PageTitle
					title={[
						{ name: "Tarife", link: "/tariff" },
						{
							name: tariff?.code_e.toString() ?? "Neue Tarife",
						},
					]}
				/>
				<CustomButtonWithAnchoredMenu>
					<CustomMenuItem
						title=""
						menuFunction={handleDelete}
						disabled={false}
						text="Tarif löschen"
						placement="left"
					/>
				</CustomButtonWithAnchoredMenu>
			</div>
			<FormProvider {...methods}>
				<TariffForm
					showSaveButton
					onSubmit={onSubmit}
					tariff={tariff}
				/>
			</FormProvider>
		</div>
	);
};
