import {
	Chip,
	CircularProgress,
	IconButton,
	Menu,
	MenuItem as MuiMenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./styles.css";
import { JobStatusEnum } from "@/lib/types/job";
import { FilterList } from "@mui/icons-material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { useJobStore } from "../../store/Jobs";
import { useCentralStore } from "../../store/Central";
import {
	JobListItem,
	JobListItemContent,
} from "./job-list-components/job-list-item.component";

// TODO: find more elegant way for prop drilling onRefresh
// TODO: in this case don't refresh jobs in ClientJobList component but simply use setJobs, filtering out the deleted job

const jobStatusOptions = [
	{
		status: JobStatusEnum.NOT_STARTED,
		label: "Voranmeldung",
	},
	{
		status: JobStatusEnum.IN_PROGRESS,
		label: "In Arbeit",
	},
	{
		status: JobStatusEnum.COMPLETED,
		label: "Geliefert",
	},
];

export const JobList: React.FC<{
	onRefresh?: () => void;
}> = ({ onRefresh }) => {
	return (
		<div
			style={{
				paddingTop: "6px",
			}}
		>
			{jobStatusOptions.map((option) => (
				<SingleJobList
					key={option.label}
					label={option.label}
					onRefresh={onRefresh}
					status={option.status}
				/>
			))}
		</div>
	);
};

const dateFilters = [
	{
		label: "Alle",
		value: 0, // 0 means all time
	},
	{
		label: "1 Jahr",
		value: 1,
	},
	{
		label: "2 Jahre",
		value: 2,
	},
	{
		label: "3 Jahre",
		value: 3,
	},
	{
		label: "4 Jahre",
		value: 4,
	},
	{
		label: "5 Jahre",
		value: 5,
	},
];
const SingleJobList: React.FC<{
	label: string;
	status: JobStatusEnum;
	onRefresh?: () => void;
}> = ({ label, status, onRefresh }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openFilter = Boolean(anchorEl);
	const [filterIsLoading, setFilterIsLoading] = useState(false);
	const [jobsLoading, setJobsLoading] = useState(false);

	// as a loading of less than 300ms is barely perceptible to the user, we use the
	// loading state only if loading exceeds 300ms
	const [loadingExceeded, setLoadingExceeded] = useState(false);
	const {
		jobList,
		selectedJobStatus,
		fetchJobsByStatus,
		changeSelectedJobStatus,
	} = useJobStore((state) => ({
		jobList: state.jobList,
		selectedJobStatus: state.selectedJobStatus,
		fetchJobsByStatus: state.fetchJobsByStatus,
		changeSelectedJobStatus: state.changeSelectedJobStatus,
	}));

	const handleToggleList = (status: JobStatusEnum) => {
		if (selectedJobStatus == status) changeSelectedJobStatus(null);
		else changeSelectedJobStatus(status);
	};

	const { clientId, searchConfig, updateSearchConfig } = useCentralStore();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	// await fetchJobsByStatus because we need to wait for the jobs to be fetched before we can set the loading state to false
	const asyncFetchJobsByStatus = async (status: JobStatusEnum) => {
		setLoadingExceeded(false);
		setJobsLoading(true);
		setTimeout(() => {
			setLoadingExceeded(true);
		}, 300);
		await fetchJobsByStatus(status);
		setJobsLoading(false);
	};

	useEffect(() => {
		asyncFetchJobsByStatus(status);
	}, [clientId, status]);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleFilter = async (filter: number) => {
		setFilterIsLoading(true);
		setAnchorEl(null);
		await updateSearchConfig({
			statuses: {
				...(searchConfig?.statuses as Record<JobStatusEnum, number>),
				[status]: filter,
			},
		});
		setFilterIsLoading(false);
	};

	const jobCount = jobList[status].jobCount;

	const jobs = jobList[status].jobs;

	return (
		<div>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<IconButton
						aria-label="open"
						size="small"
						onClick={() => handleToggleList(status)}
					>
						{selectedJobStatus == status ? (
							<ArrowDropDownIcon fontSize="small" />
						) : (
							<ArrowRightIcon fontSize="small" />
						)}
					</IconButton>
					<div className="flex flex-row items-center">
						{label}
						{jobCount > 0 && !jobsLoading && (
							<Chip
								label={jobCount}
								size="small"
								style={{
									marginLeft: "6px",
									fontSize: "12px",
								}}
							/>
						)}
						{jobsLoading && loadingExceeded && (
							<CircularProgress
								size={10}
								style={{
									marginLeft: "6px",
								}}
							/>
						)}
					</div>
				</div>
				<div
					style={{
						position: "relative",
					}}
				>
					<IconButton
						disabled={Boolean(anchorEl)}
						onClick={handleClick}
					>
						{filterIsLoading ? (
							<AutorenewIcon fontSize="small" />
						) : (
							<FilterList fontSize="small" />
						)}
					</IconButton>
					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={openFilter}
						onClose={handleClose}
						MenuListProps={{
							"aria-labelledby": "basic-button",
						}}
					>
						{dateFilters.map((filter) => (
							<MuiMenuItem
								onClick={() => handleFilter(filter.value)}
								key={filter.label}
								sx={{
									backgroundColor:
										searchConfig?.statuses?.[status] ===
										filter.value
											? "var(--neutral-100)"
											: "white",
								}}
							>
								{filter.label}
							</MuiMenuItem>
						))}
					</Menu>
				</div>
			</div>
			{selectedJobStatus === status && (
				<div
					style={{
						maxHeight: "40vh",
						overflowY: "auto",
					}}
				>
					{!jobsLoading &&
						jobs?.map((job) => (
							<JobListItem
								key={job.id}
								jobId={job.id ?? 0}
								clientId={job.client_id ?? ""}
								isJobRequest={(job as any).request ?? false}
								jobStatus={job.status as JobStatusEnum}
								onRefresh={onRefresh}
							>
								<JobListItemContent
									jobTitle={job.title ?? ""}
									jobCode={job.code ?? ""}
									patientId={job.patient_id ?? ""}
								/>
							</JobListItem>
						))}
				</div>
			)}
		</div>
	);
};
