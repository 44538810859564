import {
	CommandDialog,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@lab/src/components/ui/command";
import { useJobStore } from "@/dentlab/src/store/Jobs";
import { useTemplatesStore } from "@/dentlab/src/store/Templates";
import { JobDocumentTypeEnum } from "../job-document/job-document.types";
import { displayTemplateLabel } from "@/dentlab/src/store/Templates/utils";

export const JobDocumentDialog: React.FC<{
	open: boolean;
	setOpen: (open: boolean) => void;
	templateType: JobDocumentTypeEnum;
}> = ({ open, setOpen, templateType }) => {
	const { createJobDocumentFromTemplate, isCreateJobDocumentAllowed } =
		useJobStore((state) => ({
			createJobDocumentFromTemplate: state.createJobDocumentFromTemplate,
			isCreateJobDocumentAllowed: state.isCreateJobDocumentAllowed,
		}));

	// The options are unique because the codes are unique
	const jobDocumentTemplateOptions = useTemplatesStore((state) =>
		Object.values(state.templatesLookup).map((template) => ({
			label: displayTemplateLabel(template.code, template.name),
			id: template.id,
		}))
	);

	return (
		<CommandDialog onOpenChange={setOpen} open={open}>
			<CommandInput
				placeholder="Auftragsvorlage suchen…"
				className="px-4"
			/>{" "}
			<CommandList className="w-[640px] h-96 max-h-96">
				<CommandGroup heading="Jumbos/Vorlagen" className="w-full">
					{jobDocumentTemplateOptions.map((template) => {
						return (
							<CommandItem
								onSelect={() => {
									createJobDocumentFromTemplate(
										template.id,
										templateType
									);
									setOpen(false);
								}}
								key={template.id}
								// The options are unique because the codes are unique
								value={template.label ?? template.id.toString()}
								disabled={
									!isCreateJobDocumentAllowed(templateType)
										.isAllowed
								}
							>
								<span>{template.label}</span>
							</CommandItem>
						);
					})}
				</CommandGroup>
			</CommandList>
		</CommandDialog>
	);
};
