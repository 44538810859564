export enum PatientNameFormat {
	LAST_NAME_COMMA_FIRST_NAME = 1,
	LAST_NAME_COMMA_FIRST_NAME_COMMA_TITLE = 2,
}

export const patientNameFormatOptions = [
	{
		value: PatientNameFormat.LAST_NAME_COMMA_FIRST_NAME,
		label: "Nachname, Vorname",
	},
	{
		value: PatientNameFormat.LAST_NAME_COMMA_FIRST_NAME_COMMA_TITLE,
		label: "Nachname, Vorname, Titel",
	},
];

export enum TpTier {
	SV = 0,
	PP1 = 1,
	PP2 = 2,
	PP3 = 3,
}

export enum TpValue {
	OLD = 5.55,
	NEW = 1.0,
}

export enum ConfigurationColumns {
	DOCUMENTS = "documents_config",
}

/**
 * V1 main bucket, guarded via RLS on public.files (if you have access to the entry in public.files, you have access to the path specified in the path_name column)
 * - read is guarded by RLS on public.files
 * - anyone can upload if authenticated
 *
 * In ORG_V1 every organization has its own folder by org_id and can only access the folder of their organization (via org_id in app_metadata). The paths inside that folder don't matter.
 * - all operations are allowed in one's own folder
 *
 * V1_PUBLIC is for profile pictures and logos
 * - make sure to only upload to folders secured with a uuid
 * - only image uploads are allowed (image/*)
 */
export enum StorageBucketsEnum {
	V1 = "v1",
	ORG_V1 = "org_v1",
	PUBLIC_IMAGES = "pub_img",
}

export enum Currency {
	CHF = "CHF",
	EUR = "EUR",
}

export enum RolesEnum {
	OWNER = 0,
	ADMIN = 1,
	MEMBER = 2,
}
