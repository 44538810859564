import { StateCreator } from "zustand";
import { supabase } from "@/lib/supabase";
import {
	ActivityTypeEnum,
	JobRequestEntityType,
	JobWithShare,
	SupabaseShareEntityViewEnum,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { JobStatusEnum } from "@/lib/types/job";
import { Logger } from "@/lib/logger/Logger";
import { useCentralStore } from "../Central";
import { produce } from "immer";
import { showNotification } from "../Central/selectors";
import { JobStoreUnion } from ".";

// One might argue that this should be part of the client store. Why?
// - Because the it's the list of jobs for the client.
// - Because the job store focused on the currently selected job and is impacted by the job route.
// However, the job list tracks the status and whether a job is shared. Therefore, it should be mutated by the job store.

interface JobListState {
	jobList: Record<
		JobStatusEnum,
		{
			jobCount: number;
			jobs: JobWithShare[];
		}
	>;
	jobRequests: JobRequestEntityType[] | null;
	selectedJobStatus: JobStatusEnum | null;
	pinnedJobs: Record<string, JobWithShare>;
}

export const initialJobListState: JobListState = {
	jobList: {
		[JobStatusEnum.BOOKED_SINGLE]: {
			jobCount: 0,
			jobs: [],
		},
		[JobStatusEnum.IN_PROGRESS]: {
			jobCount: 0,
			jobs: [],
		},
		[JobStatusEnum.COMPLETED]: {
			jobCount: 0,
			jobs: [],
		},
		[JobStatusEnum.NOT_STARTED]: {
			jobCount: 0,
			jobs: [],
		},
		[JobStatusEnum.BOOKED_MONTHLY]: {
			jobCount: 0,
			jobs: [],
		},
		[JobStatusEnum.ARCHIVED]: {
			jobCount: 0,
			jobs: [],
		},
	},
	jobRequests: null,
	selectedJobStatus: null,
	pinnedJobs: {},
};

export interface JobListSlice extends JobListState {
	fetchJobsByStatus: (status: JobStatusEnum) => Promise<void>;
	changeSelectedJobStatus: (status: JobStatusEnum | null) => void;
	// This function accepts/rejects a job request and correspondingly creates
	// a new one if it's accepted
	changeRequestToJob: (
		jobRequest: JobRequestEntityType,
		accepted: boolean
	) => Promise<number | null>;
	fetchJobRequest: (
		jobRequestId: number
	) => Promise<JobRequestEntityType | null>;
	pinJob: (job: JobWithShare) => void;
	unpinJob: (jobId: number) => void;
	unpinAllJobs: () => void;
}

export const createJobListStore: StateCreator<
	JobStoreUnion,
	[],
	[],
	JobListSlice
> = (set, get) => ({
	...initialJobListState,
	fetchJobsByStatus: async (status: JobStatusEnum) => {
		const searchConfig = useCentralStore.getState().searchConfig;

		let year = searchConfig?.statuses?.[status] ?? 0;
		const clientId = useCentralStore.getState().clientId;

		if (!clientId) return;

		if (year === 0) {
			year = 100;
		}

		const targetDate = new Date();
		targetDate.setDate(targetDate.getDate() - 365 * year);

		const { data, error } = await supabase
			.from(SupabaseShareEntityViewEnum.JOBS_WITH_SHARES)
			.select("*")
			.eq("status", status)
			.eq("client_id", clientId)
			.order("created_at", { ascending: false })
			.gte("created_at", targetDate.toISOString());
		if (error) {
			set(
				produce((state) => {
					state.jobList[status] = {
						jobCount: 0,
						jobs: [],
					};
				})
			);
			Logger.error(error, {}, "Error fetching jobs by status");
			return;
		}

		const connectRelationshipId =
			useCentralStore.getState().connectRelationshipId;
		const { data: jobRequestData, error: jobRequestError } = await supabase
			.from(SupabaseTableEnum.JOB_REQUESTS)
			.select()
			.eq("connect_relationship_id", connectRelationshipId as string)
			.is("accepted", null)
			.is("rejected", null);

		// If there's an error fetching job requests don't return
		// log the error and continue
		if (jobRequestError) {
			Logger.error(jobRequestError);
		}

		const requests =
			jobRequestData?.map((j) => ({
				id: j.id,
				request: true,
				...JSON.parse(j.request as string),
			})) ?? [];

		set({
			jobRequests: jobRequestData,
		});

		set(
			produce((state) => {
				state.jobList[status] = {
					jobCount:
						status == JobStatusEnum.NOT_STARTED
							? data?.length + requests?.length
							: data?.length,
					jobs:
						status == JobStatusEnum.NOT_STARTED
							? [...data, ...requests]
							: data,
				};
			})
		);
	},

	changeRequestToJob: async (jobRequest, accepted) => {
		const clientId = useCentralStore.getState().client?.id;
		const currentDate = new Date().toDateString();

		// Helper function to update job request status
		const updateJobRequestStatus = async (
			statusField: "accepted" | "rejected",
			statusDate: string
		) => {
			const { error, data } = await supabase
				.from(SupabaseTableEnum.JOB_REQUESTS)
				.update({
					...jobRequest,
					[statusField]: statusDate,
				})
				.eq("id", jobRequest.id)
				.select()
				.single();

			if (error) {
				showNotification({
					message: `Fehler beim ${
						accepted ? "Annehmen" : "Ablehnen"
					} der Arbeit`,
					type: "error",
				});
				Logger.error(error);
				return null;
			}

			const { connectRelationshipId, userId, organization } =
				useCentralStore.getState();

			if (!userId || !organization?.id || !connectRelationshipId) {
				Logger.error("Error fetching shared");
				return null;
			}
			const { error: sharedError } = await supabase
				.from(SupabaseTableEnum.SHARED)
				.insert({
					connect_relationship_id: connectRelationshipId,
					created_by: userId,
					created_by_org: organization.id,
					job_request_id: data.id,
					activity_type: ActivityTypeEnum.JOB_REQUEST_STATUS,
				});

			if (sharedError) {
				showNotification({
					message: "Fehler beim Senden der Aktivität an dentCONNECT",
					type: "error",
				});
				Logger.error(sharedError);
				return null;
			}

			return true;
		};

		if (accepted) {
			const { data: job, error } = await supabase
				.from(SupabaseTableEnum.JOBS)
				.insert({
					// @ts-expect-error request is a JSON field
					...JSON.parse(jobRequest.request),
					code: "", // If code = '' it will be set on insert on the db
					client_id: clientId,
					status: JobStatusEnum.IN_PROGRESS,
				})
				.select("*, job_documents(*)")
				.single();

			if (error) {
				showNotification({
					message: "Fehler beim Annehmen der Arbeit",
					type: "error",
				});
				Logger.error(error);
				return null;
			}

			const statusUpdated = await updateJobRequestStatus(
				"accepted",
				currentDate
			);
			if (!statusUpdated) return null;

			set(
				produce((state) => {
					state.jobList[JobStatusEnum.NOT_STARTED] = {
						jobCount:
							state.jobList[JobStatusEnum.NOT_STARTED].jobCount -
							1,
						jobs: state.jobList[
							JobStatusEnum.NOT_STARTED
						].jobs.filter(
							(j: JobRequestEntityType) => j.id !== jobRequest.id
						),
					};
					state.jobList[JobStatusEnum.IN_PROGRESS] = {
						jobCount:
							state.jobList[JobStatusEnum.IN_PROGRESS].jobCount +
							1,
						jobs: [
							job,
							...state.jobList[JobStatusEnum.IN_PROGRESS].jobs,
						],
					};
				})
			);

			showNotification({
				message: "Arbeit erfolgreich angenommen",
				type: "success",
			});

			return job.id;
		} else {
			const statusUpdated = await updateJobRequestStatus(
				"rejected",
				currentDate
			);
			if (!statusUpdated) return null;

			set(
				produce((state) => {
					state.jobList[JobStatusEnum.NOT_STARTED] = {
						jobCount:
							state.jobList[JobStatusEnum.NOT_STARTED].jobCount -
							1,
						jobs: state.jobList[
							JobStatusEnum.NOT_STARTED
						].jobs.filter(
							(j: JobRequestEntityType) => j.id !== jobRequest.id
						),
					};
				})
			);

			showNotification({
				message: "Arbeit erfolgreich abgelehnt",
				type: "warning",
			});

			return null;
		}
	},

	fetchJobRequest: async (jobRequestId: number) => {
		const jobRequests = get().jobRequests;
		const req = jobRequests?.find((jr) => jr.id == Number(jobRequestId));
		if (req) return req;

		const { data: jobRequest, error: jobRequestError } = await supabase
			.from(SupabaseTableEnum.JOB_REQUESTS)
			.select()
			.eq("id", jobRequestId)
			.single();
		if (jobRequestError) {
			showNotification({
				message: "Die Anfrage, die Sie suchen, existiert nicht",
				type: "error",
			});

			Logger.error(jobRequestError);

			return null;
		}
		set(
			produce((state) => {
				state.jobList[JobStatusEnum.NOT_STARTED] = {
					jobCount:
						state.jobList[JobStatusEnum.NOT_STARTED].jobCount + 1,
					jobs: [
						...state.jobList[JobStatusEnum.NOT_STARTED].jobs,
						jobRequest,
					],
				};
			})
		);

		return jobRequest;
	},

	changeSelectedJobStatus: (status: JobStatusEnum | null) => {
		set({
			selectedJobStatus: status,
		});

		if (status) get().fetchJobsByStatus(status);
	},
	pinJob: (job: JobWithShare) => {
		set(
			produce((state) => {
				if (!job.id) return;
				state.pinnedJobs[job.id] = job;
			})
		);
	},
	unpinJob: (jobId: number) => {
		set(
			produce((state) => {
				if (!jobId) return;
				delete state.pinnedJobs[jobId];
			})
		);
	},
	unpinAllJobs: () => {
		set(
			produce((state) => {
				state.pinnedJobs = {};
			})
		);
	},
});
