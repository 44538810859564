import React, { useState } from "react";
import { Logger } from "@/lib/logger/Logger";
import { useCentralStore } from "../../store/Central";
import { supabase } from "@/lib/supabase";
import {
	Box,
	LinearProgress,
	LinearProgressProps,
	Typography,
} from "@mui/material";
import clsx from "clsx";
import { showNotification } from "../../store/Central/selectors";
import { SupabaseTableEnum } from "@/lib/supabase/supabaseTypes";
import { uploadFileWithProvidedPath } from "../../hooks/useStorage";

const formatDate = (dateString: string): string | undefined => {
	if (!dateString) return undefined;
	const [date, time] = dateString.trim().split(" ");
	const parts = date.split(".");
	if (parts.length !== 3) return undefined;
	const [day, month, year] = parts;
	return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};

function LinearProgressWithLabel(
	props: LinearProgressProps & { value: number }
) {
	return (
		<div style={{ display: "flex", alignItems: "center" }}>
			<div style={{ width: "100%", marginRight: 1 }}>
				<LinearProgress variant="determinate" {...props} />
			</div>
			<div style={{ minWidth: 35 }}>
				<Typography
					variant="body2"
					sx={{ color: "text.secondary" }}
				>{`${Math.round(props.value)}%`}</Typography>
			</div>
		</div>
	);
}

// TODO:
// Konfiguration.XML
// Doc
// Pic
// RTF

/**
 * We are ignoring the following files:
 * - Tickets.txt
 * - PLZ.txt
 * - PINK.txt
 */
const FILES_TO_PROCESS = [
	"Labors.txt", // Ignore
	"TAJournal.txt", // Ignore
	"Mitarbeiter.txt", // Ignore
	"Garanten.txt",
	"Kunden.txt",
	"Archiv.txt",
	"LListe.txt", // Ignore
	"Gruppen.txt", // Ignore
	"Artikel.txt",
	"Tarif.txt",
	"Bloecke.txt",
	"BPositionen.txt",
	"Debitoren.txt",
	"Faelle.txt",
	"FPositionen.txt",
	"IMAGE.txt",
	"Ressourcen.txt", // Ignore
	"Termine.txt", // Ignore
	"Aufgaben.txt", // Ignore
	"Pendenzen.txt", // Ignore
	"Farbkarten.txt", // Ignore
	"Vorlagen.txt", // Ignore
	"Layouts.txt",
	"SpotNotes.txt", // Ignore for now
	"EMails.txt", // Ignore
].sort();

interface ProgressInterface {
	[name: string]: {
		totalLength: number;
		processedSuccess: number;
		processedError: number;
	};
}

export const MigrationPage: React.FC = () => {
	const organizationId = useCentralStore((state) => state.organization?.id);
	const [progress, setProgress] = useState<ProgressInterface>(
		Object.fromEntries(
			FILES_TO_PROCESS.map((name) => [
				name,
				{ totalLength: 0, processedSuccess: 0, processedError: 0 },
			])
		)
	);
	const [rowErrors, setRowErrors] = useState<{
		[name: string]: any[];
	}>({});

	const readTxtFile = async (fileData: string, fileName: string) => {
		if (!organizationId) {
			Logger.error("[MigrationPage] Organization ID not found.");
			return;
		}

		const columns = fileData
			.split("\n")[0]
			.split("|")
			.map((col) => col.trim().replace(/\r$/, "")); // The last column can contain a \r, which we need to remove
		const columnCount = columns.length;

		const rows = fileData
			.split("\n")
			.filter((row) => row.trim().startsWith("{"))
			.map((row) => {
				const rowData = row.split("|");
				if (rowData.length !== columnCount) {
					return null;
				}
				const rowDict = Object.fromEntries(
					columns.map((column, index) => [
						column,
						rowData[index].trim(),
					])
				);
				return {
					table_name: fileName,
					row_value: rowDict,
					organization_id: organizationId,
					row_id: rowData[0],
					e_datum: formatDate(rowData[1]),
				};
			})
			.filter((row) => row !== null);

		setProgress((prev) => ({
			...prev,
			[fileName]: {
				...prev[fileName],
				totalLength: rows.length,
			},
		}));

		const processBatch = async (batch: any) => {
			Logger.info(
				`[MigrationPage] Processing batch for file ${fileName}`,
				batch
			);
			const { data, error } = await supabase.from("DL_MIG").insert(batch);

			if (error) {
				Logger.error(
					error,
					{},
					`[MigrationPage] Error processing batch for file ${fileName}`
				);
				return false;
			}
			Logger.info(
				`[MigrationPage] Batch for file ${fileName} processed successfully.`,
				data
			);
			return true;
		};

		const batchSize = 191;
		let errors: any[] = [];
		for (let i = 0; i < rows.length; i += batchSize) {
			const batch = rows.slice(i, i + batchSize);
			const success = await processBatch(batch);
			if (!success) {
				errors = errors.concat(batch);
			} else {
				setProgress((prev) => ({
					...prev,
					[fileName]: {
						...prev[fileName],
						processedSuccess:
							prev[fileName].processedSuccess + batch.length,
					},
				}));
			}

			// wait 300ms
			await new Promise((resolve) => setTimeout(resolve, 300));
		}

		const retryBatchSize = 11;
		let errors2: any[] = [];
		for (let i = 0; i < errors.length; i += retryBatchSize) {
			const batch = errors.slice(i, i + retryBatchSize);
			const success = await processBatch(batch);
			if (!success) {
				errors2 = errors2.concat(batch);
			} else {
				setProgress((prev) => ({
					...prev,
					[fileName]: {
						...prev[fileName],
						processedSuccess:
							prev[fileName].processedSuccess + batch.length,
					},
				}));
			}

			// wait 300ms
			await new Promise((resolve) => setTimeout(resolve, 300));
		}

		const retryBatchSize2 = 1;
		let remainingErrors: any[] = [];
		for (let i = 0; i < errors2.length; i += retryBatchSize2) {
			const batch = errors2.slice(i, i + retryBatchSize2);
			const success = await processBatch(batch);
			if (!success) {
				Logger.error(
					`[MigrationPage] Error processing batch for file ${fileName}`
				);
				setProgress((prev) => ({
					...prev,
					[fileName]: {
						...prev[fileName],
						processedError:
							prev[fileName].processedError + batch.length,
					},
				}));
				remainingErrors = remainingErrors.concat(batch);
			} else {
				setProgress((prev) => ({
					...prev,
					[fileName]: {
						...prev[fileName],
						processedSuccess:
							prev[fileName].processedSuccess + batch.length,
					},
				}));
			}

			// wait 300ms
			await new Promise((resolve) => setTimeout(resolve, 300));
		}
		if (remainingErrors.length > 0) {
			Logger.error(
				"[MigrationPage] Error processing batch for file",
				{},
				remainingErrors,
				`${fileName}`
			);
			setRowErrors((prev) => ({
				...prev,
				[fileName]: remainingErrors,
			}));
		}
	};

	const handleFileUpload = async (
		e: React.ChangeEvent<HTMLInputElement>,
		key: string
	) => {
		const file = e.target.files?.[0];
		if (!file) {
			showNotification({
				message: "Bitte wählen Sie eine Datei aus.",
				type: "error",
			});
			return;
		}
		if (file && file.name !== key) {
			showNotification({
				message: `Dateiname stimmt nicht überein. Erwartet: ${key}, aber erhalten: ${file.name}`,
				type: "error",
			});
			e.target.value = "";
			return;
		}
		Logger.info("[MigrationPage] Reading file 1.", file);
		if (file) {
			Logger.info("[MigrationPage] Reading file 2.", file);
			const reader = new FileReader();
			reader.onload = async (event) => {
				const fileData = event.target?.result as string;
				Logger.info("[MigrationPage] Reading file 3.", fileData);
				if (fileData) {
					Logger.info("[MigrationPage] Reading file 4.");
					await readTxtFile(fileData, file.name);
					Logger.info("[MigrationPage] Reading file 5.");
				}
			};
			reader.readAsText(file);
		}
	};

	return (
		<div className="px-16 pb-20 pt-10 flex flex-col gap-6">
			<a href="https://drive.google.com/drive/folders/1afw_s1pKHCt3ZcuwLgJ3SMlDW2a0uv-j?usp=drive_link">
				Migrationsanleitung
			</a>
			<div className="grid grid-cols-3 gap-2">
				<KonfigurationMigration />
				{Object.keys(progress).map((key) => {
					const processed =
						progress[key].processedSuccess +
						progress[key].processedError;
					return (
						<div
							key={key}
							className={clsx(
								"flex flex-col bg-gray-200 rounded-md p-6 gap-2",
								{
									"border-red-500 border-2":
										progress[key].processedError > 0,
									"bg-green-200":
										processed ===
											progress[key].totalLength &&
										processed > 0,
								}
							)}
						>
							<div className="flex flex-row gap-4 items-center">
								<h1 className="font-bold">{key}</h1>
								<input
									type="file"
									accept=".txt"
									onChange={(e) => handleFileUpload(e, key)}
								/>
							</div>
							<LinearProgressWithLabel
								value={
									(processed / progress[key].totalLength) *
										100 || 0
								}
							/>
							{progress[key].processedError > 0 && (
								<p>Fehler: {progress[key].processedError}</p>
							)}
							Gesamt: {processed}/{progress[key].totalLength}
						</div>
					);
				})}
				{/* 				<FileMigration
					accept={["application/pdf", "application/xml"]}
					label="PDF Dateien (Doc)"
				/> */}
				<FileMigration
					accept={["image/jpeg", "image/png", "image/gif"]}
					label="Bild Dateien (Pic)"
				/>
			</div>
			{Object.keys(rowErrors).length > 0 && (
				<div className="flex flex-col gap-2">
					<h1 className="font-bold">Fehlerhafte Zeilen</h1>
					<div className="grid grid-cols-1 gap-2">
						{Object.keys(rowErrors).map((key) => {
							return (
								<div
									key={key}
									className="flex flex-col bg-gray-200 rounded-md p-4"
								>
									<h1 className="font-bold">{key}</h1>
									{rowErrors[key].length > 0 &&
										rowErrors[key].map((row, index) => (
											<p key={index}>{row.row_value}</p>
										))}
								</div>
							);
						})}
					</div>
				</div>
			)}
		</div>
	);
};

const KonfigurationMigration = () => {
	const key = "Konfiguration.XML";
	const [progress, setProgress] = useState<number>(0);
	const [error, setError] = useState<string | null>(null);
	const organizationId = useCentralStore((state) => state.organization?.id);

	const extractInfo = (xmlString: string) => {
		try {
			const [a, valueWithRest] = xmlString.split(">", 2);
			const [_, tagInfo] = a.split("<", 2);
			const [value] = valueWithRest.split("<", 1);

			const [groupAndTag, typeInfo, multInfo] = tagInfo.split(" ");
			const [group, tag] = groupAndTag.split(".");
			const type = typeInfo?.split("=")[1]?.replace(/"/g, "");
			const mult = multInfo
				? parseInt(multInfo.split("=")[1].replace(/"/g, ""))
				: null;

			return { group, tag, value, type, mult };
		} catch {
			return {
				group: null,
				tag: null,
				value: null,
				type: null,
				mult: null,
			};
		}
	};

	const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		setProgress(10);
		const file = e.target.files?.[0];
		if (!file) {
			showNotification({
				message: "Bitte wählen Sie eine Datei aus.",
				type: "error",
			});
			return;
		}
		if (file && file.name.toLowerCase() !== key.toLowerCase()) {
			showNotification({
				message: `Dateiname stimmt nicht überein. Erwartet: Konfiguration.XML, aber erhalten: ${file.name}`,
				type: "error",
			});
			e.target.value = "";
			return;
		}

		setProgress(30);

		const reader = new FileReader();
		let organizationData: { [key: string]: any } = {};
		let configData: { [key: string]: any } = {};
		reader.onload = async (event) => {
			const xmlContent = event.target?.result as string;
			const xmlLines = xmlContent.split("\n");

			for (const line of xmlLines) {
				const { group, tag, value } = extractInfo(line);
				if (!group || !tag || !value) continue;

				configData = {
					...configData,
					[group]: {
						...configData[group],
						[tag]: value,
					},
				};

				if (group === "Rg") {
					if (tag === "MWStisEUSt_OK") {
						organizationData = {
							...organizationData,
							is_mwst_eust: value !== "Falsch",
						};
					} else if (tag === "MwStNummer") {
						organizationData = {
							...organizationData,
							mwst_number: value,
						};
					} else if (tag === "MWStInkl_OK") {
						organizationData = {
							...organizationData,
							is_mwst_included: value !== "Falsch",
						};
					} else if (tag === "Waehrung") {
						organizationData = {
							...organizationData,
							currency_text: value,
						};
					} else if (tag === "Mahndauer1") {
						organizationData = {
							...organizationData,
							days_payment_reminder: value,
						};
					} else if (tag === "Mahndauer2") {
						organizationData = {
							...organizationData,
							days_payment_mahnung_1: value,
						};
					} else if (tag === "Mahndauer3") {
						organizationData = {
							...organizationData,
							days_payment_mahnung_2: value,
						};
					} else if (tag === "Zahlungsziel1") {
						organizationData = {
							...organizationData,
							days_payment: value,
						};
					} else if (tag === "Zahlungsziel2") {
						organizationData = {
							...organizationData,
							days_payment_akonto: value,
						};
					} else if (tag === "MaxMahn") {
						organizationData = {
							...organizationData,
							mahnung_max_count: value,
						};
					}
				} else if (group === "Brief") {
					if (tag === "HeaderGLN") {
						organizationData = {
							...organizationData,
							gln: value,
						};
					} else if (tag === "HeaderAnrede1") {
						organizationData = {
							...organizationData,
							title_or_company_name: value,
						};
					}
				}
			}

			organizationData = {
				...organizationData,
				konfiguration: configData,
			};

			setProgress(70);

			if (!organizationId) {
				setError("Organisation nicht gefunden.");
				return;
			}

			const { error } = await supabase
				.from(SupabaseTableEnum.ORGANIZATIONS)
				.update(organizationData)
				.eq("id", organizationId);

			if (error) {
				setError("Fehler beim Speichern der Organisation.");
				return;
			}

			setProgress(100);
		};

		reader.readAsText(file);
	};

	return (
		<div
			key={key}
			className={clsx("flex flex-col bg-gray-200 rounded-md p-6 gap-2", {
				"bg-green-200": progress === 100,
				"bg-red-200": error !== null,
			})}
		>
			<div className="flex flex-row gap-4 items-center">
				<h1 className="font-bold">{key}</h1>
				<input
					type="file"
					accept=".xml"
					onChange={(e) => handleFileUpload(e)}
				/>
			</div>
			<LinearProgressWithLabel value={progress} />
			{error && <p className="text-red-500">{error}</p>}
		</div>
	);
};

const FileMigration: React.FC<{
	accept: string[];
	label: string;
}> = ({ accept, label }) => {
	const organizationId = useCentralStore((state) => state.organization?.id);
	const [numberOfFiles, setNumberOfFiles] = useState<number>(0);
	const [numberOfFilesUploaded, setNumberOfFilesUploaded] =
		useState<number>(0);
	const [numberOfFilesFailed, setNumberOfFilesFailed] = useState<number>(0);
	const [error, setError] = useState<string | null>(null);

	const extractUUID = (fileName: string): string | null => {
		const match = fileName.match(/{([0-9A-F-]+)}/i);
		return match ? match[1] : null;
	};

	const handleFolderUpload = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const selectedFiles = event.target.files;

		if (!selectedFiles || selectedFiles.length <= 0) {
			setError("Bitte wählen Sie eine Datei aus.");
			return;
		}
		setNumberOfFiles(selectedFiles?.length ?? 0);
		if (!organizationId) {
			setError("Organisation nicht gefunden.");
			return;
		}

		const uuid = extractUUID(selectedFiles[0].name);

		if (!uuid) {
			setError("Datei hat keine UUID.");
			return;
		}

		for (const file of selectedFiles) {
			if (!accept.includes(file.type)) {
				setNumberOfFilesFailed((prev) => prev + 1);
				Logger.warn("Error uploading file", file.name);
				continue;
			}

			// change the file name to avoid db errors (removing curly braces)
			const newFileName = file.name.replace(/[{}]/g, "");
			const newFile = new File([file], newFileName, { type: file.type });

			const { success, pathName } = await uploadFileWithProvidedPath({
				fileBody: newFile,
				filePath: `${uuid.toLowerCase()}/${newFileName}`,
			});
			if (!success) {
				setNumberOfFilesFailed((prev) => prev + 1);
				Logger.warn("Error uploading file", pathName, newFile.name);
			} else {
				setNumberOfFilesUploaded((prev) => prev + 1);
				Logger.info("File uploaded", pathName, newFile.name);
			}
			await new Promise((resolve) => setTimeout(resolve, 1000));
		}
	};
	return (
		<div
			key={label}
			className={clsx("flex flex-col bg-slate-300 rounded-md p-6 gap-2", {
				"bg-green-200":
					numberOfFilesUploaded + numberOfFilesFailed >=
						numberOfFiles && numberOfFiles > 0,
				"bg-red-200": numberOfFilesFailed > 0,
			})}
		>
			<div className="flex flex-row gap-4 items-center">
				<h1 className="font-bold">{label}</h1>
				<input
					type="file"
					multiple
					onChange={handleFolderUpload}
					// Workaround for TypeScript
					{...{ webkitdirectory: "true", directory: "true" }}
				/>
			</div>
			<LinearProgressWithLabel
				value={
					((numberOfFilesUploaded + numberOfFilesFailed) /
						numberOfFiles) *
						100 || 0
				}
			/>
			{error && <p className="text-red-500">{error}</p>}
			<p>Fehler: {numberOfFilesFailed}</p>
			<p>
				Gesamt: {numberOfFilesUploaded + numberOfFilesFailed}/
				{numberOfFiles}
			</p>
		</div>
	);
};
