import { Database } from "@/lib/supabase";
import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";
import {
	ClientEntityType,
	GuarantorEntityType,
	JobDocumentEntityType,
	JobEntityType,
	JobItemEntityType,
	OrganizationEntityType,
} from "@/lib/supabase/supabaseTypes";
import { JobDocumentTypeEnum } from "../../pages/job-page/job-document/job-document.types";
import { Logger } from "@/lib/logger/Logger";
import { findEarliestAndLatest } from "./utils-functions";

interface CalculationResult {
	text: string;
	value: number;
}

export type ParsedJobItemFromView = {
	id: string;
	price: number;
	quantity: number;
	tp_value: number;
	discount: number;
	is_req_dr_tax: boolean;
	is_warranty: boolean;
	type: JobItemTypeEnum;
};

export type ExtendedJobDocument = Omit<
	Database["public"]["Views"]["job_documents_extended_view"]["Row"],
	"job_items"
> & {
	job_items: ParsedJobItemFromView[];
};

interface SammelrechnungRow {
	jobCode: string;
	clientName: string;
	jobDocumentDate: string;
	jobTitle: string;
	jobTotals: CalculateTotalsResult;
	patientName: {
		firstName: string;
		lastName: string;
		title: string;
	};
	ignoreMonthlyDiscount: boolean;
}

interface CalculateSammelrechnungResult extends CalculateTotalsResult {
	monthlyDiscount: CalculationResult;
	startDate: string | null;
	endDate: string | null;
	rows: SammelrechnungRow[];
}

function getCalculationResult(value: number): CalculationResult {
	return {
		text: value.toFixed(2),
		value,
	};
}

export function roundToRappen(value: number): number {
	return Math.round(value * 20) / 20;
}

function getMwstPercentage(organization: OrganizationEntityType): number {
	let mwstPercentage = organization.mwst_percentage;
	// The lab can select that the mwst is already included in the price of the tariffs and articles, in which case the mwst is 0
	if (organization.is_mwst_included) {
		mwstPercentage = 0;
	}
	return mwstPercentage;
}

/**
 * Calculates the final amount (Betrag) for a single job item
 *
 * Betrag = Menge * TP-Wert * Rabatt * Preis (amount = quantity * tp_value * discount * price)
 *
 * Note:
 * - Warranty is not included here because the full amount should still be shown in the table (it's only substracted from the total amount)
 * - Therefore, make sure to take out the warranty items before calculating the full amount
 *
 * @param {ParsedJobItemFromView} jobItem
 * @returns {number} final amount (Betrag) for a single job item
 */
export function calculateJobItemAmount(jobItem: ParsedJobItemFromView): number {
	const amountWithoutDiscount =
		jobItem.price * jobItem.quantity * (jobItem.tp_value || 1);

	let discountAmount = 0;
	if (jobItem.discount && jobItem.discount > 0) {
		discountAmount = (jobItem.discount / 100) * amountWithoutDiscount;
	}
	return amountWithoutDiscount - discountAmount;
}

/** Adapter if you want to pass JobItemEntityType */
export function calculateJobItemAmountForJobItem(
	jobItem: JobItemEntityType
): number {
	return calculateJobItemAmount({
		id: jobItem.id,
		price: jobItem.price,
		quantity: jobItem.quantity,
		tp_value: jobItem.tp_value,
		discount: jobItem.discount,
		is_req_dr_tax: jobItem.is_req_dr_tax || false,
		is_warranty: jobItem.is_warranty || false,
		type: jobItem.type,
	});
}

export function calculateJobItemsSum(
	items: ParsedJobItemFromView[],
	type?: JobItemTypeEnum
): number {
	return items.reduce((acc, curr) => {
		if (type === undefined || curr.type === type) {
			// Warranty means the item is free of charge, so we don't add anything to the sum
			if (curr.is_warranty) {
				return acc;
			}
			return acc + calculateJobItemAmount(curr);
		}
		return acc;
	}, 0);
}

/**
 * Calculate amount of job items that is subject to ZAZ Str.
 * If "Aerztesteuer inkl. Mwst" is selected, mwst is added on top of the amount
 */
export function calculateSubjectToDrTaxAmount(
	jobItems: ParsedJobItemFromView[],
	mwstPercentage: number,
	showDrTaxWithMwst: boolean
) {
	const amountWithoutMwst = jobItems.reduce((acc, curr) => {
		// Warranty means the item is free of charge, so we don't add anything to the sum
		if (curr.is_warranty) {
			return acc;
		}
		if (
			curr.is_req_dr_tax &&
			curr.type !== JobItemTypeEnum.ARTICLE_DISCOUNTS
		) {
			return acc + calculateJobItemAmount(curr);
		}
		return acc;
	}, 0);
	if (!showDrTaxWithMwst) {
		return amountWithoutMwst;
	}
	const amountWithMwst = amountWithoutMwst * (1 + mwstPercentage / 100);
	return amountWithMwst;
}

/**
 * subjectToTaxAmount = sum of all items that are marked is_req_dr_tax
 * exemptFromTaxAmount = total - subjectToTaxAmount
 */
export interface CalculateTotalsResult {
	amountWork: CalculationResult;
	amountMaterials: CalculationResult;
	amountPostage: CalculationResult;
	amountExternalWork: CalculationResult;
	amountDiscounts: CalculationResult;
	amountFixedRate: CalculationResult;
	amountWorkAndFixedRate: CalculationResult;
	deductions: CalculationResult;
	subTotal: CalculationResult;
	mwst: CalculationResult;
	total: CalculationResult;
	taxpunkte: CalculationResult;
	subectToTaxAmount: CalculationResult;
	exemptFromTaxAmount: CalculationResult;
}

interface CalculateJobTotalsInput {
	extendedJobDocument: ExtendedJobDocument;
	organization: OrganizationEntityType;
	client: ClientEntityType;
}

export function calculateJobTotalsLegacy({
	job,
	jobDocument,
	jobItems = [],
	organization,
	client,
}: {
	job: JobEntityType | null;
	jobDocument: JobDocumentEntityType | null;
	jobItems: JobItemEntityType[];
	organization: OrganizationEntityType;
	client: ClientEntityType;
}): CalculateTotalsResult {
	return calculateJobTotals({
		extendedJobDocument: {
			job_code: job?.code || "",
			job_client_id: job?.client_id || "",
			job_document_date: jobDocument?.date || "",
			job_title: job?.title || "",
			job_document_discount_material: jobDocument?.discount_material || 0,
			job_document_discount_work: jobDocument?.discount_work || 0,
			job_document_id: Number(jobDocument?.id) || null,
			patient_code: "",
			patient_first_name: "",
			patient_last_name: "",
			patient_title: "",
			job_items: jobItems.map((item) => {
				return {
					id: item.id,
					price: item.price,
					quantity: item.quantity,
					tp_value: item.tp_value,
					discount: item.discount,
					is_req_dr_tax: item.is_req_dr_tax || false,
					is_warranty: item.is_warranty || false,
					type: item.type,
				};
			}),
			organization_id: organization.id,
			job_document_type: jobDocument?.type || null,
			is_ignore_monthly_discount:
				job?.is_ignore_monthly_discount || false,
		},
		organization,
		client,
	});
}

/**
 * Takes job items and calculates the total amount of work, materials, sub total, mwst and total
 * @param jobItems
 * @param job
 * @returns totals as strings rounded to 2 decimal places (except Taxpunkte)
 * @returns taxpunkte = Sum of (quantity * price) of all tariffs
 * @returns amountWork = Sum of (quantity * price * tp_value * discount) of all tariffs
 * @returns amountMaterials = Sum of (quantity * price * discount) of all materials
 * @returns amountPostage = Sum of (quantity * price * discount) of all postage
 * @returns amountExternalWork = Sum of (quantity * price * discount) of all external work
 * @returns amountDiscounts = Sum of (quantity * price * discount) of all discounts
 * @returns amountFixedRate = Sum of (quantity * price * discount) of all fixed rates
 * @returns deductions = amountDiscounts (fixed job item discount positions) + amountMaterials * discountMaterial + amountWork * discountWork
 * @returns subTotal = all job items (except discounts) - deductions
 * @returns mwst = mwst on subTotal
 * @returns total = subTotal + mwst
 * @returns exemptFromTaxAmount = total - subjectToTaxAmount
 * @returns subjectToTaxAmount = sum of all positions (except fixed discounts) that are marked is_req_dr_tax
 * @example   const {
    amountWork,
    amountMaterials,
    deductions,
    subTotal,
    mwst,
    total,
    taxpunkte,
  } = calculateJobTotals(jobItems, job);
 */
export function calculateJobTotals({
	extendedJobDocument,
	organization,
	client,
}: CalculateJobTotalsInput): CalculateTotalsResult {
	const jobItems = extendedJobDocument.job_items;
	if (jobItems.length <= 0 || !organization) {
		return {
			taxpunkte: getCalculationResult(0),
			amountWork: getCalculationResult(0),
			amountPostage: getCalculationResult(0),
			amountExternalWork: getCalculationResult(0),
			amountDiscounts: getCalculationResult(0),
			amountFixedRate: getCalculationResult(0),
			amountWorkAndFixedRate: getCalculationResult(0),
			deductions: getCalculationResult(0),
			amountMaterials: getCalculationResult(0),
			subTotal: getCalculationResult(0),
			mwst: getCalculationResult(0),
			total: getCalculationResult(0),
			exemptFromTaxAmount: getCalculationResult(0),
			subectToTaxAmount: getCalculationResult(0),
		};
	}

	// Calculate taxpunkte of all tariffs (Summe von (Menge * Preis) aller Zeilen)
	// Note that this calculation does not include discounts and does not include the TP-Wert (tp_value)
	const taxpunkte = jobItems.reduce((acc, curr) => {
		// Warranty means the item is free of charge, so we don't add anything to the sum
		if (curr.is_warranty) {
			return acc;
		}
		if (curr.type === JobItemTypeEnum.TARIFF) {
			return acc + curr.quantity * curr.price;
		}
		return acc;
	}, 0);

	// Caculate sum of all work items
	const amountWork = calculateJobItemsSum(jobItems, JobItemTypeEnum.TARIFF);

	// Caculate sums of different types of articles
	const amountMaterials = calculateJobItemsSum(
		jobItems,
		JobItemTypeEnum.ARTICLE_MATERIAL
	);
	const amountPostage = calculateJobItemsSum(
		jobItems,
		JobItemTypeEnum.ARTICLE_POSTAGE
	);
	const amountExternalWork = calculateJobItemsSum(
		jobItems,
		JobItemTypeEnum.ARTICLE_EXTERNAL_WORK
	);
	const amountDiscounts = calculateJobItemsSum(
		jobItems,
		JobItemTypeEnum.ARTICLE_DISCOUNTS
	);
	const amountFixedRate = calculateJobItemsSum(
		jobItems,
		JobItemTypeEnum.ARTICLE_FIXED_RATE
	);

	const discountWork =
		(extendedJobDocument?.job_document_discount_work || 0) / 100;
	const discountMaterial =
		(extendedJobDocument?.job_document_discount_material || 0) / 100;
	let deductions =
		amountMaterials * discountMaterial +
		amountWork * discountWork +
		amountDiscounts;
	deductions = roundToRappen(deductions);

	// Sum of all items
	const subTotal =
		amountWork +
		amountMaterials +
		amountPostage +
		amountExternalWork +
		amountFixedRate -
		deductions;

	// Caculate MwSt and round the result to 0.05
	const mwstPercentage = getMwstPercentage(organization);
	let mwst = subTotal * (mwstPercentage / 100);
	mwst = roundToRappen(mwst);

	// Total
	const total = roundToRappen(subTotal + mwst);

	const subjectToDrTaxAmount = roundToRappen(
		calculateSubjectToDrTaxAmount(
			jobItems,
			mwstPercentage,
			// if is_incl_dr_tax is not set, default to true (as it's better to add tax incorrectly than not add it incorrectly)
			client?.is_incl_dr_tax ?? true
		)
	);
	const exemptFromTaxAmount = roundToRappen(total - subjectToDrTaxAmount);

	return {
		taxpunkte: getCalculationResult(taxpunkte),
		amountWork: getCalculationResult(amountWork),
		amountPostage: getCalculationResult(amountPostage),
		amountExternalWork: getCalculationResult(amountExternalWork),
		amountDiscounts: getCalculationResult(amountDiscounts),
		amountFixedRate: getCalculationResult(amountFixedRate),
		amountWorkAndFixedRate: getCalculationResult(
			amountWork + amountFixedRate
		),
		deductions: getCalculationResult(deductions),
		amountMaterials: getCalculationResult(amountMaterials),
		subTotal: getCalculationResult(subTotal),
		mwst: getCalculationResult(mwst),
		total: getCalculationResult(total),
		subectToTaxAmount: getCalculationResult(subjectToDrTaxAmount),
		exemptFromTaxAmount: getCalculationResult(exemptFromTaxAmount),
	};
}

export function calculateSammelrechnungRowsAndTotals(
	extendedJobDocuments: ExtendedJobDocument[],
	organization: OrganizationEntityType,
	recipient: ClientEntityType | GuarantorEntityType,
	clientsLookup: Record<string, ClientEntityType>
): CalculateSammelrechnungResult {
	const mwstPercentage = getMwstPercentage(organization);
	const rowsForPdfTable: SammelrechnungRow[] = extendedJobDocuments.map(
		(extendedJobDocument) => {
			const client =
				clientsLookup[extendedJobDocument.job_client_id || ""];
			return {
				jobCode: extendedJobDocument.job_code || "",
				clientName: client?.first_name + " " + client?.last_name,
				patientName: {
					title: extendedJobDocument.patient_title || "",
					firstName: extendedJobDocument.patient_first_name || "",
					lastName: extendedJobDocument.patient_last_name || "",
				},
				jobDocumentDate: extendedJobDocument.job_document_date || "",
				jobTitle: extendedJobDocument.job_title || "",
				jobTotals:
					extendedJobDocument.job_document_type ===
					JobDocumentTypeEnum.CreditNote
						? calculateJobTotals({
								extendedJobDocument: {
									...extendedJobDocument,
									job_items:
										extendedJobDocument.job_items.map(
											(item) => {
												return {
													...item,
													price: -item.price,
												};
											}
										),
								},
								organization,
								client,
							})
						: calculateJobTotals({
								extendedJobDocument,
								organization,
								client,
							}),
				ignoreMonthlyDiscount:
					extendedJobDocument.is_ignore_monthly_discount || false,
			};
		}
	);

	const monthlyDiscount = recipient?.monthly_discount || 0;
	const bookableDocumentsTotalsResult = rowsForPdfTable.reduce(
		(acc, curr) => {
			const jobTotals = curr.jobTotals;

			let currSubTotalValue = jobTotals.subTotal.value;
			let currMwstValue = jobTotals.mwst.value;
			let currTotalValue = jobTotals.total.value;

			let deductionsMonthlyDiscount = 0;
			// TODO: organization.is_monthly_discount_on_total ? currSubTotalValue * (monthlyDiscount / 100) : jobTotals.amountWorkAndFixedRate.value * (monthlyDiscount / 100);
			// Currently, the monthly discount is applied to the total amount of work and fixed rates
			// Some might also want to apply it to the subtotal (maybe on client level instead?)

			// TODO:2 Currently the monthly discount is applied to the total amount of work and fixed rates;
			// so if discounts have been applied on job document level, it will one more time discount on the full work amount
			// this is documented in the help center but is not easy to follow
			if (monthlyDiscount > 0 && !curr.ignoreMonthlyDiscount) {
				deductionsMonthlyDiscount =
					jobTotals.amountWorkAndFixedRate.value *
					(monthlyDiscount / 100);

				currSubTotalValue -= deductionsMonthlyDiscount;

				// mwst and total need to be recalculated because the subtotal has changed
				currMwstValue = currSubTotalValue * (mwstPercentage / 100);
				currTotalValue = currSubTotalValue + currMwstValue;
			}

			return {
				amountWork: acc.amountWork + jobTotals.amountWork.value,
				amountMaterials:
					acc.amountMaterials + jobTotals.amountMaterials.value,
				amountPostage:
					acc.amountPostage + jobTotals.amountPostage.value,
				amountExternalWork:
					acc.amountExternalWork + jobTotals.amountExternalWork.value,
				amountDiscounts:
					acc.amountDiscounts + jobTotals.amountDiscounts.value,
				amountFixedRate:
					acc.amountFixedRate + jobTotals.amountFixedRate.value,
				amountWorkAndFixedRate:
					acc.amountWorkAndFixedRate +
					jobTotals.amountWorkAndFixedRate.value,
				deductions: acc.deductions + jobTotals.deductions.value,
				subTotal: acc.subTotal + currSubTotalValue,
				mwst: acc.mwst + currMwstValue,
				total: acc.total + currTotalValue,
				taxpunkte: acc.taxpunkte + jobTotals.taxpunkte.value,
				subectToTaxAmount:
					acc.subectToTaxAmount + jobTotals.subectToTaxAmount.value,
				exemptFromTaxAmount:
					acc.exemptFromTaxAmount +
					jobTotals.exemptFromTaxAmount.value,
				monthlyDiscount:
					acc.monthlyDiscount + deductionsMonthlyDiscount,
			};
		},
		{
			amountWork: 0,
			amountMaterials: 0,
			amountPostage: 0,
			amountExternalWork: 0,
			amountDiscounts: 0,
			amountFixedRate: 0,
			amountWorkAndFixedRate: 0,
			deductions: 0,
			subTotal: 0,
			mwst: 0,
			total: 0,
			taxpunkte: 0,
			subectToTaxAmount: 0,
			exemptFromTaxAmount: 0,
			monthlyDiscount: 0,
		}
	);

	const { earliest, latest } = findEarliestAndLatest(
		extendedJobDocuments,
		"job_document_date"
	);

	return {
		rows: rowsForPdfTable,
		amountWork: getCalculationResult(
			bookableDocumentsTotalsResult.amountWork
		),
		amountMaterials: getCalculationResult(
			bookableDocumentsTotalsResult.amountMaterials
		),
		amountPostage: getCalculationResult(
			bookableDocumentsTotalsResult.amountPostage
		),
		amountExternalWork: getCalculationResult(
			bookableDocumentsTotalsResult.amountExternalWork
		),
		amountDiscounts: getCalculationResult(
			bookableDocumentsTotalsResult.amountDiscounts
		),
		amountFixedRate: getCalculationResult(
			bookableDocumentsTotalsResult.amountFixedRate
		),
		amountWorkAndFixedRate: getCalculationResult(
			bookableDocumentsTotalsResult.amountWorkAndFixedRate
		),
		deductions: getCalculationResult(
			bookableDocumentsTotalsResult.deductions
		),
		subTotal: getCalculationResult(bookableDocumentsTotalsResult.subTotal),
		mwst: getCalculationResult(bookableDocumentsTotalsResult.mwst),
		total: getCalculationResult(
			roundToRappen(bookableDocumentsTotalsResult.total)
		),
		taxpunkte: getCalculationResult(
			bookableDocumentsTotalsResult.taxpunkte
		),
		subectToTaxAmount: getCalculationResult(
			bookableDocumentsTotalsResult.subectToTaxAmount
		),
		exemptFromTaxAmount: getCalculationResult(
			bookableDocumentsTotalsResult.exemptFromTaxAmount
		),
		monthlyDiscount: getCalculationResult(
			bookableDocumentsTotalsResult.monthlyDiscount
		),
		startDate: earliest,
		endDate: latest,
	};
}

interface JobItemEntityTypeForPdfTable
	extends Omit<JobItemEntityType, "price" | "tp_value"> {
	tp_value: string;
	price: string;
	total: string;
}

/**
 * Take job items and
 * - calculates the total amount for each row and returns it as a string rounded to 2 decimal places
 * - adds two decimal places to the price field (i.e. appends .00/.x0)
 * - adds to decimal places to tp_value (TP-Wert)
 * @param {JobItemEntityType[]} jobItems - jobItems to calculate total for
 * @returns {JobItemEntityTypeForPdfTable[]} - jobItems with total field added
 */
export function getJobItemsForPdfTable(
	jobItems: JobItemEntityType[]
): JobItemEntityTypeForPdfTable[] {
	return jobItems.map((jobItem) => {
		let price = "0.00";
		try {
			price = jobItem.price.toFixed(2);
		} catch (error) {
			Logger.error(error, {}, "Error converting price to string");
		}
		return {
			...jobItem,
			tp_value: jobItem?.tp_value?.toFixed(2) || "0.00",
			price,
			total: calculateJobItemAmountForJobItem(jobItem).toFixed(2),
		};
	});
}

/**
 * Cacluates the prepayment for a job, returns 0 if the solvency limit is above the total amount
 * @param solvencyLimit - taken from the organization settings
 * @param prepaymentPercentage - taken from the organization settings, the percentage of the prepayment (e.g. 10)
 * @param totalAmount - the total amount of the job (after discounts, taxes,...) 

 * @returns the prepayment amount
*/
export function calculatePrePayment(
	solvencyLimit: number,
	prepaymentPercentage: number,
	totalAmount: number
): CalculationResult {
	if (solvencyLimit > totalAmount) {
		return getCalculationResult(0);
	}
	const prepayment = solvencyLimit * (prepaymentPercentage / 100);
	return getCalculationResult(prepayment);
}
