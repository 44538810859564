import { JobDocumentTypeEnum } from "../../job-document/job-document.types";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import DifferenceIcon from "@mui/icons-material/Difference";
import InventoryIcon from "@mui/icons-material/Inventory";

export const NEW_DOCUMENT_OPTIONS = [
	{
		value: JobDocumentTypeEnum.Quotation,
		label: "Kostenvoranschlag",
		icon: <SummarizeIcon />,
		type: JobDocumentTypeEnum.Quotation,
	},
	{
		value: JobDocumentTypeEnum.Quotation + "_TEMPLATE",
		label: "Kostenvoranschlag aus Vorlage",
		icon: <SummarizeIcon />,
		type: JobDocumentTypeEnum.Quotation,
	},
	{
		value: JobDocumentTypeEnum.DeliveryNote,
		label: "Lieferschein",
		icon: <LocalPostOfficeIcon />,
		type: JobDocumentTypeEnum.DeliveryNote,
	},
	{
		value: JobDocumentTypeEnum.DeliveryNote + "_TEMPLATE",
		label: "Lieferschein aus Vorlage",
		icon: <LocalPostOfficeIcon />,
		type: JobDocumentTypeEnum.DeliveryNote,
	},
	{
		value: JobDocumentTypeEnum.CreditNote,
		label: "Gutschrift",
		icon: <DifferenceIcon />,
		type: JobDocumentTypeEnum.CreditNote,
	},
	{
		value: JobDocumentTypeEnum.MATERIALS,
		label: "Materialien",
		icon: <InventoryIcon />,
		type: JobDocumentTypeEnum.MATERIALS,
	},
];
