import {
	ClientEntityType,
	GuarantorEntityType,
} from "@/lib/supabase/supabaseTypes";
import { PdfTemplateConfiguration } from "../../context/PdfTemplateConfigurationContext";
import { PdfTemplateType } from "../document-template.types";
import { getConfig } from "../../context/util";
import clsx from "clsx";

export const PdfRecipientAddress: React.FC<{
	recipient: ClientEntityType | GuarantorEntityType;
	pdfTemplateConfiguration: PdfTemplateConfiguration | undefined;
	template?: PdfTemplateType | "general";
}> = ({ recipient, pdfTemplateConfiguration, template }) => {
	const alignment = {
		left: "start",
		center: "center",
		right: "end",
	};
	// const config = pdfTemplateConfiguration?.[template ?? "general"];
	const generalConfig = pdfTemplateConfiguration!.general;
	const pdfConfig = pdfTemplateConfiguration?.[template ?? "general"] || {};

	const config = getConfig(pdfConfig, generalConfig);

	const fontSize = {
		sm: "7pt",
		md: "8pt",
		lg: "10pt",
	};
	const height = {
		sm: "16mm",
		md: "20mm",
		lg: "25mm",
	};

	const padding = {
		sm: "1mm",
		md: "5mm",
		lg: "10mm",
	};

	let addressFirstLine = <p>{recipient.title}</p>;
	let addressSecondLine: React.ReactNode | null = (
		<p>
			{recipient.last_name} {recipient.first_name}
		</p>
	);

	if ((recipient as ClientEntityType)?.company_name) {
		addressFirstLine = (
			<p>{(recipient as ClientEntityType).company_name}</p>
		);
		if (recipient.first_name || recipient.last_name) {
			addressSecondLine = (
				<p>
					{recipient.title && recipient.title + " "}
					{recipient.last_name && recipient.last_name + " "}
					{recipient.first_name}
				</p>
			);
		} else {
			addressSecondLine = null;
		}
	}

	return (
		<div
			aria-label="Empfängeradresse"
			style={{
				height: height[generalConfig.size ?? "lg"],
				paddingBottom: padding[generalConfig.size ?? "lg"],
				width: "100%",
				paddingLeft: generalConfig?.clientAddressLeft ? "6mm" : "97mm", // Linksadressierungen beginnen 26mm von links, Rechtsadressierungen 117mm von links.
				fontSize: fontSize[generalConfig.size ?? "lg"],
				display: "flex",
				...(!config.absoluteRecipientAddress
					? {
							alignItems:
								alignment[
									config.recipientAddressAlignment ?? "left"
								],
						}
					: {}),
				flexDirection: "column",
			}}
		>
			<div
				className={clsx("text-left", {
					"top-[48mm] absolute": config.absoluteRecipientAddress,
					"left-[120mm]":
						config.absoluteRecipientAddress &&
						config.recipientAddressAlignment === "right",
					"left-[20mm]":
						config.absoluteRecipientAddress &&
						config.recipientAddressAlignment !== "right",
				})}
			>
				{addressFirstLine}
				{addressSecondLine}
				<p>{recipient.street}</p>
				<p>
					{recipient.postal_code} {recipient.city}
				</p>
			</div>
		</div>
	);
};
