import React from "react";
import { Tooltip } from "@mui/material";
import "./menu-item-button.css";

export const MenuItemButton: React.FC<{
	title?: string;
	iconLeft?: any;
	iconRight?: any;
	onClick: () => void;
	onContextMenu?: (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => void;
	tooltipTitle?: string;
	disabled?: boolean;
	selected?: boolean;
	dataTestId?: string;
	textStyles?: string;
	gap?: "small" | "medium" | "large";
	children?: React.ReactNode;
	style?: React.CSSProperties;
}> = ({
	title,
	iconLeft,
	onClick,
	tooltipTitle,
	disabled,
	onContextMenu,
	selected,
	iconRight,
	dataTestId,
	textStyles,
	gap,
	children: customElement,
	style,
}) => {
	return (
		<Tooltip title={tooltipTitle} placement="bottom">
			<div
				className={`menu-item-button ${disabled && "disabled"} ${
					selected && "selected"
				} ${gap && gap}`}
				onClick={onClick}
				data-testid="custom-menu-item-button"
				onContextMenu={onContextMenu}
				style={{
					display: "flex",
					justifyContent: !iconLeft
						? "space-between"
						: "justify-start",
					alignItems: "center",
					...style,
				}}
			>
				{iconLeft}
				{title && (
					<p
						className={textStyles ? textStyles : "body-medium"}
						style={{
							overflow: "hidden",
							textOverflow: "ellipsis",
							whiteSpace: "nowrap",
							width: "80%", // This will give enough space for the right icon
						}}
					>
						{title}
					</p>
				)}
				{customElement}
				{iconRight}
			</div>
		</Tooltip>
	);
};
