import { useEffect, useState } from "react";
import { _validateField } from "../hooks/useForm/form-field-validation-functions";
import { Logger } from "../../../lib/logger/Logger";
import { Button, Checkbox, Paper } from "@mui/material";
import { SupabaseTableEnum } from "../../../lib/supabase/supabaseTypes";
import { useCentralStore } from "../store/Central";
import { HeadingWithButton } from "../components/heading-with-button.component";
import { DataGrid } from "@mui/x-data-grid";
import { SmallDialog } from "../components/small-dialog.component";
import { CustomTextField } from "../forms/form-input-components/custom-input-components/custom-text-field.component";
import { showNotification } from "../store/Central/selectors";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { supabase } from "@/lib/supabase";
import { RolesEnum } from "../types/enums";
import { useUserManagementStore } from "../store/UserManagement";
import { deDE } from "@mui/x-data-grid/locales";
import { useSendEmail } from "../hooks/useSendEmail";
import { v4 as uuidv4 } from "uuid";

const columns = [
	{
		field: "email",
		headerName: "E-Mail",
		width: 300,
	},
	{
		field: "first_name",
		headerName: "Vorname",
		width: 150,
	},
	{
		field: "last_name",
		headerName: "Nachname",
		width: 150,
	},
	{
		field: "code",
		headerName: "Code",
		width: 150,
	},
	{
		field: "invited",
		headerName: "Eingeladen",
		width: 150,
	},
	{
		field: "role",
		headerName: "Rolle",
		width: 200,
		valueGetter: (params: any) => {
			if (
				params.row?.email?.includes("support-") &&
				params.row?.email?.includes("@dentdesk.ch")
			) {
				return "Support";
			}
			return params.row.role;
		},
	},
	{
		field: "accepted",
		headerName: "Einladung angenommen",
		width: 150,
	},
];

export const UserManagementPage: React.FC = () => {
	const { organizationId } = useCentralStore((state) => ({
		organizationId: state.organization?.id,
		role: state.role ?? 2,
	}));
	const { members, getMembers } = useUserManagementStore((state) => ({
		members: Object.values(state.membersLookup),
		getMembers: state.getMembers,
	}));
	const { sendInviteEmailTemplate } = useSendEmail();

	useEffect(() => {
		getMembers();
	}, []);

	const [email, setEmail] = useState("");
	const [validationError, setValidationError] = useState<string | null>(null);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [supportDialogOpen, setSupportDialogOpen] = useState(false);
	const [isProfile, setIsProfile] = useState(true);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [code, setCode] = useState("");

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const name = "email";
		const error = _validateField(name, value);
		setEmail(value);
		setValidationError(error);
	};

	const closeDialog = () => {
		setDialogOpen(false);
		setEmail("");
		setValidationError(null);
	};

	const openDialog = () => {
		setDialogOpen(true);
	};

	const submitInvite = async () => {
		if (validationError) {
			showNotification({
				message: "Bitte überprüfen Sie Ihre Eingabe.",
				type: "error",
			});
			return;
		}

		if (!organizationId) {
			showNotification({
				message:
					"Die Organisation konnte nicht gefunden werden. Bitte wenden Sie sich an den Support.",
				type: "error",
			});
			return;
		}

		if (isProfile) {
			const { error } = await supabase
				.from(SupabaseTableEnum.ALLOWED_SIGNUPS_LAB)
				.insert({
					email,
					role,
					organization_id: organizationId,
				});

			if (error) {
				showNotification({
					message:
						"Beim Erstellen des Mitarbeiters ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
					type: "error",
				});
				Logger.error(error, {}, "submitInvite");
				return;
			}

			const organization = useCentralStore.getState().organization;
			sendInviteEmailTemplate({
				emailAddress: email,
				labName: organization?.name ?? "",
			});
			showNotification({
				message: "Einladung erfolgreich versendet.",
				type: "success",
			});
		} else {
			if (!code && !firstName && !lastName) {
				showNotification({
					message:
						"Bitte geben Sie mindestens einen Code, einen Vornamen oder einen Nachnamen ein.",
					type: "warning",
				});
				return;
			}
			const { error } = await supabase
				.from(SupabaseTableEnum.EMPTY_PROFILES)
				.insert({
					code: code,
					first_name: firstName,
					last_name: lastName,
					organization_id: organizationId,
				});

			if (error) {
				showNotification({
					message:
						"Beim Erstellen des Mitarbeiters ohne Profil ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
					type: "error",
				});
				Logger.error(error, {}, "submitInvite");
				return;
			}

			showNotification({
				message: "Mitarbeiter erfolgreich erstellt.",
				type: "success",
			});
		}

		getMembers();
		closeDialog();
		setIsProfile(true);
		setRole(RolesEnum.MEMBER);
		setEmail("");
		setFirstName("");
		setLastName("");
		setCode("");
	};

	const handleSupportAccountCreation = async () => {
		if (!organizationId) {
			showNotification({
				message:
					"Die Organisation konnte nicht gefunden werden. Bitte wenden Sie sich an den Support.",
				type: "error",
			});
			return;
		}

		// Take first 8 characters of the uuid
		const uuid = uuidv4().substring(0, 8);
		const { error } = await supabase
			.from(SupabaseTableEnum.ALLOWED_SIGNUPS_LAB)
			.insert({
				email: `support-${uuid}@dentdesk.ch`,
				role: RolesEnum.ADMIN,
				organization_id: organizationId,
			});

		if (error) {
			showNotification({
				message:
					"Beim Erstellen des Mitarbeiters ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.",
				type: "error",
			});
			Logger.error(error, {}, "submitInvite");
			return;
		}

		const organization = useCentralStore.getState().organization;
		sendInviteEmailTemplate({
			emailAddress: "julius@dentdesk.ch",
			labName: `SUPPORT Account für ${organization?.name} unter support-${uuid}@dentdesk.ch`,
		});
		showNotification({
			message: "Supportaccount erfolgreich erstellt.",
			type: "success",
		});

		getMembers();
		setSupportDialogOpen(false);
	};

	const [role, setRole] = useState(RolesEnum.MEMBER);

	const changeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = (event.target as HTMLInputElement).value;
		setRole(parseInt(value));
	};

	const changeNoProfile = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsProfile(event.target.checked);
	};

	return (
		<div
			style={{
				height: "100%",
				width: "100%",
				display: "flex",
				flexDirection: "column",
				padding: "20px",
				paddingTop: "10px",
			}}
		>
			<div className="flex flex-row justify-between items-center">
				<HeadingWithButton
					heading="Mitarbeiter"
					buttonLabel="Mitarbeiter hinzufügen"
					onClick={openDialog}
				/>
				<Button
					variant="outlined"
					color="primary"
					size="small"
					onClick={() => setSupportDialogOpen(true)}
				>
					Supportaccount hinzufügen
				</Button>
			</div>
			<Paper>
				<DataGrid
					localeText={
						deDE.components.MuiDataGrid.defaultProps.localeText
					}
					rows={members}
					columns={columns}
					sx={{
						height: "100%",
						width: "100%",
						border: 0,
						"& .MuiDataGrid-footerContainer": {
							display: "none",
						},
					}}
				/>
			</Paper>
			<SmallDialog
				dialogTitle="Mitarbeiter einladen"
				dialogText="Geben Sie die E-Mail-Adresse des Mitarbeiters ein, den Sie einladen möchten."
				open={dialogOpen}
				onCancel={closeDialog}
				onSave={submitInvite}
				dialogActions={["cancel", "save"]}
				dialogActionsText={{ cancel: "Abbrechen", save: "Einladen" }}
			>
				<div className="flex flex-col gap-2">
					<FormControlLabel
						value="end"
						control={
							<Checkbox
								onChange={changeNoProfile}
								checked={isProfile}
							/>
						}
						label="Mitarbeiter soll eigenes Profil erstellen"
						labelPlacement="end"
					/>
					{isProfile ? (
						<>
							<CustomTextField
								label="E-Mail"
								value={email}
								onChange={handleChange}
								validationError={validationError}
							/>
							<FormControl>
								<FormLabel>Rolle</FormLabel>
								<RadioGroup value={role} onChange={changeRole}>
									<FormControlLabel
										value={RolesEnum.MEMBER}
										control={<Radio />}
										label="Mitarbeiter"
									/>
									<FormControlLabel
										value={RolesEnum.ADMIN}
										control={<Radio />}
										label="Administrator"
									/>
								</RadioGroup>
							</FormControl>
						</>
					) : (
						<>
							<CustomTextField
								label="Vorname"
								value={firstName}
								onChange={(event) =>
									setFirstName(event.target.value)
								}
								validationError={validationError}
							/>
							<CustomTextField
								label="Nachname"
								value={lastName}
								onChange={(event) =>
									setLastName(event.target.value)
								}
								validationError={validationError}
							/>
							<CustomTextField
								label="Code"
								value={code}
								onChange={(event) =>
									setCode(event.target.value)
								}
								validationError={validationError}
							/>
						</>
					)}
				</div>
			</SmallDialog>
			<SmallDialog
				dialogTitle="Supportaccount hinzufügen"
				dialogText="Sie können dem Support von dentDESK einen Account erstellen."
				open={supportDialogOpen}
				onCancel={() => setSupportDialogOpen(false)}
				onSave={handleSupportAccountCreation}
				dialogActions={["cancel", "save"]}
				dialogActionsText={{ cancel: "Abbrechen", save: "Hinzufügen" }}
			>
				<div></div>
			</SmallDialog>
			{/* Hack to give the paper bottom space*/}
			<div className="text-3xl  opacity-0">Lorem</div>
		</div>
	);
};
