import { Controller, useFormContext } from "react-hook-form";
import { TextField, FormControlLabel, Checkbox, Button } from "@mui/material";
import { FormSectionWithTitle } from "../../components/form-section-with-title/form-section-with-title.component";
import { TariffValuesGroup } from "../../forms/form-input-components/groups/tariff-values.group";
import { CustomTextField } from "../../forms/form-input-components/custom-input-components/custom-text-field.component";
import { TariffEntityType } from "@/lib/supabase/supabaseTypes";

interface TariffFormProps {
	onSubmit: (data: any) => void;
	tariff?: TariffEntityType;
	showSaveButton?: boolean;
}

export const TariffForm: React.FC<TariffFormProps> = ({
	onSubmit,
	tariff,
	showSaveButton,
}) => {
	const {
		handleSubmit,
		control,
		watch,
		setValue,
		formState: { errors },
	} = useFormContext();

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<FormSectionWithTitle title="Tarifdaten">
				<Controller
					name="code"
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<TextField
							label="Tarif-Position (Anzeige)"
							{...field}
							required
							helperText="Dieser Code wird auf Dokumenten und Rechnungen angezeigt."
							error={!!errors.code}
						/>
					)}
				/>
				<Controller
					name="code_e"
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<TextField
							label="Tarif-Code (Eingabe)"
							{...field}
							required
							helperText="Dieser Code wird zur Eingabe von Positionen genutzt. Er muss eindeutig sein. Falls Sie nicht mehrere Varianten des Tarifs haben, sollte er identisch mit dem Tarif-Code sein."
							error={!!errors.code_e}
						/>
					)}
				/>
				<Controller
					name="description_de"
					control={control}
					render={({ field }) => (
						<TextField label="Bezeichnung (DE)" {...field} />
					)}
				/>
				<Controller
					name="description_fr"
					control={control}
					render={({ field }) => (
						<TextField label="Bezeichnung (FR)" {...field} />
					)}
				/>
				<Controller
					name="description_it"
					control={control}
					render={({ field }) => (
						<TextField label="Bezeichnung (IT)" {...field} />
					)}
				/>
			</FormSectionWithTitle>

			<FormSectionWithTitle title="Taxpunkte">
				<TariffValuesGroup
					formData={watch()}
					onValueChange={({ name, value }) => {
						setValue(name as any, value);
					}}
				/>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "start",
					}}
				>
					<Controller
						name="is_own_tp"
						control={control}
						render={({ field }) => (
							<FormControlLabel
								control={
									<Checkbox
										{...field}
										color="primary"
										checked={field.value ?? false}
									/>
								}
								label="Eigener TP-Wert"
								sx={{ mt: "6.5px", width: "170px" }}
							/>
						)}
					/>
					<Controller
						name="own_tp"
						control={control}
						render={({ field }) => (
							<CustomTextField
								label="Taxpunktwert"
								{...field}
								validationError={
									(errors.own_tp?.message as string) ?? ""
								}
								sx={{ flex: 1 }}
							/>
						)}
					/>
				</div>
			</FormSectionWithTitle>

			<FormSectionWithTitle title="Weiteres">
				<Controller
					name="standard_quantity"
					control={control}
					render={({ field }) => (
						<TextField label="Vorgabemenge" {...field} />
					)}
				/>
				<Controller
					name="additional_text"
					control={control}
					render={({ field }) => (
						<TextField
							label="Zusatztext"
							multiline
							rows={4}
							{...field}
						/>
					)}
				/>
				<Controller
					name="is_req_dr_tax"
					control={control}
					render={({ field }) => (
						<FormControlLabel
							control={
								<Checkbox
									{...field}
									checked={field.value ?? false}
								/>
							}
							label="Ärztesteuerpflichtig"
						/>
					)}
				/>
				<Controller
					name="composition"
					control={control}
					render={({ field }) => (
						<TextField
							label="Zusammensetzung"
							multiline
							rows={4}
							{...field}
						/>
					)}
				/>

				{showSaveButton && <Button type="submit">Speichern</Button>}
			</FormSectionWithTitle>
		</form>
	);
};
