import { Controller, useFormContext } from "react-hook-form";
import { TextField, FormControlLabel, Checkbox, Button } from "@mui/material";
import { FormSectionWithTitle } from "../../components/form-section-with-title/form-section-with-title.component";
import { CustomSelect } from "../../components/custom-select.component";
import { JobItemTypeEnum } from "@/lib/supabase/supabaseEnums";
import { ClusterSelect } from "../../forms/form-input-components/selects/cluster-select/cluster.select";

interface ArticleFormProps {
	onSubmit: (data: any) => void;
	showSaveButton?: boolean;
}

export const ArticleForm: React.FC<ArticleFormProps> = ({
	onSubmit,
	showSaveButton,
}) => {
	const { handleSubmit, control, watch, setValue, formState } =
		useFormContext();

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<FormSectionWithTitle title="Artikeldaten">
				<Controller
					name="code_e"
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<TextField
							label="Artikelcode"
							{...field}
							required
							helperText="Dieser Code wird zur Eingabe verwendet."
							error={!!formState.errors.code_e}
						/>
					)}
				/>
				<Controller
					name="description_de"
					control={control}
					render={({ field }) => (
						<TextField label="Bezeichnung (DE)" {...field} />
					)}
				/>
				<Controller
					name="description_fr"
					control={control}
					render={({ field }) => (
						<TextField label="Bezeichnung (FR)" {...field} />
					)}
				/>
				<Controller
					name="description_it"
					control={control}
					render={({ field }) => (
						<TextField label="Bezeichnung (IT)" {...field} />
					)}
				/>
			</FormSectionWithTitle>

			<FormSectionWithTitle title="Artikelwerte">
				<Controller
					name="unit"
					control={control}
					render={({ field }) => (
						<TextField label="Einheit" {...field} />
					)}
				/>
				<Controller
					name="price"
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<TextField
							type="number"
							label="Preis"
							{...field}
							required
							error={!!formState.errors.price}
						/>
					)}
				/>
				<Controller
					name="purchase_price"
					control={control}
					render={({ field }) => (
						<TextField
							label="Einstandspreis"
							{...field}
							type="number"
						/>
					)}
				/>
				<Controller
					name="margin"
					control={control}
					render={({ field }) => (
						<TextField label="Marge" {...field} />
					)}
				/>
				<Controller
					name="standard_quantity"
					control={control}
					render={({ field }) => (
						<TextField label="Vorgabemenge" {...field} />
					)}
				/>
			</FormSectionWithTitle>

			<FormSectionWithTitle title="Artikelart und Cluster">
				<CustomSelect
					inputLabel="Artikelart"
					value={watch("type")?.toString() || ""}
					onChange={(value: string) =>
						setValue("type", parseInt(value))
					}
					options={[
						{
							value: JobItemTypeEnum.ARTICLE_MATERIAL,
							label: "Material",
						},
						{
							value: JobItemTypeEnum.ARTICLE_POSTAGE,
							label: "Porto",
						},
						{
							value: JobItemTypeEnum.ARTICLE_EXTERNAL_WORK,
							label: "Fremdarbeiten",
						},
						{
							value: JobItemTypeEnum.ARTICLE_DISCOUNTS,
							label: "Abzug/Barrabatt",
						},
						{
							value: JobItemTypeEnum.ARTICLE_FIXED_RATE,
							label: "Arbeitspauschale",
						},
					]}
					size="small"
					width="500px"
					required
				/>
				<ClusterSelect
					value={watch("cluster") || ""}
					onChange={(value: string) => setValue("cluster", value)}
					width="500px"
					required
				/>
			</FormSectionWithTitle>

			<FormSectionWithTitle title="Weiteres">
				<Controller
					name="additional_text"
					control={control}
					render={({ field }) => (
						<TextField
							label="Zusatztext"
							multiline
							rows={4}
							{...field}
						/>
					)}
				/>
				<Controller
					name="is_req_dr_tax"
					control={control}
					render={({ field }) => (
						<FormControlLabel
							control={
								<Checkbox
									{...field}
									checked={field.value ?? false}
								/>
							}
							label="Ärztesteuerpflichtig"
						/>
					)}
				/>
				<Controller
					name="manufacturer"
					control={control}
					render={({ field }) => (
						<TextField label="Hersteller" {...field} />
					)}
				/>
				<Controller
					name="ce_number"
					control={control}
					render={({ field }) => (
						<TextField label="CE-Nummer" {...field} />
					)}
				/>
				<Controller
					name="composition"
					control={control}
					render={({ field }) => (
						<TextField
							label="Zusammensetzung"
							multiline
							rows={4}
							{...field}
						/>
					)}
				/>
				<Controller
					name="stock_quantity"
					control={control}
					render={({ field }) => (
						<TextField label="Lager" {...field} />
					)}
				/>
				<Controller
					name="purchase_quantity"
					control={control}
					render={({ field }) => (
						<TextField label="Einkauf" {...field} />
					)}
				/>
				<Controller
					name="lot"
					control={control}
					render={({ field }) => (
						<TextField label="LOT-Nummer" {...field} />
					)}
				/>
			</FormSectionWithTitle>

			{showSaveButton && <Button type="submit">Speichern</Button>}
		</form>
	);
};
