import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useCentralStore } from "../../store/Central";
import { CustomDateRangePicker } from "../../components/custom-date-range-picker/custom-date-range-picker.component";
import { PageTitle } from "@/components/src/page-title/page-title.component";
import { Loading } from "@/components/src/animations/loading";
import { supabase } from "@/lib/supabase/supabaseClient";
import { Logger } from "@/lib/logger/Logger";
import { showNotification } from "../../store/Central/selectors";

export const StatisticsPage = () => {
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);

	const changeDate = (value: { start: Date | null; end: Date | null }) => {
		Logger.info("changeDate", value);

		if (value.start !== null && value.end !== null) {
			if (
				// Typescript doesn't allow for difference between dates to be calculated, so we have to suppress the ts error
				// @ts-expect-error cast
				((value.end - value.start) as number) >
				1000 * 60 * 60 * 24 * 366
			) {
				return;
			}
		}
		setStartDate(value.start);
		setEndDate(value.end);
	};

	return (
		<div className="flex flex-col px-12 py-6">
			<PageTitle title={[{ name: "Statistik" }]} />
			<div className="flex flex-col gap-6 py-4">
				<CustomDateRangePicker
					handleDateChange={changeDate}
					dateVal={{ start: startDate, end: endDate }}
				/>
				<TableWithJobItemsStats
					startDate={startDate}
					endDate={endDate}
				/>
			</div>
		</div>
	);
};
interface JobItemStatsRow {
	client_id: string;
	total_jobs: number;
	total_job_items: number;
	amount_tariff: number;
	amount_article_material: number;
	amount_article_postage: number;
	amount_article_external_work: number;
	amount_article_discounts: number;
	amount_article_fixed_rate: number;
	amount_warranty: number;
	organization_id: string;
}

const TableWithJobItemsStats = ({
	startDate,
	endDate,
}: {
	startDate: Date | null;
	endDate: Date | null;
}) => {
	const { organizationId, clientsLookup } = useCentralStore((state) => ({
		organizationId: state.organization?.id,
		clientsLookup: state.clientsLookup,
	}));

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState<JobItemStatsRow[]>([]);

	useEffect(() => {
		fetchData();
	}, [organizationId, startDate, endDate]);

	const fetchData = async () => {
		if (!organizationId) {
			Logger.error("No organization id");
			return;
		}

		if (startDate === null || endDate === null) {
			return;
		}

		setLoading(true);
		Logger.info("fetching data");

		const { data, error } = await supabase.rpc("get_job_items_stats", {
			start_date: startDate?.toISOString() || "",
			end_date: endDate?.toISOString() || "",
		});

		if (error) {
			Logger.error(error);
			showNotification({
				message: "Fehler beim Laden der Daten",
				type: "error",
			});
		} else {
			Logger.info(data);
			setData(data);
		}
		setLoading(false);
	};

	if (loading) return <Loading />;
	if (startDate === null || endDate === null)
		return <div>Keinen Zeitraum ausgewählt</div>;
	return (
		<div>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Auftraggeber</TableCell>
						<TableCell>Anzahl Aufträge</TableCell>
						<TableCell>Anzahl Positionen</TableCell>
						<TableCell>Arbeit</TableCell>
						<TableCell>Material</TableCell>
						<TableCell>Fremdarb.</TableCell>
						<TableCell>Porto</TableCell>
						<TableCell>Abzüge</TableCell>
						<TableCell>Arbeitspauschale</TableCell>
						<TableCell>Garantie</TableCell>
						<TableCell>Summe (ohne Garantie)</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map((row) => {
						// eslint-disable-next-line @typescript-eslint/naming-convention
						const { company_name, title, first_name, last_name } =
							clientsLookup[row.client_id] ?? {};
						return (
							<TableRow key={row.client_id}>
								<TableCell>
									{company_name ??
										`${title} ${first_name} ${last_name}`}
								</TableCell>
								<TableCell align="right">
									{row.total_jobs}
								</TableCell>
								<TableCell align="right">
									{row.total_job_items}
								</TableCell>
								<TableCell align="right">
									{row.amount_tariff} CHF
								</TableCell>
								<TableCell align="right">
									{row.amount_article_material} CHF
								</TableCell>
								<TableCell align="right">
									{row.amount_article_external_work} CHF
								</TableCell>
								<TableCell align="right">
									{row.amount_article_postage} CHF
								</TableCell>
								<TableCell align="right">
									{row.amount_article_discounts} CHF
								</TableCell>
								<TableCell align="right">
									{row.amount_article_fixed_rate} CHF
								</TableCell>
								<TableCell align="right">
									{row.amount_warranty} CHF
								</TableCell>
								<TableCell align="right">
									{row.amount_tariff +
										row.amount_article_material +
										row.amount_article_external_work +
										row.amount_article_postage +
										row.amount_article_discounts +
										row.amount_article_fixed_rate}{" "}
									CHF
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</div>
	);
};
