import { SelectProps } from "../../form-types";
import {
	FormControl,
	MenuItem,
	Select,
	SelectChangeEvent,
} from "@mui/material";
import { useUserManagementStore } from "@/dentlab/src/store/UserManagement";
import { Member } from "@/dentlab/src/store/UserManagement/user-management.store";

const generateLabel = (profile: Member) => {
	return (
		`${profile.first_name} ${profile.last_name}`.trim() ||
		profile.email ||
		profile.code ||
		"Unbekannt"
	);
};

export const TeamProfileSelect: React.FC<SelectProps> = (props) => {
	const { membersLookup } = useUserManagementStore((state) => ({
		membersLookup: state.membersLookup,
	}));

	const teamProfiles = Object.values(membersLookup);

	const handleChange = (event: SelectChangeEvent) => {
		props.onChange(event.target.value);
	};

	return (
		<FormControl
			variant="standard"
			sx={{ minWidth: 70, margin: 0, width: "100%" }}
		>
			<Select
				labelId="demo-simple-select-standard-label"
				id="demo-simple-select-standard"
				value={props.value || ""}
				onChange={handleChange}
				label="Age"
				sx={{
					height: "20px",
					"& .MuiSelect-select": {
						padding: "0px",
						height: "22px",
						fontSize: "12px",
					},
				}}
				fullWidth
			>
				{teamProfiles.length > 0 &&
					teamProfiles.map((profile) => (
						<MenuItem key={profile.id} value={profile.id}>
							{generateLabel(profile)}
						</MenuItem>
					))}
			</Select>
		</FormControl>
	);
};
