import {
	JobItemEntityType,
	PatientEntityType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import {
	PdfTemplateType,
	XmlTemplateType,
} from "../../pdf-templates/document-template.types";
import { Logger } from "@/lib/logger/Logger";
import { showNotification } from "../../store/Central/selectors";
import {
	GsStatusEnum,
	KvStatusEnum,
	LsStatusEnum,
} from "../actions/actions-hooks-types";
import { calculateJobTotalsLegacy } from "../../lib/utils/calculate";
import { useCentralStore } from "../../store/Central";
import { useJobStore } from "../../store/Jobs";

import {
	documentPropsFor,
	useExportActions,
} from "../actions/useExportActions";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { supabase } from "@/lib/supabase";
import { StorageBucketsEnum } from "../../types/enums";
import { JobStatusEnum } from "@/lib/types/job";
import { usePatientStore } from "../../store/Patient";
import { getBankAccount } from "../actions/get-bank-account/get-bank-account";
import { useAccountingStore } from "../../store/Accounting";
import { InvoiceType } from "../../types/database-enums/invoices-enums";
import { JobDocumentWithFiles } from "../../store/Jobs/job-documents.store";
import {
	documentIsDocument,
	documentIsInvoice,
	documentIsXml,
	getFileNameForDocument,
	getFileNameForXml,
} from "./export-document-utils";
import { LazyPatient } from "../../store/Patient/patients.store";

export const useHandleExportDocument = () => {
	const {
		paymentSlipDataFor,
		exportRechnung,
		exportBarzahlungsquittung,
		exportKartenzahlungsquittung,
		exportKostenvoranschlag,
		exportLieferschein,
		exportAuftragsblatt,
		exportGutschrift,
		exportXmlKostenvoranschlag,
		exportXmlLieferschein,
	} = useExportActions();

	const { patientsLookup } = usePatientStore((state) => ({
		patientsLookup: state.patientsLookup,
	}));

	const { job, updateJobDocumentStatus, fetchJobDocument, changeJobStatus } =
		useJobStore((state) => ({
			job: state.job,
			updateJobDocumentStatus: state.updateJobDocumentStatus,
			fetchJobDocument: state.fetchJobDocument,
			changeJobStatus: state.changeJobStatus,
		}));

	const { organization, client, bankAccountsLookup } = useCentralStore(
		(state) => ({
			organization: state.organization,
			client: state.client,
			bankAccountsLookup: state.bankAccountsLookup,
		})
	);

	const { createInvoiceSingle } = useAccountingStore((state) => ({
		createInvoiceSingle: state.createInvoiceSingle,
	}));

	const [jobDocumentLoading, setJobDocumentLoading] = useState<number | null>(
		null
	);
	const handleExportDocument = async (
		templateType: PdfTemplateType | XmlTemplateType,
		jobItems: JobItemEntityType[],
		jobDocument: JobDocumentWithFiles
	) => {
		if (!job) {
			showNotification({
				message:
					"Auftrag nicht gefunden. Bitte versuchen Sie es erneut.",
				type: "error",
			});
			return;
		}
		if (!client) {
			showNotification({
				message: "Kunde nicht gefunden. Bitte versuchen Sie es erneut.",
				type: "error",
			});
			return;
		}
		if (!organization) {
			showNotification({
				message:
					"Organisation nicht gefunden. Bitte versuchen Sie es erneut.",
				type: "error",
			});
			return;
		}

		setJobDocumentLoading(jobDocument.id);

		const completeJobInformation = {
			client,
			job,
			jobItems,
			jobDocument,
		};

		const { bankAccount, error: bankAccountError } = getBankAccount({
			bankAccountsLookup,
			lab: organization,
			job: job,
			recipient: client,
		});
		if (!bankAccount) {
			setJobDocumentLoading(null);
			showNotification({
				message:
					"Bankkonto nicht gefunden. Bitte versuchen Sie es erneut.",
				type: "error",
			});
			return;
		}

		const documentProps = documentPropsFor(
			organization,
			completeJobInformation
		);
		if (!documentProps) {
			showNotification({
				message:
					"Dokumentinformationen konnten nicht erstellt werden. Bitte versuchen Sie es erneut.",
				type: "error",
			});
			return;
		}

		if (documentIsInvoice(templateType)) {
			let fileNamePrefix = "Rechnung-";
			let invoiceType = InvoiceType.RECHNUNG;
			if (templateType === PdfTemplateType.BARZAHLUNGSQUITTUNG) {
				fileNamePrefix = "Barzahlungsquittung-";
				invoiceType = InvoiceType.BARRECHNUNG;
			} else if (
				templateType === PdfTemplateType.KARTENZAHLUNGSQUITTUNG
			) {
				fileNamePrefix = "Kartenzahlungsquittung-";
				invoiceType = InvoiceType.KARTENZAHLUNGSRECHNUNG;
			} else if (templateType === PdfTemplateType.AKONTORECHNUNG) {
				showNotification({
					message: "Akontorechnung noch nicht implementiert",
					type: "error",
				});
			}

			// TODO: For Akontorechnung this would be different
			const invoiceValue = calculateJobTotalsLegacy({
				job,
				jobDocument,
				jobItems,
				organization,
				client,
			}).total.value;

			const {
				success: createInvoiceSuccess,
				data: createInvoiceData,
				error: createInvoiceError,
			} = await createInvoiceSingle({
				fileNamePrefix,
				invoiceValue,
				bankAccount,
				invoiceType,
				pdfTemplateType: templateType as PdfTemplateType,
				client,
				organization,
				jobDocument,
				job,
			});

			if (!createInvoiceSuccess || !createInvoiceData) {
				setJobDocumentLoading(null);
				showNotification({
					message:
						"Rechnung konnte nicht erstellt werden. Bitte versuchen Sie es erneut.",
					type: "error",
				});
				Logger.error(createInvoiceError, {}, "Error creating invoice");
				return;
			}

			const { invoiceNumber, filePath } = createInvoiceData;

			const paymentSlipData = paymentSlipDataFor(
				completeJobInformation,
				invoiceNumber
			);
			if (!paymentSlipData) {
				return {
					success: false,
					error: "Zahlungsinformationen konnten nicht erstellt werden. Bitte versuchen Sie es erneut.",
				};
			}

			let success;
			if (templateType === PdfTemplateType.RECHNUNG) {
				const { success: s } = await exportRechnung(
					documentProps,
					paymentSlipData,
					filePath
				);
				success = s;
			} else if (templateType === PdfTemplateType.BARZAHLUNGSQUITTUNG) {
				const { success: s } = await exportBarzahlungsquittung(
					documentProps,
					paymentSlipData,
					filePath
				);
				success = s;
			} else if (
				templateType === PdfTemplateType.KARTENZAHLUNGSQUITTUNG
			) {
				const { success: s } = await exportKartenzahlungsquittung(
					documentProps,
					paymentSlipData,
					filePath
				);
				success = s;
			} else if (templateType === PdfTemplateType.AKONTORECHNUNG) {
				// TODO
				success = false;
			} else {
				success = false;
				Logger.error("Unknown invoice type");
			}

			if (!success) {
				setJobDocumentLoading(null);
				showNotification({
					message:
						"Fehler beim Erstellen des PDF-Dokuments. Bitte versuchen Sie es erneut.",
					type: "error",
				});
				return;
			}
		} else if (documentIsDocument(templateType)) {
			const fileName = getFileNameForDocument({
				templateType,
				jobDocument,
				job,
				client,
				patientsLookup: patientsLookup as Record<string, LazyPatient>,
			});
			const pathName = uuidv4() + "/" + fileName;

			let exportError = null;
			if (templateType === PdfTemplateType.KOSTENVORANSCHLAG) {
				const { error } = await exportKostenvoranschlag(
					documentProps,
					pathName,
					job.patient_id
						? (patientsLookup[job.patient_id] as PatientEntityType)
						: undefined
				);
				exportError = error;
				if (!exportError) {
					updateJobDocumentStatus(
						jobDocument.id,
						KvStatusEnum.COMPLETED,
						calculateJobTotalsLegacy({
							job,
							jobDocument,
							jobItems,
							organization,
							client,
						}).total.value,
						null
					);
				}
			} else if (templateType === PdfTemplateType.LIEFERSCHEIN) {
				const { error } = await exportLieferschein(
					documentProps,
					pathName,
					job.patient_id
						? (patientsLookup[job.patient_id] as PatientEntityType)
						: undefined
				);
				exportError = error;

				if (!exportError) {
					updateJobDocumentStatus(
						jobDocument.id,
						LsStatusEnum.COMPLETED,
						calculateJobTotalsLegacy({
							job,
							jobDocument,
							jobItems,
							organization,
							client,
						}).total.value,
						null
					);
					// when the delivery note is exported, the job is completed and gets an invoice amount
					changeJobStatus(
						job.id as number,
						job.status as JobStatusEnum,
						JobStatusEnum.COMPLETED
					);
				}
			} else if (templateType === PdfTemplateType.AUFTRAGSBLATT) {
				const { error } = await exportAuftragsblatt(
					documentProps,
					pathName
				);
				exportError = error;
			} else if (templateType === PdfTemplateType.GUTSCHRIFT) {
				const { error } = await exportGutschrift(
					documentProps,
					pathName
				);
				exportError = error;

				if (!exportError) {
					updateJobDocumentStatus(
						jobDocument.id,
						GsStatusEnum.COMPLETED,
						calculateJobTotalsLegacy({
							job,
							jobDocument,
							jobItems,
							organization,
							client,
						}).total.value * -1, // because it's a credit note
						null
					);
				}
			}

			if (exportError) {
				setJobDocumentLoading(null);
				showNotification({
					message:
						"Export-Fehler beim Erstellen des PDF-Dokuments. Bitte versuchen Sie es erneut.",
					type: "error",
					description: exportError,
				});
				Logger.error(
					"Error creating Lieferschein 1",
					{},
					{ exportError }
				);
				return;
			}

			const { error } = await supabase
				.from(SupabaseTableEnum.FILES)
				.insert([
					{
						file_name: fileName,
						bucket_name: StorageBucketsEnum.V1,
						path_name: pathName,
						job_id: job.id,
						meta_data: {
							type: "pdf",
							pdf_template_type: templateType,
						},
						organization_id: organization.id,
						job_document_id: jobDocument.id,
					},
				])
				.select();

			if (error) {
				setJobDocumentLoading(null);
				showNotification({
					message:
						"Fehler beim Erstellen des Datenbank-Eintrags. Bitte versuchen Sie es erneut.",
					type: "error",
				});
				Logger.error(error, {}, "Error creating document");
				return;
			}
		} else if (documentIsXml(templateType)) {
			if (!job.patient_id || !patientsLookup[job.patient_id]) {
				setJobDocumentLoading(null);
				showNotification({
					message:
						"Bitte wählen Sie einen Patienten aus, bevor Sie das XML-Dokument exportieren.",
					type: "warning",
				});
				return;
			}

			const fileName = getFileNameForXml({
				templateType,
				job,
				client,
				patientsLookup: patientsLookup as Record<string, LazyPatient>,
			});

			let exportError = null;
			if (templateType === XmlTemplateType.KOSTENVORANSCHLAG_XML) {
				const { error } = await exportXmlKostenvoranschlag(
					completeJobInformation,
					patientsLookup[job.patient_id] as PatientEntityType,
					fileName
				);
				exportError = error;
			} else if (templateType === XmlTemplateType.LIEFERSCHEIN_XML) {
				const { error } = await exportXmlLieferschein(
					completeJobInformation,
					patientsLookup[job.patient_id] as PatientEntityType,
					fileName
				);
				exportError = error;
			}

			if (exportError) {
				setJobDocumentLoading(null);
				showNotification({
					message: `Fehler beim Erstellen des Dokuments (XML Export). Bitte kontaktieren Sie den Support sollte dieser Fehler wiederholt auftreten.`,
					type: "error",
					description: exportError,
				});
				Logger.error(
					"Error creating XML document",
					{},
					{ exportError }
				);
				return;
			}
		}

		// wait for 1 second before refetching the job documents
		// TODO: instead call database to see when the file is there
		await new Promise((resolve) => setTimeout(resolve, 1000));
		fetchJobDocument(jobDocument.id);
		setJobDocumentLoading(null);
	};

	return {
		jobDocumentLoading,
		handleExportDocument,
	};
};
