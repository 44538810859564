import { ClientEntityType, JobEntityType } from "@/lib/supabase/supabaseTypes";
import {
	PdfTemplateType,
	XmlTemplateType,
} from "../../pdf-templates/document-template.types";
import { getUnderscoredString } from "../../lib/utils/utils-functions";
import { JobDocumentWithFiles } from "../../store/Jobs/job-documents.store";
import { LazyPatient } from "../../store/Patient/patients.store";

export const documentIsInvoice = (type: PdfTemplateType | XmlTemplateType) => {
	return (
		type === PdfTemplateType.RECHNUNG ||
		type === PdfTemplateType.BARZAHLUNGSQUITTUNG ||
		type === PdfTemplateType.KARTENZAHLUNGSQUITTUNG ||
		type === PdfTemplateType.AKONTORECHNUNG
	);
};

export const documentIsDocument = (type: PdfTemplateType | XmlTemplateType) => {
	return (
		type === PdfTemplateType.KOSTENVORANSCHLAG ||
		type === PdfTemplateType.LIEFERSCHEIN ||
		type === PdfTemplateType.AUFTRAGSBLATT ||
		type === PdfTemplateType.GUTSCHRIFT
	);
};

export const documentIsXml = (type: PdfTemplateType | XmlTemplateType) => {
	return (
		type === XmlTemplateType.KOSTENVORANSCHLAG_XML ||
		type === XmlTemplateType.LIEFERSCHEIN_XML
	);
};

const getPatientName = (
	job: JobEntityType,
	patientsLookup: Record<string, LazyPatient>
) => {
	return job.patient_id && patientsLookup[job.patient_id]
		? `${patientsLookup[job.patient_id]?.last_name}${patientsLookup[
				job.patient_id
			]?.first_name?.slice(0, 1)}`
		: "";
};

export const getFileNameForDocument = ({
	templateType,
	jobDocument,
	job,
	client,
	patientsLookup,
}: {
	templateType: PdfTemplateType;
	jobDocument: JobDocumentWithFiles;
	job: JobEntityType;
	client: ClientEntityType;
	patientsLookup: Record<string, LazyPatient>;
}) => {
	let fileName = jobDocument.title
		? getUnderscoredString(jobDocument.title)
		: "Kostenvoranschlag";

	if (templateType === PdfTemplateType.LIEFERSCHEIN) {
		fileName = jobDocument.title
			? getUnderscoredString(jobDocument.title)
			: "Lieferschein";
	} else if (templateType === PdfTemplateType.AUFTRAGSBLATT) {
		fileName = "Auftragsblatt";
	} else if (templateType === PdfTemplateType.GUTSCHRIFT) {
		fileName = jobDocument.title
			? getUnderscoredString(jobDocument.title)
			: "Gutschrift";
	}

	// prefix job code
	if (job?.code) {
		fileName = `${job.code}_${fileName}`;
	}

	// prefix patient name if needed
	if (client?.is_pt_name_in_doc_name && job?.patient_id) {
		const patientName = getPatientName(job, patientsLookup);
		fileName = `${patientName}_${fileName}`;
	}

	return cleanUmlaute(fileName);
};

export const getFileNameForXml = ({
	templateType,
	job,
	client,
	patientsLookup,
}: {
	templateType: XmlTemplateType;
	job: JobEntityType;
	client: ClientEntityType;
	patientsLookup: Record<string, LazyPatient>;
}) => {
	let fileName = "XML";

	if (templateType === XmlTemplateType.LIEFERSCHEIN_XML) {
		fileName = "SV-LS-ELNF";
	} else if (templateType === XmlTemplateType.KOSTENVORANSCHLAG_XML) {
		fileName = "SV-KV-ELNF";
	}

	// prefix job code
	if (job?.code) {
		fileName = `${job.code}_${fileName}`;
	}

	// prefix patient name if needed
	if (client?.is_pt_name_in_xml_name && job?.patient_id) {
		const patientName = getPatientName(job, patientsLookup);
		fileName = `${patientName}_${fileName}`;
	}

	return cleanUmlaute(fileName);
};

export const cleanUmlaute = (str: string) => {
	return str.replace(
		/[äöüÄÖÜ]/g,
		(match) =>
			({
				ä: "ae",
				ö: "oe",
				ü: "ue",
				Ä: "Ae",
				Ö: "Oe",
				Ü: "Ue",
			})[match] || match
	);
};
