import { supabase } from "@/lib/supabase";
import { useCentralStore } from "../../store/Central";
import { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { deDE } from "@mui/x-data-grid/locales";
import { SquareArrowOutUpRight } from "lucide-react";
import { Chip, IconButton, Stack } from "@mui/material";
import {
	JobWithShare,
	SupabaseShareEntityViewEnum,
} from "@/lib/supabase/supabaseTypes";
import { usePatientStore } from "../../store/Patient";
import { PinJob } from "../job-page/pin-job.component";
import { JobStatusEnum } from "@/lib/types/job";

export const JobsArchivePage = () => {
	const { clientId } = useCentralStore((state) => ({
		clientId: state.clientId,
	}));
	const { patientName, patientsLookup } = usePatientStore((state) => ({
		patientName: state.patientName,
		patientsLookup: state.patientsLookup,
	}));
	const [jobs, setJobs] = useState<JobWithShare[]>([]);
	const [selectedStatus, setSelectedStatus] = useState<JobStatusEnum>(
		JobStatusEnum.ARCHIVED
	);

	const columns: GridColDef[] = [
		{
			field: "actions",
			headerName: "Actionen",
			width: 100,
			renderCell: (params) => (
				<div>
					<IconButton
						onClick={() =>
							window.open(
								`/client/${clientId}/job/${params.row.id}`,
								"_blank"
							)
						}
					>
						<SquareArrowOutUpRight />
					</IconButton>
					<PinJob job={params.row} />
				</div>
			),
		},
		{
			field: "created_at",
			headerName: "Erstellt am",
			width: 150,
			valueGetter: (params) =>
				new Date(params.value).toLocaleDateString("de-DE", {
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
				}),
		},
		{ field: "code", headerName: "Code", width: 150 },
		{ field: "title", headerName: "Titel", width: 300 },
		{
			field: "patient_name",
			headerName: "Patient",
			width: 300,
			valueGetter: (params) => {
				if (!patientsLookup || !params.row.patient_id) return "";
				return patientName(patientsLookup[params.row.patient_id]);
			},
		},
	];

	const fetchJobs = async () => {
		if (!clientId) return;

		const jobs = await supabase
			.from(SupabaseShareEntityViewEnum.JOBS_WITH_SHARES)
			.select("*")
			.eq("client_id", clientId)
			.eq("status", selectedStatus);

		setJobs(jobs.data ?? []);
	};

	useEffect(() => {
		fetchJobs();
	}, [clientId, selectedStatus]);

	return (
		<div className="p-4">
			<Stack direction="row" spacing={1} sx={{ mb: 2 }}>
				<Chip
					label="Verbucht als Einzelrechnung"
					onClick={() =>
						setSelectedStatus(JobStatusEnum.BOOKED_SINGLE)
					}
					color={
						selectedStatus === JobStatusEnum.BOOKED_SINGLE
							? "primary"
							: "default"
					}
					variant={
						selectedStatus === JobStatusEnum.BOOKED_SINGLE
							? "filled"
							: "outlined"
					}
				/>
				<Chip
					label="Verbucht als Sammelrechnung"
					onClick={() =>
						setSelectedStatus(JobStatusEnum.BOOKED_MONTHLY)
					}
					color={
						selectedStatus === JobStatusEnum.BOOKED_MONTHLY
							? "primary"
							: "default"
					}
					variant={
						selectedStatus === JobStatusEnum.BOOKED_MONTHLY
							? "filled"
							: "outlined"
					}
				/>
				<Chip
					label="Archiviert"
					onClick={() => setSelectedStatus(JobStatusEnum.ARCHIVED)}
					color={
						selectedStatus === JobStatusEnum.ARCHIVED
							? "primary"
							: "default"
					}
					variant={
						selectedStatus === JobStatusEnum.ARCHIVED
							? "filled"
							: "outlined"
					}
				/>
			</Stack>
			<DataGrid
				rows={jobs}
				columns={columns}
				localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
				disableRowSelectionOnClick
				disableColumnSelector
			/>
		</div>
	);
};
