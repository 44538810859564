import { useForm } from "../../../../hooks/useForm/useForm";
import { FormProps } from "../../../form-types";
import { Database } from "../../../../../../lib/supabase";
import { InvoiceSettingsAGroup } from "../input-components/invoice-settings-a.group";
import { FormSectionWithSaveButton } from "../../../../components/dialog-components/form-section-with-save-button/form-section-with-save-button.component";
import { InvoiceSettingsBGroup } from "../input-components/invoice-settings-b.group";
import { SupabaseTableEnum } from "../../../../../../lib/supabase/supabaseTypes";
import { useCustomDialogContext } from "@/dentlab/src/components/dialog-components/custom-dialog/custom-dialog.component";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { Logger } from "@/lib/logger/Logger";
import { showNotification } from "@/dentlab/src/store/Central/selectors";
import { Typography } from "@/components/src/typography/typography.component";
import {
	PatientNameFormat,
	patientNameFormatOptions,
	TpTier,
	TpValue,
} from "@/dentlab/src/types/enums";
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import { CustomTextFieldWithPercentage } from "../../../form-input-components/custom-input-components/custom-text-field-with-percentage.component";

// Form to update (not create!) client invoice settings
export const ClientInvoiceSettingsForm: React.FC<FormProps> = ({
	onSave = () => {},
	submitRef = null,
}) => {
	const organizationId = useCentralStore((state) => state.organization?.id);
	const clientId = useCentralStore((state) => state.clientId);
	const { setIsPendingChanges } = useCustomDialogContext();
	const {
		handleCheckboxChange,
		handleInputChange,
		handleValueChange,
		formData,
		formErrors,
		handleUpdate,
		isPendingChanges,
		resetFormData,
	} = useForm<Database["public"]["Tables"]["clients"]["Row"]>(
		{
			organization_id: organizationId,
		},
		SupabaseTableEnum.CLIENTS,
		true,
		clientId,
		"Auftraggeber",
		setIsPendingChanges
	);

	const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		if (!clientId) {
			showNotification({
				message: "Fehler beim Speichern des Auftraggebers",
				type: "error",
			});
			Logger.error("No client id found in store");
			return;
		}

		// Search vector field can only be set by the database
		delete formData.search_vector;
		const { success, data } = await handleUpdate(event, clientId);
		if (success && data) {
			const client = data[0];
			delete client.search_vector;
			onSave(client);

			useCentralStore.getState().upsertClient(client);
		} else {
			showNotification({
				message: "Fehler beim Speichern des Auftraggebers",
				type: "error",
			});
		}
	};

	return (
		<FormSectionWithSaveButton
			onSubmit={onSubmit}
			disabled={!isPendingChanges}
			margin="none"
			onResetForm={resetFormData}
		>
			<button ref={submitRef} type="submit" style={{ display: "none" }} />
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "1rem",
					maxWidth: "500px",
					marginTop: "1.5rem",
				}}
			>
				<InvoiceSettingsAGroup
					formData={formData}
					formErrors={formErrors}
					onCheckboxChange={handleCheckboxChange}
				/>
				<FormControl>
					<InputLabel id="patient-name-format-label">
						Schreibweise Patientenname
					</InputLabel>
					<Select
						labelId="patient-name-format-label"
						id="patient-name-format-select"
						value={
							formData.patient_name_format ||
							PatientNameFormat.LAST_NAME_COMMA_FIRST_NAME
						}
						onChange={(e) => {
							handleValueChange(
								"patient_name_format",
								e.target.value
							);
						}}
						label="Schreibweise Patientenname"
					>
						{patientNameFormatOptions.map((option) => (
							<MenuItem value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<InvoiceSettingsBGroup
					formData={formData}
					formErrors={formErrors}
					onChange={handleInputChange}
					onValueChange={(args: any) =>
						handleValueChange(args.name, args.value)
					}
					onCheckboxChange={handleCheckboxChange}
				/>
			</div>
			<div className="py-2 gap-1 flex flex-col">
				<Typography variant="subtitle-md">
					Standard Tarifwert
				</Typography>
				<ToggleButtonGroup
					color="primary"
					value={
						formData.default_tp_value === null
							? ""
							: formData.default_tp_value
					}
					exclusive
					onChange={(event, value) => {
						handleValueChange(
							"default_tp_value",
							value === "" ? null : value
						);
					}}
				>
					<ToggleButton value={TpValue.OLD}>5.55</ToggleButton>
					<ToggleButton value={TpValue.NEW}>1.0</ToggleButton>
					<ToggleButton value={""}>Labor-Standard</ToggleButton>
				</ToggleButtonGroup>
			</div>
			<div className="py-2 gap-1 flex flex-col">
				<Typography variant="subtitle-md">
					Standard Tarifstufe
				</Typography>
				<ToggleButtonGroup
					color="primary"
					value={
						formData.default_tp_tier === null
							? ""
							: formData.default_tp_tier
					}
					exclusive
					onChange={(event, value) => {
						handleValueChange(
							"default_tp_tier",
							value === "" ? null : value
						);
					}}
				>
					<ToggleButton value={TpTier.SV}>SV</ToggleButton>
					<ToggleButton value={TpTier.PP1}>Min (PP1)</ToggleButton>
					<ToggleButton value={TpTier.PP2}>Privat (PP2)</ToggleButton>
					<ToggleButton value={TpTier.PP3}>Max (PP3)</ToggleButton>
					<ToggleButton value={""}>Labor-Standard</ToggleButton>
				</ToggleButtonGroup>
			</div>
			<div className="py-2 gap-1 flex flex-col">
				<Typography variant="subtitle-md">
					TP-Variation (+/-)
				</Typography>
				<CustomTextFieldWithPercentage
					label="TP-Variation (+/-)"
					name="default_tp_variation"
					value={formData.default_tp_variation ?? ""}
					onChange={handleInputChange}
					size="small"
					validationError={formErrors.default_tp_variation}
					sx={{
						width: "50%",
					}}
				/>
			</div>
		</FormSectionWithSaveButton>
	);
};
