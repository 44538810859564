import { Logger } from "@/lib/logger/Logger";
import {
	GuarantorEntityType,
	JobEntityType,
} from "@/lib/supabase/supabaseTypes";
import { OrganizationEntityType } from "@/lib/supabase/supabaseTypes";
import { BankAccountEntityType } from "@/lib/supabase/supabaseTypes";

import { ClientEntityType } from "@/lib/supabase/supabaseTypes";

type GetBankAccountResult =
	| {
			bankAccount: BankAccountEntityType;
			error: null;
	  }
	| {
			bankAccount: null;
			error: string;
	  };

interface GetBankAccountProps {
	bankAccountsLookup: Record<number, BankAccountEntityType>;
	lab: OrganizationEntityType;
	job: JobEntityType | null;
	recipient: ClientEntityType | GuarantorEntityType;
}

/**
 * Retrieves bank account in the following order:
 * 1. Job
 * 2. Client/Guarantor
 * 3. Lab
 */
export const getBankAccount = ({
	bankAccountsLookup,
	lab,
	job,
	recipient,
}: GetBankAccountProps): GetBankAccountResult => {
	let bankAccountId = null;
	if (job && job.bank_account_id) {
		bankAccountId = job.bank_account_id;
	} else if (recipient.bank_account_id) {
		bankAccountId = recipient.bank_account_id;
	} else if (lab.default_bank_account_id) {
		bankAccountId = lab.default_bank_account_id;
	}

	if (!bankAccountId) {
		return {
			bankAccount: null,
			error: "Kein Bankkonto gefunden. Bitte wählen Sie ein Bankkonto in den Einstellungen des Labors.",
		};
	}

	const bankAccount = bankAccountsLookup[bankAccountId];
	if (!bankAccount) {
		Logger.error(
			"Bankkonto nicht gefunden",
			{},
			`Das zugeordnete Bankkonto wurde nicht gefunden (${bankAccountId}).`
		);
		return {
			bankAccount: null,
			error: `Das zugeordnete Bankkonto wurde nicht gefunden (${bankAccountId}).`,
		};
	}

	return {
		bankAccount,
		error: null,
	};
};
