import { JobStatusEnum } from "@/lib/types/job";
import { JobListItem } from "../job-list/job-list-components/job-list-item.component";
import { useJobStore } from "../../store/Jobs";
import { XIcon } from "lucide-react";
import { usePatientStore } from "../../store/Patient";

export const JobTabs: React.FC = () => {
	const { pinnedJobs, unpinAllJobs } = useJobStore((state) => ({
		pinnedJobs: Object.values(state.pinnedJobs),
		unpinAllJobs: state.unpinAllJobs,
	}));
	const { patientName, patientsLookup } = usePatientStore((state) => ({
		patientName: state.patientName,
		patientsLookup: state.patientsLookup,
	}));
	if (pinnedJobs.length === 0) {
		return null;
	}
	return (
		<div className="flex flex-row justify-between bg-gray-100 border-b border-gray-200 p-1 items-center">
			<div className="flex flex-row flex-wrap">
				{pinnedJobs.map((job) => (
					<JobListItem
						key={job.id}
						jobId={job.id ?? 0}
						clientId={job.client_id ?? ""}
						isJobRequest={(job as any).request ?? false}
						jobStatus={job.status as JobStatusEnum}
					>
						{job.code}{" "}
						{job.patient_id && (
							<b>{patientName(patientsLookup[job.patient_id])}</b>
						)}
					</JobListItem>
				))}
			</div>
			<div className="flex flex-row pr-2">
				<XIcon className="w-4 h-4" onClick={unpinAllJobs} />
			</div>
		</div>
	);
};
