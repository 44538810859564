import { NEW_DOCUMENT_OPTIONS } from "./new-document-options";
import { useJobStore } from "@/dentlab/src/store/Jobs";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { JobDocumentTypeEnum } from "../../job-document/job-document.types";
import { JobDocumentDialog } from "../job-document-dialog.component";
import { useState } from "react";

export const NewJobDocumentArea: React.FC = () => {
	const { createJobDocument } = useJobStore((state) => ({
		createJobDocument: state.createJobDocument,
	}));

	const { isCreateJobDocumentAllowed } = useJobStore((state) => ({
		isCreateJobDocumentAllowed: state.isCreateJobDocumentAllowed,
	}));

	const [templateTypeDialog, setTemplateTypeDialog] =
		useState<JobDocumentTypeEnum | null>(null);

	return (
		<div className="p-4">
			<div className="flex flex-row gap-2 items-center flex-wrap">
				{NEW_DOCUMENT_OPTIONS.map((option) => (
					<Button
						variant="outlined"
						key={option.value}
						onClick={() => {
							if (option.value.includes("_TEMPLATE")) {
								setTemplateTypeDialog(option.type);
							} else {
								createJobDocument(option.type);
							}
						}}
						disabled={
							!isCreateJobDocumentAllowed(option.type).isAllowed
						}
						startIcon={<AddIcon />}
						size="large"
					>
						{option.label}
					</Button>
				))}
			</div>
			<JobDocumentDialog
				open={templateTypeDialog !== null}
				setOpen={(open) => {
					if (!open) {
						setTemplateTypeDialog(null);
					}
				}}
				templateType={
					templateTypeDialog ?? JobDocumentTypeEnum.Quotation
				}
			/>
		</div>
	);
};
