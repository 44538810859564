import { useState } from "react";
import {
	PractitionerEmailsAutocomplete,
	ProfileOptionType,
} from "../../components/sharing/share-dialog/practitioner-emails-autocomplete";
import { DialogWithTitleAndActions } from "../../components/dialog-components/dialog-frames/dialog-with-title-and-actions/dialog-with-title-and-actions.component";
import { ConnectTextInputForMessage } from "../../../../components/src/activity-history/thread-sidebar/thread-sidebar-components/connect-text-input.component";
import { useCentralStore } from "../../store/Central";

// TODO: Jira Story DW-395
/**
 * @param open
 * @param onClose
 * @returns
 */
export const NewMessageDialog: React.FC<{
	open: boolean;
	onClose: () => void;
}> = ({ open, onClose }) => {
	const { connectRelationshipId } = useCentralStore((state) => ({
		connectRelationshipId: state.connectRelationshipId,
	}));
	const [selectedProfiles, setSelectedProfiles] = useState<
		ProfileOptionType[]
	>([]);

	if (!connectRelationshipId) {
		return null;
	}
	return (
		<DialogWithTitleAndActions
			dialogTitle="Neue Nachricht"
			open={open}
			onClose={onClose}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "10px",
					padding: "10px",
				}}
			>
				<PractitionerEmailsAutocomplete
					selectedProfiles={selectedProfiles}
					setSelectedProfiles={setSelectedProfiles}
				/>
				<ConnectTextInputForMessage
					connectRelationshipId={connectRelationshipId}
					onSubmit={onClose}
					emailAddresses={
						selectedProfiles.map((profile) => profile.label) || []
					}
					muiTextFieldProps={{
						fullWidth: true,
						multiline: true,
						rows: 4,
					}}
				/>
			</div>
		</DialogWithTitleAndActions>
	);
};
