import { LargeDialogFrameWithTabs } from "@/dentlab/src/components/dialog-components/dialog-frames/large-dialog-with-tabs/large-dialog-frame-with-tabs.component";
import { DialogPageWithToolbar } from "@/dentlab/src/components/dialog-components/dialog-pages/dialog-page-with-toolbar/dialog-page-with-toolbar.component";
import { SharePatientButton } from "@/dentlab/src/components/sharing/share-button/share-patient-button.component";
import { PatientForm } from "@/dentlab/src/forms/form-components/patient/insert-update/patient.form";
import {
	JobEntityType,
	PatientWithShare,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { PatientDialogFilesSection } from "./components/patients-file-section.component";
import { useNavigate } from "react-router-dom";
import { Logger } from "@/lib/logger/Logger";
import { supabase } from "@/lib/supabase";
import { useEffect, useState } from "react";
import { useCentralStore } from "../../store/Central";

export const PatientFormDialog: React.FC<{
	open: boolean;
	onClose: () => void;
	patient?: PatientWithShare;
	setPatient?: (patient: PatientWithShare) => void;
	onSave?: (patient: PatientWithShare) => void;
}> = ({ open, onClose, patient, setPatient, onSave }) => {
	let tabs = [
		{
			label: "Patient",
			component: patient ? (
				<PatientForm
					patientId={patient?.id as string}
					onSave={onSave}
				/>
			) : (
				<PatientForm onSave={onSave} />
			),
			index: 0,
		},
		{
			label: "Dateien",
			component: (
				<PatientDialogFilesSection patientId={patient?.id as string} />
			),
			index: 1,
		},
		{
			label: "Aufträge",
			index: 2,
			component: patient && <PatientJobs patient={patient} />,
		},
	];
	if (!patient) {
		tabs = tabs.slice(0, 1);
	}
	return (
		<LargeDialogFrameWithTabs
			id="patient-form-dialog"
			height={"70%"}
			maxWidth={"lg"}
			open={open}
			onClose={onClose}
			tabs={tabs.map((tab) => ({
				label: tab.label,
				component: (
					<DialogPageWithToolbar
						onClose={onClose}
						toolbar={
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									height: "100%",
									justifyContent: "space-between",
								}}
							>
								<div
									style={{
										marginRight: "1rem",
									}}
								>
									{patient && setPatient && (
										<SharePatientButton
											patient={patient}
											setPatient={setPatient}
										/>
									)}
								</div>
							</div>
						}
					>
						{tab.component}
					</DialogPageWithToolbar>
				),
				index: tab.index,
			}))}
		/>
	);
};

const PatientJobs: React.FC<{ patient: PatientWithShare }> = ({ patient }) => {
	const [jobs, setJobs] = useState<JobEntityType[]>([]);
	const { clientsLookup } = useCentralStore((state) => ({
		clientsLookup: state.clientsLookup,
	}));

	useEffect(() => {
		const getPatientJobs = async () => {
			const { error, data } = await supabase
				.from(SupabaseTableEnum.JOBS)
				.select()
				.eq("patient_id", patient.id as string);
			if (error) {
				Logger.error(error, {}, "Error fetching jobs for patient");
				return;
			}
			setJobs(data);
		};
		getPatientJobs();
	}, [patient?.id]);

	const navigate = useNavigate();
	return (
		<div className="p-4 flex flex-col">
			{jobs.map((job) => (
				<div
					onClick={() =>
						navigate(`/client/${job.client_id}/job/${job.id}`)
					}
					className="w-full cursor-pointer gap-2 flex p-2 rounded bg-gray-100"
				>
					<div>
						{clientsLookup[job.client_id ?? ""].first_name ?? ""}{" "}
						{clientsLookup[job.client_id ?? ""].last_name ?? ""}
					</div>
					<div>{job?.title ?? ""}</div>
					<div>{job?.code ?? ""}</div>
					<div>
						Eingang:{" "}
						{new Date(
							job.incoming_order_date ?? ""
						).toLocaleDateString("de-CH")}
					</div>
					<div>
						Ausgang:{" "}
						{new Date(
							job.outgoing_order_date ?? ""
						).toLocaleDateString("de-CH")}
					</div>
				</div>
			))}
		</div>
	);
};
