import { Autocomplete, TextField } from "@mui/material";
import { ProfileOfConnectRelationship } from "@/dentlab/src/store/Central/types";
import { useCentralStore } from "@/dentlab/src/store/Central";
import { useEffect } from "react";

function getProfiles(
	connectRelationshipId: string | null,
	profilesPerConnectRelationship: Record<
		string,
		ProfileOfConnectRelationship[]
	>
) {
	return connectRelationshipId
		? profilesPerConnectRelationship[connectRelationshipId].map(
				(profile: ProfileOfConnectRelationship) => ({
					label: profile.email,
					name: `${profile.first_name} ${profile.last_name}`,
					value: profile.profile_id,
				})
			)
		: [];
}

export type ProfileOptionType = {
	label: string;
	name: string;
	value: string;
};

export const PractitionerEmailsAutocomplete: React.FC<{
	selectedProfiles: ProfileOptionType[];
	setSelectedProfiles: (profiles: ProfileOptionType[]) => void;
}> = ({ selectedProfiles, setSelectedProfiles }) => {
	const { connectRelationshipId, profilesPerConnectRelationship } =
		useCentralStore((state) => ({
			connectRelationshipId: state.connectRelationshipId,
			profilesPerConnectRelationship:
				state.profilesPerConnectRelationship,
		}));

	useEffect(() => {
		const profiles = getProfiles(
			connectRelationshipId,
			profilesPerConnectRelationship
		);
		setSelectedProfiles([profiles[0]]);
	}, [
		setSelectedProfiles,
		connectRelationshipId,
		profilesPerConnectRelationship,
	]);

	const profiles = getProfiles(
		connectRelationshipId,
		profilesPerConnectRelationship
	);
	return (
		<div>
			<Autocomplete
				disablePortal
				multiple
				options={profiles}
				sx={{
					marginTop: "1rem",
				}}
				renderInput={(params) => (
					<TextField {...params} label="Personen benachrichtigen" />
				)}
				value={selectedProfiles}
				onChange={(event, newValue) => {
					setSelectedProfiles(newValue);
				}}
				disabled={profiles.length === 1}
			/>
		</div>
	);
};
