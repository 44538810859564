import App from "./App";
import { SignUp } from "./pages/auth/signup.page";
import { AccountPage } from "./pages/account.page";
import { DashboardPage } from "./pages/dashboard/dashboard.page";
import { UserManagementPage } from "./pages/user-management.page";
import { JobPage } from "./pages/job-page/job.page";
import { ClientPage } from "./pages/client-page/client.page";
import { SearchPage } from "./pages/search-page/search.page";
import { ArchivePage } from "./pages/archive/archive.page";
import { PdfTemplateConfiguration } from "./pages/pdf-template-configuration/pdf-template-configuration.page";
import { ConnectPage } from "./pages/client-chat-page/connect.page";
import { ClientDashboardPage } from "./pages/client-dashboard-page/client-dashboard.page";
import { sentryCreateBrowserRouter } from "../../lib/sentry/sentry-utils";
import { MonthlyInvoicesPage } from "./pages/monthly-invoices/monthly-invoices.page";
import { StatisticsPage } from "./pages/statistics/statistics.page";
import { DesktopOverviewPage } from "./pages/desktop-systems/desktop-systems-overview/desktop-overview.page";
import { DesktopSystemPage } from "./pages/desktop-systems/desktop-system/desktop-system.page";
import { NotFoundPage } from "./404.page";
import { DesktopRequestPage } from "./pages/desktop-systems/desktop-request/desktop-request.page";
import { useJobStore } from "./store/Jobs";
import { useCentralStore } from "./store/Central";
import { Agenda } from "./pages/agenda/agenda.page";
import { AccountingPage } from "./pages/accounting/accounting.page";
import { MigrationPage } from "./pages/migration/migration.page";
import { DesktopServiceSimulator } from "./pages/desktop-systems/desktop-service-simulator";
import { InviteeSignup } from "./pages/auth/invitee_signup.page";
import { JobRequestPage } from "./pages/job-request/job-request.page";
import { SubscriptionPage } from "./pages/subscription/subscription.page";
import { JobDocumentTemplatesPage } from "./pages/job-document-templates/job-document-templates.page";
import { TariffsOverviewPage } from "./pages/tariff/tariffs-overview.page";
import { TariffPage } from "./pages/tariff/tariff.page";
import { ArticlePage } from "./pages/article/article.page";
import { PasswordRecoveryPage } from "./pages/password-recovery/password-recovery.page";
import { SetPasswordPage } from "./pages/password-recovery/set-password.page";
import { ArticlesOverviewPage } from "./pages/article/articles-overview.page";
import { JobsArchivePage } from "./pages/jobs/jobs-archive.page";

export const router = sentryCreateBrowserRouter([
	{
		path: "/",
		element: <App />,
		loader: async ({ params }: { params: { clientId?: string } }) => {
			await useCentralStore.getState().initialize();
			// The initial load is not caught by the loader in the client page
			// so we cover the case where a user goes to a client/job page directly here
			if (params.clientId) {
				await useCentralStore.getState().setClientId(params.clientId);
			}
			return null;
		},
		children: [
			{
				path: "/",
				element: <DashboardPage />,
			},
			{
				path: "/migration",
				element: <MigrationPage />,
			},
			{
				path: `client/:clientId`,
				element: <ClientPage />,
				loader: async ({
					params,
				}: {
					params: { clientId: string };
				}) => {
					const client = useCentralStore.getState().client;
					if (client && client.id === params.clientId) {
						return null;
					}
					await useCentralStore
						.getState()
						.setClientId(params.clientId);
					return null;
				},
				children: [
					{
						path: "/client/:clientId",
						element: <ClientDashboardPage />,
					},
					{
						path: "job/:jobId",
						element: <JobPage />,
						loader: async ({
							params,
						}: {
							params: { jobId: string };
						}) => {
							await useJobStore
								.getState()
								.handleJobRouteChange(params.jobId);
							return null;
						},
					},
					{
						path: "connect",
						element: <ConnectPage />,
					},
					{
						path: "archive",
						element: <JobsArchivePage />,
					},
					{
						path: "request/:requestId",
						element: <JobRequestPage />,
					},
				],
			},
			{
				path: "/desktop-requests",
				element: <DesktopRequestPage />,
			},
			{
				path: "/desktop-systems",
				element: <DesktopOverviewPage />,
			},
			{
				path: "/desktop-systems/:desktopSystemId",
				element: <DesktopSystemPage />,
			},
			{
				path: "account-settings",
				element: <AccountPage />,
			},
			{
				path: "user-management",
				element: <UserManagementPage />,
			},
			{
				path: "search",
				element: <SearchPage />,
			},
			{
				path: "/archive",
				element: <ArchivePage />,
			},
			{
				path: "/document-settings",
				element: <PdfTemplateConfiguration />,
			},
			{
				path: "/monthly-invoices",
				element: <MonthlyInvoicesPage />,
			},
			{
				path: "/desktop-service-simulator",
				element: (
					<div>
						{import.meta.env.MODE === "development" ||
							(import.meta.env.MODE === "development_testing" && (
								<DesktopServiceSimulator />
							))}
					</div>
				),
			},
			{
				path: "/job-document-templates",
				element: <JobDocumentTemplatesPage />,
			},
			{
				path: "/statistics",
				element: <StatisticsPage />,
			},
			{
				path: "/agenda",
				element: <Agenda />,
			},
			{
				path: "/accounting",
				element: <AccountingPage />,
			},
			{
				path: "/abonnement",
				element: <SubscriptionPage />,
			},
			{
				path: "/tariff/:code",
				element: <TariffPage />,
			},
			{
				path: "/tariff",
				element: <TariffsOverviewPage />,
			},
			{
				path: "/article/:code",
				element: <ArticlePage />,
			},
			{
				path: "/article",
				element: <ArticlesOverviewPage />,
			},
		],
	},
	{
		path: "signup",
		element: <SignUp />,
	},
	{
		path: "invite",
		element: <InviteeSignup />,
	},
	{
		path: "password-recovery",
		element: <PasswordRecoveryPage />,
	},
	{
		path: "set-password",
		element: <SetPasswordPage />,
	},
	{
		path: "*",
		element: <NotFoundPage />,
	},
]);
