import { useCentralStore } from "../../store/Central";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../lib/utils/utils-functions";

import {
	articleClusterOptions,
	articleTypeOptions,
} from "@/dentlab/src/lib/constants/options";
import { ArticleDialog } from "./article-dialog.component";
import { TariffsOrArticlesTable } from "../tariff/tariffs-and-articles-table.component";

const articlesColumns = [
	{ field: "code_e", headerName: "Code", width: 100 },
	{
		field: "lot",
		headerName: "Lot",
		width: 100,
	},
	{
		field: "description_de",
		headerName: "Bezeichnung (D)",
		width: 200,
	},
	{
		field: "description_fr",
		headerName: "Bezeichnung (F)",
		width: 200,
	},
	{
		field: "description_it",
		headerName: "Bezeichnung (I)",
		width: 200,
	},
	{
		field: "additional_text",
		headerName: "Zusatztext",
		width: 200,
	},
	{
		field: "is_req_dr_tax",
		type: "boolean",
		headerName: "Arztstr.",
		explanation: "Ärztesteuerpflichtig",
		width: 70,
	},
	{
		field: "composition",
		headerName: "Zusammensetzung",
		width: 200,
	},
	{
		field: "manufacturer",
		headerName: "Hersteller",
		width: 200,
	},
	{
		field: "cluster",
		headerName: "Cluster",
		type: "singleSelect",
		valueOptions: articleClusterOptions,
		width: 100,
	},
	{
		field: "ce_number",
		headerName: "CE-Nummer",
		width: 100,
	},
	{
		field: "unit",
		headerName: "Einheit",
		width: 100,
	},
	{
		field: "type",
		headerName: "Typ",
		type: "singleSelect",
		valueOptions: articleTypeOptions,
		width: 100,
	},
	{
		field: "purchase_price",
		headerName: "Einkaufspreis",
		width: 100,
	},
	{
		field: "price",
		headerName: "Verkaufspreis",
		width: 100,
	},
	{
		field: "margin",
		headerName: "Marge",
		width: 100,
	},
	{
		field: "purchase_quantity",
		headerName: "Einkaufsmenge",
		width: 100,
	},
	{
		field: "standard_quantity",
		headerName: "Menge",
		explanation: "Standardmenge",
		width: 100,
	},
	{
		field: "stock_quantity",
		headerName: "Bestand",
		width: 100,
	},
	{
		field: "modified_at",
		headerName: "Änderung",
		width: 120,
		renderCell: (params: any) => {
			return params.row.custom
				? formatDate(params.row.modified_at)
				: "Keine";
		},
	},
];

export const ArticlesOverviewPage = () => {
	const { articles } = useCentralStore((state) => ({
		articles: Object.values(state.articles),
	}));
	const navigate = useNavigate();
	return (
		<div>
			<div className="w-full flex justify-between items-center p-6">
				<div className="text-2xl">Artikel</div>
				<ArticleDialog />
			</div>
			<div className="p-2">
				<TariffsOrArticlesTable
					rows={articles}
					columns={articlesColumns}
					onRowClick={(params) => {
						navigate(`/article/${params.row.code_e}`);
					}}
				/>
			</div>
		</div>
	);
};
