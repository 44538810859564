import { Select, MenuItem } from "@mui/material";
import { useCentralStore } from "../../../../store/Central";

export const ConnectRelationshipSelect: React.FC<{
	onSelect: (connectRelationshipId: string) => void;
}> = ({ onSelect }) => {
	const { clients } = useCentralStore((state) => ({
		clients: Object.values(state.clientsLookup),
	}));
	const connectRelationships: {
		label: string;
		value: string;
	}[] = clients
		.filter((client) => client.connect_relationship_id)
		.map((client) => ({
			label:
				client.company_name ??
				client?.title +
					" " +
					client?.first_name +
					" " +
					client?.last_name,
			value: client.connect_relationship_id ?? "",
		}));
	return (
		<Select onChange={(e) => onSelect(e.target.value as string)}>
			{connectRelationships.map((option) => (
				<MenuItem key={option.value} value={option.value}>
					{option.label}
				</MenuItem>
			))}
		</Select>
	);
};
