import { ActivityHistory } from "../../../../components/src/activity-history/activity-history.component";
import { PatientFormDialog } from "../../pages-via-dialog/patient/patient.dialog";
import { useEffect, useState } from "react";
import {
	JobEntityType,
	PatientWithShare,
} from "../../../../lib/supabase/supabaseTypes";
import { useNavigate } from "react-router-dom";
import { useCentralStore } from "../../store/Central";
import { useRealtimeStore } from "@/lib/store/Realtime";
import { Button, IconButton } from "@mui/material";
import { Loading } from "@/components/src/animations/loading";
import { Typography } from "@/components/src/typography/typography.component";
import { ShareConnectRelationshipDialog } from "./existing-relationship/share-connect-relationship-dialog.component";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import { NewConnectRelationshipDialog } from "./new-relationship/new-connect-relationship-dialog.component";
import { Info, X } from "lucide-react";

export const ConnectPage = () => {
	const navigate = useNavigate();
	const { connectRelationshipId } = useCentralStore((state) => ({
		connectRelationshipId: state.connectRelationshipId,
	}));
	const { activities, fetchActivities, activityCount } = useRealtimeStore(
		(state) => ({
			activities: Object.values(state.activitiesLookup).sort((a, b) => {
				if (a.last_reply_at && b.last_reply_at)
					return a.last_reply_at < b.last_reply_at ? 1 : -1;
				else return -1;
			}),
			fetchActivities: state.fetchActivities,
			activityCount: state.activityCount,
		})
	);

	const [lastActivityCursor, setLastActivityCursor] = useState<number>(9);
	const [moreActivitiesLoading, setMoreActivitiesLoading] = useState(false);
	useEffect(() => {
		if (connectRelationshipId) {
			setMoreActivitiesLoading(true);
			fetchActivities(connectRelationshipId, lastActivityCursor).then(
				() => {
					setMoreActivitiesLoading(false);
				}
			);
		}
	}, [connectRelationshipId, lastActivityCursor]);

	const [openPatient, setOpenPatient] = useState<PatientWithShare | null>(
		null
	);
	const [isPatientDialogOpen, setIsPatientDialogOpen] = useState(false);
	const [sectionForConnectedOpen, setSectionForConnectedOpen] =
		useState(false);

	const addMoreActivities = () => {
		setLastActivityCursor(lastActivityCursor + 20);
	};
	const activitiesLeft = activityCount && activityCount > activities.length;

	return (
		<div className="flex flex-col gap-4 w-full h-full">
			{connectRelationshipId && (
				<div className="flex flex-col gap-4 absolute top-0 left-3 z-10">
					{sectionForConnectedOpen ? (
						<div className="flex flex-col">
							<SectionForConnected
								onSetSectionForConnectedOpen={
									setSectionForConnectedOpen
								}
							/>
						</div>
					) : (
						<div className="bg-blue-100 rounded-md p-2 mt-3">
							<IconButton
								onClick={() => setSectionForConnectedOpen(true)}
							>
								<Info />
							</IconButton>
						</div>
					)}
				</div>
			)}
			{!connectRelationshipId && (
				<div className="flex justify-center">
					<SectionForNotConnected />
				</div>
			)}
			{connectRelationshipId && (
				<>
					<div className="w-full mb-0 min-h-full flex flex-col">
						<ActivityHistory
							key={connectRelationshipId}
							activities={activities}
							onPatientClick={(patient: PatientWithShare) => {
								setIsPatientDialogOpen(true);
								setOpenPatient(patient);
							}}
							onJobClick={(job: JobEntityType) => {
								navigate(
									`/client/${job.client_id}/job/${job.id}`
								);
							}}
						/>

						{!moreActivitiesLoading ? (
							activitiesLeft && (
								<div className="mt-20 w-full justify-center mb-20 flex">
									<Button onClick={addMoreActivities}>
										20 ältere Aktivitäten anzeigen
									</Button>
								</div>
							)
						) : (
							<div className="w-full h-40 flex items-center justify-center">
								<Loading />
							</div>
						)}
					</div>
					{openPatient && (
						<PatientFormDialog
							open={isPatientDialogOpen}
							onClose={() => setIsPatientDialogOpen(false)}
							patient={openPatient}
							setPatient={(patient) => setOpenPatient(patient)}
						/>
					)}
				</>
			)}
		</div>
	);
};

const SectionForConnected: React.FC<{
	onSetSectionForConnectedOpen: (open: boolean) => void;
}> = ({ onSetSectionForConnectedOpen }) => {
	const {
		clients,
		connectRelationshipId,
		deleteConnectRelationshipEntirely,
		removeConnectRelationshipFromClient,
		profilesPerConnectRelationship,
	} = useCentralStore((state) => ({
		clients: Object.values(state.clientsLookup),
		connectRelationshipId: state.connectRelationshipId,
		deleteConnectRelationshipEntirely:
			state.deleteConnectRelationshipEntirely,
		removeConnectRelationshipFromClient:
			state.removeConnectRelationshipFromClient,
		profilesPerConnectRelationship: state.profilesPerConnectRelationship,
	}));

	const [warningDialogOpen, setWarningDialogOpen] = useState(false);

	const connectRelationshipForMultipleClients =
		clients.filter(
			(c) => c.connect_relationship_id === connectRelationshipId
		).length > 1;
	return (
		<div className="my-4 max-w-96 p-3 bg-blue-100 rounded-md flex flex-col gap-2 shadow-lg">
			<div className="flex flex-row items-center justify-between">
				<Typography variant="title-sm">
					dentCONNECT aktiviert
				</Typography>
				<IconButton
					onClick={() => onSetSectionForConnectedOpen(false)}
					size="small"
				>
					<X className="w-5 h-5" />
				</IconButton>
			</div>
			<Typography variant="body-sm">
				dentCONNECT wurde erfolgreich aktiviert. Sie können nun
				Aufträge, Dokumente, Patienten, Nachrichten Auftrags-Dateien und
				Patienten-Dateien teilen.
			</Typography>
			{connectRelationshipId &&
				profilesPerConnectRelationship[connectRelationshipId] && (
					<Typography variant="body-sm">
						Folgende Profile sind mit dieser Connect-Verbindung
						verbunden:
						{profilesPerConnectRelationship[
							connectRelationshipId
						].map(
							(profile) =>
								`${profile.first_name} ${profile.last_name} (${profile.email})`
						)}
					</Typography>
				)}
			{connectRelationshipForMultipleClients && (
				<Typography variant="body-sm">
					Diese Connect-Verbindung besteht zu mehreren Auftraggebern.
				</Typography>
			)}
			<Button
				variant="text"
				className="self-end"
				onClick={() => setWarningDialogOpen(true)}
			>
				{connectRelationshipForMultipleClients
					? "Diesen Auftraggeber trennen"
					: "Connect-Verbindung auflösen"}
			</Button>
			<Dialog
				open={warningDialogOpen}
				onClose={() => setWarningDialogOpen(false)}
			>
				<DialogTitle>
					Wollen Sie den Zugang wirklich aufheben?
				</DialogTitle>
				<DialogContent className="flex flex-col gap-2">
					<Typography variant="body-lg">
						Alle geteilten Daten bleiben erhalten, aber werden nicht
						mehr geteilt - auch wenn Sie den Zugang später wieder
						herstellen.
					</Typography>
					{connectRelationshipForMultipleClients && (
						<Typography variant="body-lg">
							Der Zugang bleibt für andere Auftraggeber erhalten.
						</Typography>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setWarningDialogOpen(false)}>
						Abbrechen
					</Button>
					<Button
						onClick={() => {
							if (connectRelationshipForMultipleClients) {
								removeConnectRelationshipFromClient();
							} else {
								deleteConnectRelationshipEntirely();
							}
						}}
					>
						Connect-Verbindung trennen
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

const SectionForNotConnected = () => {
	const [dialogForNew, setDialogForNew] = useState(false);
	const [dialogForExisting, setDialogForExisting] = useState(false);
	return (
		<div className="my-4 p-3 bg-blue-100 rounded-md flex flex-col gap-2">
			<div className="flex flex-row gap-2 items-start">
				<Button
					variant="contained"
					className="self-end"
					onClick={() => setDialogForNew(true)}
				>
					Neue Connect-Verbindung einrichten
				</Button>
				<Button onClick={() => setDialogForExisting(true)}>
					Von bestehendem Auftraggeber
				</Button>
			</div>
			<ShareConnectRelationshipDialog
				open={dialogForExisting}
				onClose={() => setDialogForExisting(false)}
			/>
			<NewConnectRelationshipDialog
				open={dialogForNew}
				onClose={() => setDialogForNew(false)}
			/>
		</div>
	);
};
